import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    adminDetails: null,
    plan: null,
    vendorList: [],
    clientList: []
}

export const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        adminDetails: (state, action) => {
            state.adminDetails = action.payload?.user || null
            state.vendorList = action.payload?.vendors || []
            state.clientList = action.payload?.clients || []
            state.plan = action.payload?.plan || null
        },
    },
})

// Action creators are generated for each case reducer function
export const { adminDetails } = adminSlice.actions

export const selectAdminDetails = (state) => state.admin.adminDetails
export const selectAdminPlan = (state) => state.admin.plan
export const selectVendorList = (state) => state.admin.vendorList
export const selectClientList = (state) => state.admin.clientList

export default adminSlice.reducer