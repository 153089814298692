import React, { useEffect, useState } from 'react';
import logo from '../../images/corner-cube-logo.svg';
import { Link, useHistory } from 'react-router-dom';
import welcome from '../../images/welcome.svg';
import Circles from '../Reusable/Circles';
import ResetPassword from '../Reusable/ResetPassword';
import { decodeToken, openLink } from '../../services/constant';
import { useSelector } from 'react-redux';
import { selectAdminDetails } from '../../slices/adminSlice';

export default function WelcomeScreen() {
    const history = useHistory();
    const [resetPassword, setResetPassword] = useState(false)
    const adminInfo = useSelector(selectAdminDetails)
    let user = decodeToken()

    useEffect(() => {
        if (adminInfo)
            if (adminInfo?.progress == 3) {
                setResetPassword(true)
            }
        if (!user?.progress) history.push('/login')
    }, [adminInfo])


    const closePopUp = () => setResetPassword(false)

    return (
        <>
            <section className="min-h-screen pb-6 w-full relative">
                <Link to="/"><img src={logo} className="absolute top-3 left-6" style={{ width: "120px" }} /></Link>
                <div className='text-center w-full max-w-[60rem] mx-auto flex flex-col items-center justify-center gap-4 pt-12 z-10 relative'>
                    <h1 style={{ color: "#E3B900" }} className=" text-4xl font-medium">Welcome to Corner Qube!</h1>
                    <h1 style={{ color: "#47B000" }} className="text-3xl font-semibold">{adminInfo?.companyName}</h1>
                    <div className='w-[70rem] max-w-7xl bg-red-50'>
                        <img src={welcome} className="h-full w-full object-contain" />
                    </div>
                    <div className='flex items-center gap-10 mt-5'>
                        <div className="z-10 rounded max-w-max bg-bermuda">
                            <button className={`z-10 rounded py-2 px-5 font-semibold text-lg bg-signuppb transform -translate-x-1.5 -translate-y-2`}
                                onClick={() => history.push('/')}>Continue to Corner Qube</button>
                        </div>
                        <div className="z-10 rounded max-w-max bg-bermuda">
                            <button className={`z-10 rounded py-2 px-5 font-semibold text-lg bg-lightgreen transform -translate-x-1.5 -translate-y-2`}
                                onClick={() => adminInfo?.progress == 5 ? openLink(`https://admin.cornerqube.com/dashboard/${adminInfo?.companyName}`) : openLink('https://admin.cornerqube.com/profile')}>Continue to Admin Panel</button>
                        </div>
                    </div>
                </div>
                <Circles />
            </section>
            {resetPassword && <ResetPassword progress={4} closePopUp={closePopUp} />}
        </>
    );
}










