import axios from "axios"
import { adminEndpoints, companyEndpoints, config, endpoints } from "./config"

var jwtToken = localStorage.getItem("corner_qube");

const admin = axios.create({
    baseURL: config.adminUrl,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + jwtToken
    }
})
const company = axios.create({
    baseURL: config.companyUrl,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + jwtToken
    }
})

const request = axios.create({ baseURL: config.apiUrl })

export const requestAdmin = async ({ ...options }) => {
    admin.defaults.headers.common["Content-Type"] = 'application/json;charset=UTF-8'
    if (jwtToken) {
        admin.defaults.headers.common.Authorization = 'Bearer ' + jwtToken;
    }
    const onSuccess = (response) => response;
    const onError = (error) => error;
    try {
        const response = await admin(options);
        return onSuccess(response);
    } catch (error) {
        return onError(error);
    }
}

export const requestCompany = async ({ ...options }) => {
    company.defaults.headers.common["Content-Type"] = 'application/json;charset=UTF-8'
    const jwtToken = localStorage.getItem("corner_qube");
    if (jwtToken) {
        company.defaults.headers.common.Authorization = 'Bearer ' + jwtToken;
    }
    const onSuccess = (response) => response;
    const onError = (error) => error;
    try {
        const response = await company(options);
        return onSuccess(response);
    } catch (error) {
        return onError(error);
    }
}

export const getAuthorizationHeader = () => {

    let headers = {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
    }
    const jwtToken = localStorage.getItem("corner_qube");
    if (jwtToken) {
        headers.authorization = 'Bearer ' + jwtToken;
    }
    return headers;
}

export const getPopularServices = () => {
    return request.get(endpoints.getPopularServices)
}

export const getServices = () => {
    return request.get(endpoints.getServices)
}

export const industryTypes = () => {
    return request.get(endpoints.industryTypes)
}
export const getPlans = () => {
    return request.get(endpoints.plans)
}

export const createOrder = (data) => {
    return request.post('/order/create', data, {
        headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + jwtToken
        }
    })
}


// Company API Calls
export const getOrganizationAndAccessRoleData = () => {
    return company.get(companyEndpoints.getOrganizationAndAccessRoleData)
}

export const getSignUpRefData = () => {
    return company.get(companyEndpoints.getSignUpRefData)
}

export const getCompanies = () => {
    return company.get(companyEndpoints.getCompanies)
}

export const getCompanyUsers = (companyName) => {
    return company.get(companyEndpoints.getCompanyUsers + `?companyName=${companyName}`)
}

export const getProjects = (companyName, vendorId) => {
    return company.get(companyEndpoints.getProjects + `?companyName=${companyName}` + (vendorId ? `&vendorId=${vendorId}` : ''))
}

export const getOverallRating = (companyName) => {
    return company.get(companyEndpoints.getOverallRating + `?companyName=${companyName}`)
}
export const getCompanyDetails = (companyName) => {
    return company.get(companyEndpoints.getCompanyDetails + `?companyName=${companyName}`)
}

export const getAllReviewsByCompany = (companyName) => {
    return company.get(companyEndpoints.getAllReviewsByCompany + `?companyName=${companyName}`)
}

export const getClientReviewSector = (companyName) => {
    return company.get(companyEndpoints.getClientReviewSector + `?companyName=${companyName}`)
}
export const getCompanyVSectorAvgFactorRating = (companyName) => {
    return company.get(companyEndpoints.getCompanyVSectorAvgFactorRating + `?companyName=${companyName}`)
}
export const getClientReviewOfferingTag = (companyName) => {
    return company.get(companyEndpoints.getClientReviewOfferingTag + `?companyName=${companyName}`)
}

export const getSectorClientReviewAvgDuration = (companyName) => {
    return company.get(companyEndpoints.getSectorClientReviewAvgDuration + `?companyName=${companyName}`)
}
export const getClientReviewType = (companyName) => {
    return company.get(companyEndpoints.getClientReviewType + `?companyName=${companyName}`)
}
export const getTypeWiseClientReviewAvgDuration = (companyName) => {
    return company.get(companyEndpoints.getTypeWiseClientReviewAvgDuration + `?companyName=${companyName}`)
}
export const writeReviewInfo = (companyName) => {
    return company.get(companyEndpoints.writeReviewInfo + `?companyName=${companyName}`)
}
export const companyOfferingTags = async (companyName) => {
    return company.get(companyEndpoints.companyOfferingTags + `?companyName=${companyName}`)
}

export const getReviewDetails = (id) => {
    return company.get(companyEndpoints.getReviewDetails + `/${id}`)
}

export const getCompanyCompareData = (companies) => {
    return company.get(companyEndpoints.companyCompareData + `?companyName=${companies}`)
}

export const getCompanyStatistics = (companyName) => {
    return company.get(companyEndpoints.getCompanyStatistics + `?companyName=${companyName}`)
}

export const addEnquiry = (data) => {
    return company.post(companyEndpoints.addEnquiry, data)
}

export const qubeStatistics = () => {
    return company.get(companyEndpoints.qubeStatistics)
}
export const getCompareInfo = (companies) => {
    if (!companies) return {}
    return company.get('/compare/info?companies=' + companies || '')
}


// Admin API Calls


export const registerCompany = (data) => {
    return admin.post(adminEndpoints.registerCompany, data)
}
export const addCompanyInformation = (data) => {
    return admin.post(adminEndpoints.addCompanyInformation, data)
}

export const getAdminInfo = () => {
    return admin.get(adminEndpoints.getAdminDetails)
}

export const login = (data) => {
    return admin.post(adminEndpoints.login, data)
}

export const sendOtp = (data) => {
    return admin.post(adminEndpoints.sendOtp, data)
}

export const updatePassword = async (data) => {
    try {
        let res = await admin.post(adminEndpoints.updatePassword, data)
        return res.data
    } catch (error) {
        return error?.response?.data || error.message
    }
}

export const getUsersList = () => {
    return admin.get(adminEndpoints.getUsersList)
}

export const addUsersForAccessPortal = (data) => {
    return admin.post(adminEndpoints.addUsersForAccessPortal, data)
}

export const updateUsersForAccessPortal = (data) => {
    return admin.post(adminEndpoints.updateUsersForAccessPortal, data)
}

export const addVendorByAdmin = (data) => {
    return admin.post(adminEndpoints.addVendorByAdmin, data)
}
export const addClientByAdmin = (data) => {
    return admin.post(adminEndpoints.addClientByAdmin, data)
}

export const searchOfferingTagByAdmin = () => {
    return admin.get(adminEndpoints.searchOfferingTagByAdmin)
}

export const getCompanyProjects = () => {
    return admin.get(adminEndpoints.getCompanyProjects)
}

export const getAllVendorStatus = () => {
    return admin.get(adminEndpoints.getAllVendorStatus)
}

export const getAllClientStatus = () => {
    return admin.get(adminEndpoints.getAllClientStatus)
}

export const acceptOrRejectRequestForVendor = (data) => {
    return admin.post(adminEndpoints.acceptOrRejectRequestForVendor, data)
}

export const acceptOrRejectRequestForClient = (data) => {
    return admin.post(adminEndpoints.acceptOrRejectRequestForClient, data)
}

export const handleRequestForClientAndVendor = (data) => {
    return data?.type == "vendor" ? acceptOrRejectRequestForVendor(data) : data?.type == "client" ? acceptOrRejectRequestForClient(data) : {}

}

export const feedbackRequest = (data) => {
    return admin.post(adminEndpoints.feedbackRequest, data)
}
export const feedbackRequestList = (type) => {
    // type = typeof (type) == "string" ? type : ''
    return admin.get(adminEndpoints.feedbackRequestList + `?type=${type}`)
}
export const projectFeedbackRequestList = (type) => {
    type = typeof (type) == "string" ? type : ''
    return admin.get(adminEndpoints.projectFeedbackRequestList + `?type=${type}`)
}
export const addOverAllReview = (data) => {
    return admin.post(adminEndpoints.createOverallRating, data)
}

export const searchCompanyAndTag = (value) => {
    return admin.get(adminEndpoints.searchCompanyAndTag + `?search=${value}`)
}
export const createProject = (data) => {
    return admin.post(adminEndpoints.addProject, data)
}

export const getProjectsByAdmin = () => {
    return admin.get(adminEndpoints.getProjects)
}
export const getAssignedProjects = () => {
    return admin.get(adminEndpoints.getAssignedProjects)
}

export const projectRequest = () => {
    return admin.get(adminEndpoints.projectRequest)
}

export const getPendingRequests = () => {
    return admin.get(adminEndpoints.getPendingRequests)
}

export const updateProjectRequest = (data) => {
    return admin.post(adminEndpoints.projectRequest, data)
}

export const revokeRequest = (data) => {
    return admin.post(adminEndpoints.revokeRequest, data)
}

export const deleteProject = (projectId) => {
    return admin.delete(adminEndpoints.deleteProject + `?projectId=${projectId}`)
}

export const getAllReviewsByProject = (projectId) => {
    return admin.get(adminEndpoints.getAllReviewsByProject + `?projectId=${projectId}`)
}

export const getAllReviewsByAdminCompany = (type) => {
    return admin.get(adminEndpoints.getAllReviewsByCompany + `?type=${type}`)
}

export const clearEnquiry = (id) => {
    return admin.delete(adminEndpoints.clearEnquiry + `?docId=${id}`)
}

export const getEnquiry = () => {
    return admin.get(adminEndpoints.getEnquiry)
}
export const getCompanyDocuments = () => {
    return admin.get(adminEndpoints.getCompanyDocuments)
}
export const updateCompanyInformation = (data) => {
    return admin.patch(adminEndpoints.updateCompanyInformation, data)
}
export const updateProfile = (data) => {
    return admin.patch(adminEndpoints.updateProfile, data)
}
export const myReviews = () => {
    return admin.get(adminEndpoints.myReviews)
}

export const reportProjectOverView = (filter) => {
    return admin.get(adminEndpoints.reportProjectOverView + `?${filter}`)
}
export const reportFactorSplit = (filter) => {
    return admin.get(adminEndpoints.reportFactorSplit + `?${filter}`)
}
export const reportFactorImpression = (filter) => {
    return admin.get(adminEndpoints.reportFactorImpression + `?${filter}`)
}
export const reportClientSplit = (filter) => {
    return admin.get(adminEndpoints.reportClientSplit + `?${filter}`)
}
export const reportServiceSplit = (filter) => {
    return admin.get(adminEndpoints.reportServiceSplit + `?${filter}`)
}
export const reportClientsAdded = (filter) => {
    return admin.get(adminEndpoints.reportClientsAdded + `?${filter}`)
}
export const reportVendorsAdded = (filter) => {
    return admin.get(adminEndpoints.reportVendorsAdded + `?${filter}`)
}
export const leaderBoard = () => {
    return admin.get(adminEndpoints.leaderBoard)
}
export const getDashboardData = () => {
    return admin.get(adminEndpoints.getDashboardData)
}
export const getCompanySearch = () => {
    return admin.get(adminEndpoints.getCompanySearch)
}
export const validateEmail = (data) => {
    return admin.post(adminEndpoints.validateEmail, data)
}
export const getBubbleGraphData = (companyName, id) => {
    return admin.get(adminEndpoints.getBubbleGraphData + '/' + id + `?companyName=${companyName}`)
}
export const getAllFeedbacks = (companyName) => {
    return admin.get(adminEndpoints.getAllFeedbacks + `?companyName=${companyName}`)
}
export const hideReview = (data) => {
    return admin.patch(adminEndpoints.hideReview, data)
}
export const searchCompanyAndTagDetails = (search, location) => {
    return admin.get(adminEndpoints.searchCompanyAndTagDetails + `?search=${search?.trim() || ''}&location=${location?.trim() || ''}`)
}
export const getAllRequests = () => {
    return admin.get(adminEndpoints.getAllRequests)
}

export const getVendorInfo = (projectId, companyId) => {
    return admin.get('/vendor/info' + `?projectId=${projectId}` + `&companyId=${companyId}`)
}