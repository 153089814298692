import './App.css';
import './index.css';
import Routes from './Routes';
import { BrowserRouter as Router } from 'react-router-dom';
import { Toaster } from "react-hot-toast";
import Loader from './Components/Loader/Loader';
import { useInitialLoad } from './hooks';

function App() {
  let { isLoading } = useInitialLoad()
  if (isLoading) {
    return <Loader />
  }

  return (
    <div>
      <div><Toaster containerStyle={{ textAlign: 'center' }} /></div>
      <Router>
        <Routes />
      </Router>
    </div >
  );
}

export default App;

