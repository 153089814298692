import React, { useState } from 'react'
import { useAddEnquiry } from '../../hooks';
import { toast } from 'react-hot-toast';

function GetInTouch({ getInTouchActive, email, reason, setEmail, setReason, closeGetInTouch, companyId }) {

    const [additionalNote, setAdditionalNote] = useState('')
    const [getInTouch, setGetInTouch] = useState(getInTouchActive);


    let { isLoading, mutate } = useAddEnquiry(() => setGetInTouch(2))
    const getInTouchSubmit = (e, note) => {
        e.preventDefault();

        if (!email?.includes('@') || !reason) {
            return toast.error("Please provide the valid information")
        }
        let data = { email: email.toLowerCase(), reason, companyId, description: '' }
        if (note) {
            if (!additionalNote) return toast.error('Additional note is required', { id: 'error' })
            else {
                data.description = additionalNote?.slice(0, 500)
            }
        }
        mutate(data)
    }

    return (
        <div className={`fixed top-0 left-0 right-0 w-full flex items-center justify-center z-[100] p-4 overflow-x-hidden overflow-y-auto md:inset-0 ${getInTouch > 0 ? '' : 'hidden'}`}>
            <div style={{ opacity: 0.4 }} className='bg-black fixed inset-0'></div>
            <div className="relative w-full max-w-6xl z-10">
                <div className="relative bg-white rounded-lg shadow min-h-[620px] p-6 flex flex-col items-center overflow-hidden">
                    <button type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" onClick={() => { setAdditionalNote(''); closeGetInTouch() }}>
                        <svg aria-hidden="true" className="w-7 h-7" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </button>


                    {getInTouch == 1 ? <>
                        <div className=' bg-lightpink rounded border-2 border-bermuda max-w-max'>
                            <div className=' bg-lightgreen rounded border-2 border-bermuda transform -translate-x-1 -translate-y-1'>
                                <div className='bg-white rounded border-2 border-bermuda py-2 px-8 transform -translate-x-1 -translate-y-1'>
                                    <span className='text-3xl font-bold'>Get in Touch</span>
                                </div>
                            </div>
                        </div>
                        <div className='flex-grow py-6 z-20'>
                            <div className='grid grid-cols-3 gap-x-10 xl:gap-x-16 gap-y-6 my-6'>
                                <h1 className='text-2xl font-semibold text-right'>Email</h1>
                                <div className='bg-black rounded-md col-span-2'>
                                    <input
                                        placeholder="Enter your Email"
                                        pattern='^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9.-]+$'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        type='email' className='bg-white w-full outline-none rounded border-2 border-bermuda py-2 px-4 text-lg transform -translate-x-1 -translate-y-1' />
                                </div>

                                <h1 className='text-2xl font-semibold text-right'>Reason</h1>
                                <div className='bg-black rounded-md col-span-2'>
                                    <div className='pr-2 bg-white border-2 border-bermuda rounded transform -translate-x-1 -translate-y-1'>
                                        <select type="text" aria-describedby="text"
                                            className='bg-white w-full outline-none py-2 px-4 text-lg'
                                            placeholder="We are seeking out for..."
                                            value={reason}
                                            onChange={(e) => setReason(e.target.value)}
                                        >
                                            <option value="" disabled selected>Select Reason</option>
                                            <option value="General Enquiry">General Enquiry</option>
                                            <option value="Financial Enquiry">Financial Enquiry</option>
                                            <option value="Service Enquiry">Service Enquiry</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <h1 className='text-2xl font-semibold'>Reason to connect</h1>
                            <p className='w-full my-1 italic font-light'>Attach a note for the firm to get a better understanding on why you would like to connect</p>
                            <div className="relative w-full max-w-3xl">

                                <textarea name=""
                                    value={additionalNote}
                                    onChange={e => e.target.value.length <= 500 && setAdditionalNote(e.target.value)}
                                    maxLength={500}
                                    title='maximum character limit is 500'
                                    className='h-40 p-2 w-full max-w-3xl border border-bermuda rounded resize-none outline-none focus:border-gray-900 focus:outline-none' placeholder='Enter description'></textarea>

                                <span className='absolute bottom-4 right-4 text-sm text-gray-500'>{additionalNote?.length}/500</span>
                            </div>
                        </div>
                        <div className='flex items-center gap-10 justify-end w-full pr-6 pb-4 z-10 relative'>
                            <div className=' bg-lightgreen rounded border-2 border-bermuda transform max-w-max'>
                                <button disabled={isLoading} className=' bg-lightpink py-2 px-5 rounded border-2 border-bermuda -translate-x-1 -translate-y-1' onClick={(e) => getInTouchSubmit(e, false)}>
                                    <span className='text-base font-medium'>Submit without note</span>
                                </button>
                            </div>
                            <div className=' bg-lightpink rounded border-2 border-bermuda max-w-max'>
                                <button disabled={isLoading} className=' bg-lightgreen py-2 px-5 rounded border-2 border-bermuda transform -translate-x-1 -translate-y-1' onClick={(e) => getInTouchSubmit(e, true)}>
                                    <span className='text-base font-medium'>Submit</span>
                                </button>
                            </div>
                        </div>
                    </> :

                        <>
                            <div className='w-full min-h-[70vh] flex flex-col items-center justify-center' onClick={() => { setAdditionalNote(''); closeGetInTouch(); setGetInTouch(false) }}>
                                <svg width="214" height="215" viewBox="0 0 214 215" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="14.75" y="15.75" width="198.5" height="198.5" rx="5.25" fill="#F4BCC7" stroke="black" strokeWidth="1.5" />
                                    <rect x="7.75" y="9.75" width="198.5" height="198.5" rx="5.25" fill="#BDF0BC" stroke="black" strokeWidth="1.5" />
                                    <rect x="0.75" y="0.75" width="198.5" height="198.5" rx="5.25" fill="white" stroke="black" strokeWidth="1.5" />
                                    <path d="M70.2612 162.029L19.9801 112.127C18.0718 110.229 17 107.656 17 104.974C17 102.292 18.0718 99.7195 19.9801 97.8214L26.0888 91.7471C28.0001 89.8521 30.5906 88.7877 33.2914 88.7877C35.9922 88.7877 38.5828 89.8521 40.4941 91.7471L72.686 123.696C75.0766 126.068 78.9512 126.009 81.2678 123.564L163.123 37.1802C164.975 35.2285 167.531 34.0865 170.231 34.0047C172.93 33.9229 175.552 34.9082 177.52 36.7441L183.794 42.6237C185.761 44.4634 186.913 47.0037 186.995 49.6862C187.078 52.3688 186.084 54.974 184.233 56.9293L84.9096 161.803C83.9744 162.793 82.8484 163.586 81.5984 164.135C80.3485 164.684 79.0002 164.978 77.6336 164.999C76.2671 165.02 74.9101 164.768 73.6436 164.258C72.3771 163.747 71.2269 162.99 70.2612 162.029Z" fill="#333333" />
                                </svg>
                                <h1 className='text-3xl font-bold mt-8 mb-4'>Request sent successfully!</h1>
                                <p className='text-lg'>This firm will reach out to you shortly, using the entered email address.</p>
                            </div>
                        </>
                    }

                    <div className='absolute -bottom-60 -left-60'>
                        <svg width="663" className='h-full' viewBox="0 0 663 549" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle opacity="0.23" cx="312" cy="323" r="186" fill="#F4BCC7" />
                            <circle opacity="0.3" cx="477" cy="363" r="186" fill="#F4BCC7" />
                            <circle opacity="0.23" cx="186" cy="186" r="186" fill="#F4BCC7" />
                        </svg>
                    </div>
                    <div className='absolute -bottom-60 -right-60'>
                        <svg width="705" className='h-full' viewBox="0 0 805 673" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle opacity="0.23" cx="434.817" cy="377.366" r="186" transform="rotate(-60.4709 434.817 377.366)" fill="#BDF0BC" />
                            <circle opacity="0.23" cx="253.513" cy="419.476" r="186" transform="rotate(-60.4709 253.513 419.476)" fill="#BDF0BC" />
                            <circle opacity="0.3" cx="550.946" cy="253.512" r="186" transform="rotate(-60.4709 550.946 253.512)" fill="#BDF0BC" />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GetInTouch