import React, { useEffect, useRef, useState } from 'react';
import logo from '../../images/corner-cube-logo.svg';
import cameraicon from '../../images/cameraicon.svg';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import { config } from '../../services/config';
import { FileUploader } from "react-drag-drop-files";
import { bgPalette, uploadImage, useClickOutside } from '../../services/constant';
import { useSelector } from 'react-redux';
import { selectAdminDetails } from '../../slices/adminSlice';
import Circles from '../Reusable/Circles';
import { useAddCompanyInformation } from '../../hooks';

const fileTypes = ["JPG", "PNG"]

const colors = ["lightviolet", "babyblue", "lightpink", "babycream"]
export default function Profile() {
    const adminInfo = useSelector(selectAdminDetails);
    const history = useHistory()
    const [uploadLogo, setUploadLogo] = useState(null)
    const [email, setEmail] = useState("")
    const [description, setDescription] = useState("")
    const [searchValue, setSearchValue] = useState("");
    const [suggestions, setSuggestions] = useState([])
    const [searchResults, setSearchResults] = useState([])
    const [services, setServices] = useState([]);
    const moreRef = useRef()
    useClickOutside(() => { setSearchResults([]); setSearchValue('') }, moreRef)

    const handleRemoveService = (id) => {
        let arr = [...services];
        let index = arr.findIndex(item => item.id == id)
        if (index > -1) {
            setSuggestions([...suggestions, { ...arr[index] }])
            arr.splice(index, 1)
            setServices(arr)
        }
    }
    const handleRemoveSuggestion = (id) => {
        let arr = [...suggestions];
        let index = arr.findIndex(item => item.id == id)
        if (index > -1) {
            arr.splice(index, 1)
            setSuggestions(arr)
        }
    }
    const handleRemoveItems = (item) => {
        setSearchValue("")
        setServices([...services, { id: item.id, industryServices: item.industryServices }])
        setSearchResults([])
    }

    const handleUploadLogo = async (file) => {
        if (file) {
            const res = await uploadImage(file);
            if (res) {
                setUploadLogo(res)
            }
            setUploadLogo(res)
        }
    };


    useEffect(() => {
        search();
    }, [])

    const onChangeHandler = (e) => {
        setSearchValue(e.target.value);
        let array = suggestions.filter(item => item.industryServices.toLowerCase().includes(e.target.value.toLowerCase()))
        setSearchResults(array)
    };

    const search = () => {
        axios.get(config.adminUrl + `/searchOfferingTagByAdmin`)
            .then(function (response) {
                if (response.data.status === true) {
                    setSuggestions(response.data.searchOfferingTag)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };

    const { mutate: addCompanyInformation, isLoading } = useAddCompanyInformation((response) => {
        if (response.data.data.accessToken)
            localStorage.setItem('corner_qube', response.data.data.accessToken)
        history.push('/addusers')
    });

    const addCompanyDetails = () => {
        var payload = {
            "companyEmail": email.toLowerCase(),
            "companyIcon": uploadLogo,
            "aboutUs": description.trim(),
            "companyId": adminInfo?.companyId,
            "offeringTagId": services.map(item => item.id),
            "progress": 5,
            "adminId": adminInfo?.uuid
        }
        addCompanyInformation(payload);
    }

    return (
        <>
            <section className='z-10 relative'>
                <Link to="/"><img src={logo} className="absolute top-3 left-6" style={{ width: "120px" }} /></Link>
                <center style={{ color: "#47B000" }} className="pt-6 text-4xl font-semibold">We are thrilled to have you onboard!</center>
                <center style={{ color: "#E3B900" }} className="mt-2 text-3xl font-semibold">Lets get you all cubed up!</center>
                <div className="mt-6 flex px-16 z-10 relative">
                    <div className="mt-3 w-2/12 h-32">
                        <div className="rounded w-40 h-40 text-black-500 bg-lightgreen border-bermuda border-2 mx-auto">
                            <div className={`rounded relative cursor-pointer w-40 h-40 border-2 bg-white border-bermuda overflow-hidden transform -translate-x-1.5 -translate-y-2`}
                            >
                                <FileUploader
                                    multiple={false}
                                    name="uploadLogo"
                                    handleChange={handleUploadLogo}
                                    types={fileTypes}
                                    children={
                                        <div className=' w-40 h-40 cursor-pointer flex flex-col justify-center items-center'>
                                            {uploadLogo ? <img src={uploadLogo} className="w-full h-full object-cover" /> :
                                                <>
                                                    <div><img src={cameraicon} style={{ width: "25px" }} /></div>
                                                    <div className="text-base font-semibold">Upload Logo</div>
                                                </>
                                            }
                                        </div>
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-10/12 h-auto">
                        <div className='grid grid-cols-2 grid-gap-2'>
                            <div className='px-4'>
                                <div>
                                    <div className="mt-3 rounded w-full h-12 border-2 bg-lightgreen border-bermuda">
                                        <input type="text" aria-describedby="text"
                                            className="rounded w-full h-12 bg-gray-200 text-black-500 border-black border-2 px-4 transform -translate-x-1.5 -translate-y-2.5"
                                            placeholder="Enter Company Name here"
                                            name="companyName"
                                            disabled
                                            value={adminInfo?.companyName}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='px-4'>
                                <div>
                                    <div className="mt-3 rounded w-full h-12 border-2 bg-lightgreen border-bermuda">
                                        <input type="email" aria-describedby="text"
                                            className="] rounded w-full h-12 text-black-500 border-black border-2 px-4 transform -translate-x-1.5 -translate-y-2.5"
                                            placeholder="Enter Company Contact Email Id"
                                            name="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='px-4'>
                            <div className='mt-6 text-lg font-semibold'>What are the services that you offer <div className='float-right'>{services.length}/7</div></div>
                            <div className='mt-3 w-full z-20 relative'>
                                <div className="rounded w-full border-2 bg-lightgreen border-bermuda">
                                    <div className="rounded w-full h-32 bg-white text-black-500 border-black border-2 transform -translate-x-1.5 -translate-y-2 flex flex-wrap gap-3 p-4 items-start ">
                                        {services.map((data, i) => (
                                            <div key={i} className={`cursor-pointer inline-flex items-center rounded-full justify-center border-bermuda px-4 py-2 text-sm font-semibold text-gray-700 shadow-sm border-2 ${bgPalette[Math.abs((i - bgPalette.length) % bgPalette.length)]}`}>
                                                <span>{data.industryServices}</span>
                                                <svg onClick={() => handleRemoveService(data.id)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5 cursor-pointer">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                </svg>

                                            </div>
                                        ))}
                                        {services.length < 7 ?
                                            <form className='relative z-40'>
                                                <input list="searchForTags" type="text" aria-describedby="text"
                                                    className='p-2 border-none outline-none'
                                                    value={searchValue}
                                                    onChange={e => onChangeHandler(e)}
                                                    placeholder="Ex: Blockchain"
                                                />
                                                {searchResults.length ? <div className="absolute top-12 bg-white rounded-lg shadow-lg p-2 left-0 w-full min-w-max max-h-[240px] overflow-y-auto z-20" id="searchForTags" ref={moreRef}>
                                                    {searchResults.map((item, idx) => {
                                                        return (
                                                            <h1 onClick={() => { handleRemoveItems(item); handleRemoveSuggestion(item.id) }} className="min-w-max p-2 border-b last:border-none cursor-pointer" key={idx}>{item.industryServices}</h1>
                                                        )

                                                    })}
                                                </div> : <></>}
                                            </form> : <></>}
                                    </div>
                                </div>
                            </div>
                            <div className="mt-8 rounded w-full border-2 bg-lightgreen border-bermuda relative">
                                <textarea type="text" aria-describedby="text"
                                    className="resize-none text-sm rounded w-full h-52 text-black-500 border-black border-2 px-4 py-2 transform -translate-x-2 -translate-y-2"
                                    placeholder="Tell us about your firm"
                                    name="description"
                                    value={description}
                                    maxLength={1000}
                                    title='maximum character limit is 1000'
                                    onChange={(e) => e.target.value.length <= 1000 && setDescription(e.target.value)}
                                >
                                </textarea>
                                <p className='font-medium text-gray-400 text-sm absolute bottom-5 right-5'>{description.length}/1000</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='pt-8 pr-32 flex items-center justify-end'>
                    <div className="rounded-sm max-w-max text-black-500 bg-bermuda border-bermuda border-2 ">
                        <button disabled={isLoading} onClick={addCompanyDetails} className={`rounded-sm border-2 font-bold bg-babyblue disabled:bg-gray-300 border-bermuda px-6 py-2 transform -translate-x-1.5 -translate-y-2`}
                        >Save and Add Users</button>
                    </div>
                </div>

                <div>
                </div>

            </section>
            <Circles />
        </>
    );
}


