import React, { useEffect } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import Company from './Company/Company';
import Compare from './Components/Company/Compare';
import SearchResult from './Company/SearchResult';
import Explore from './Components/Explore';
import Dashboard from './Components/Dashboard/Dashboard';
import ForgotPassword from './Components/Login/ForgotPassword';
import Login from './Components/Login/Login';
import ResetPassword from './Components/Login/ResetPassword';
import ThanksForRegistering from './Components/Login/ThanksForRegistering';
import VerifyOtp from './Components/Login/VerifyOtp';
import Welcome from './Components/Welcome/Welcome';
import SearchBarPage from './Components/NavBar/SearchBarPage';
import AddUsers from './Components/Profile/AddUsers';
import Profile from './Components/Profile/Profile';
import Sector from './Components/Sector/Sector';
import Sector2 from './Components/Sector/Sector2';
import Sector3 from './Components/Sector/Sector3';
import ThanksforRegistering from './Components/Sector/ThanksforRegistering';
import SignUp from './Components/SignUp/SignUp';
import WelcomeNew from './Components/Welcome/WelcomeNew';
import WelcomeScreen from './Components/Welcome/WelcomeScreen';
import PrivateRoute from './PrivateRoute';
import AdminRoute from './AdminRoute';
import { useSelector } from 'react-redux';
import { selectAdminDetails } from './slices/adminSlice';
import Clients from './Components/Dashboard/ClientsHome';
import Vendors from './Components/Dashboard/VendorsHome';

import { selectReviewData } from './slices/reviewSlice';
import ReviewDetail from './Components/Reusable/ReviewDetail';
import Projects from './Components/Dashboard/ProjectsHome';
import Requests from './Components/Dashboard/RequestsHome';
import Pricing from './Components/Dashboard/Pricing';
import EnquiryHome from './Components/Dashboard/EnquiryHome';
import CompanyProfileHome from './Components/Dashboard/CompanyProfileHome';
import MyProfile from './Components/Dashboard/MyProfile';
import ReportHome from './Components/Dashboard/ReportHome';
import NotFound from './Components/Reusable/404';
import Home from './Components/Dashboard';
import PdfViewer from './Components/Reusable/PdfViewer';
import ReviewHome from './Components/Dashboard/ReviewHome';

export default function Routes() {
    const history = useHistory();
    const adminInfo = useSelector(selectAdminDetails);
    const reviewData = useSelector(selectReviewData)

    useEffect(() => {
        if (adminInfo) {
            if (adminInfo?.progress == 1 || adminInfo?.progress == 2) {
                history.push('/welcome')
            }
            else if (adminInfo?.progress == 3) {
                history.push('/home')
            }
            else if (adminInfo?.progress == 4) {
                history.push('/profile')
            }
        }
    }, [adminInfo])

    return (
        <>
            {reviewData?.open && <ReviewDetail reviewData={reviewData} />}
            <PdfViewer />
            <Switch>
                <Route exact path="/" component={SearchBarPage} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/welcomenew" component={WelcomeNew} />
                <Route exact path="/forgotpassword" component={ForgotPassword} />
                <Route exact path="/resetpassword" component={ResetPassword} />
                <Route exact path="/thanksforregistering" component={ThanksForRegistering} />
                <Route exact path="/verifyotp" component={VerifyOtp} />
                <Route exact path="/welcome" component={Welcome} />
                <Route exact path="/company/:companyName" component={Company} />
                <Route exact path="/searchresults" component={SearchResult} />
                <Route exact path="/compare/companyCompareData/" component={Compare} />
                <Route exact path="/signup" component={SignUp} />
                <Route exact path="/sector" component={Sector} />
                <Route exact path="/sector2" component={Sector2} />
                <Route exact path="/sector3" component={Sector3} />
                <Route exact path="/thanks" component={ThanksforRegistering} />
                <Route exact path="/pricing" component={Pricing} />

                <Route exact path="/explore" component={Explore} />

                {/* <AdminRoute exact path="/admin/clients" title="Clients" component={Clients} /> */}
                {/* <AdminRoute exact path="/admin/vendors" title="Vendors" component={Vendors} /> */}
                {/* <AdminRoute exact path="/admin/projects" title="Projects" component={Projects} /> */}
                {/* <AdminRoute exact path="/admin/requests" title="Requests" component={Requests} /> */}
                {/* <AdminRoute exact path="/admin/enquiries" title="Enquiries" component={EnquiryHome} /> */}
                {/* <AdminRoute exact path="/admin/profile" title="My Profile" component={MyProfile} /> */}
                {/* <AdminRoute exact path="/admin/report" title="Report" component={ReportHome} /> */}
                {/* <AdminRoute exact path="/admin/reviews" title="Reviews" component={ReviewHome} /> */}
                {/* <AdminRoute exact path="/dashboard/:companyName" title="Dashboard" component={Home} /> */}
                {/* <AdminRoute exact path="/dashboard/:companyName/profile" title="Company Profile" component={CompanyProfileHome} /> */}
                {/* <AdminRoute exact path="/dashboard" title="Dashboard" component={Home} /> */}
                {/* <AdminRoute exact path="/admin" title="User Access" component={Dashboard} /> */}
                {/* <AdminRoute exact path="/admin/access" title="User Access" component={Dashboard} /> */}

                <PrivateRoute exact path="/profile" component={Profile} />
                <PrivateRoute exact path="/addusers" component={AddUsers} />
                <PrivateRoute exact path="/home" component={WelcomeScreen} />

                <PrivateRoute path="/home/:companyName" component={WelcomeScreen} exact />

                <Route exact path="/404" component={NotFound} />
                <Route exact path="*" component={NotFound} />
            </Switch>
        </>
    )
}
