import { createRef, useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export const encryptPassword = (password) => simpleCrypto.encrypt(password)

export function encrypt(message) {
    let encryptedMessage = '';
    for (let i = 0; i < message.length; i++) {
        const char = message[i];
        const charCode = char.charCodeAt(0);
        const keyCharCode = key.charCodeAt(i % key.length); // Retrieve the character code of the key at the corresponding position
        const encryptedCharCode = (charCode + keyCharCode) % 256; // Perform simple shift using the key value
        const encryptedChar = String.fromCharCode(encryptedCharCode);
        encryptedMessage += encryptedChar;
    }
    return encryptedMessage;
}

export function decrypt(encryptedMessage) {
    let decryptedMessage = '';
    for (let i = 0; i < encryptedMessage.length; i++) {
        const char = encryptedMessage[i];
        const charCode = char.charCodeAt(0);
        const keyCharCode = key.charCodeAt(i % key.length); // Retrieve the character code of the key at the corresponding position
        const decryptedCharCode = (charCode - keyCharCode + 256) % 256; // Reverse the shift using the key value
        const decryptedChar = String.fromCharCode(decryptedCharCode);
        decryptedMessage += decryptedChar;
    }
    return decryptedMessage;
}

export const openLink = (link) => {
    if (!link?.substring(0, 4)?.includes('http')) {
        link = 'https://' + link
    }
    return window.open(link, '_blank')
}

export const bgPalette = ['bg-lightblue', 'bg-lightviolet', 'bg-lightpink', 'bg-lightgreen', 'bg-cream']

export const groupBy = (x, f) => x.reduce((a, b) => ((a[f(b)] ||= []).push(b), a), {});

export const fetchPlace = async (text) => {
    try {
        const res = await fetch(
            `https://api.aqqess.me/api/location?search=${text}`
        );
        if (!res.ok) throw new Error(res.statusText);

        return res.json();
    } catch (err) {
        return { error: "Unable to retrieve places" };
    }
};

export const useClickOutside = (handler, ref = null) => {
    const domRef = ref || createRef(null);

    useEffect(() => {
        const localHandler = (e) => {
            if (!domRef.current) return;
            if (!domRef.current.contains(e.target)) handler();
        };
        document.addEventListener("mousedown", localHandler);
        return () => document.removeEventListener("mousedown", localHandler);
    }, [domRef, handler]);

    return domRef;
};


export default function useDebounce(value, delay = 300) {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value)
        }, delay);
        return () => {
            clearTimeout(handler);
        }
    }, [value, delay]);

    return debouncedValue;
}

export function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowSize;
}

export const uploadImage = async (image) => {
    let data = new FormData();
    // let tId = toast.loading("Uploading image...");
    data.append("file", image)
    data.append("upload_preset", `qube_preset`)
    data.append("cloud_name", `cornerqube`)
    const resp = await fetch(`https://api.cloudinary.com/v1_1/cornerqube/image/upload`, {
        method: "post",
        body: data
    })
    let res = await resp.json();
    // toast.dismiss(tId);
    if (res.secure_url)
        console.log(`File "${image?.name}" Uploaded successfully`, { id: 'success' })
    else
        console.log(`Unable to Upload File "${image?.name}"`, { id: 'error' })
    return res.secure_url
}

export const decodeToken = () => {
    const jwtToken = localStorage.getItem("corner_qube")

    if (!jwtToken) {
        return false;
    }
    var decoded = jwt_decode(jwtToken);
    return decoded?.userInfo
}

export function isValidEmail(inputText) {
    var mailFormat = /^[a-z0-9+_.-]+@[a-z0-9.-]+$/;
    if (inputText.match(mailFormat)) {
        return true;
    } else {
        return false;
    }
}

export function formatNumber(num) {
    if (num >= 1e9) {
        return (num / 1e9).toFixed(1) + 'B';
    }
    if (num >= 1e6) {
        return (num / 1e6).toFixed(1) + 'M';
    }
    if (num >= 1e3) {
        return (num / 1e3).toFixed(1) + 'K';
    }
    return num.toString();
}


export const getSum = (arr, property) => Array.isArray(arr) ? arr.reduce((sum, item) => sum + (isNaN(Number(item[property])) ? 0 : Number(item[property])), 0) : 0;
export const getObjectWithMaxValue = (arr, fieldName) => Array.isArray(arr) ? arr.reduce((max, obj) => (obj[fieldName] > (max ? max[fieldName] : -Infinity) ? obj : max), null) : {};


const Icon1 = () => {
    return (<svg width="91" height="81" viewBox="0 0 91 81" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M29.8226 53.1576H15.681C13.4755 53.1576 11.668 51.2509 11.668 48.9165V30.8212C11.668 28.4904 13.4721 26.5801 15.681 26.5801H29.8226C32.0281 26.5801 33.8357 28.4868 33.8357 30.8212V48.9165C33.8322 51.2509 32.0281 53.1576 29.8226 53.1576Z" fill="#B9DDB4" stroke="black" strokeMiterlimit="10" />
        <path d="M22.75 37.2402V67.3048" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M17.7578 41.2246L22.7518 53.1577" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M27.2912 38.8105L22.75 44.7771" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M79.5482 54.9606H68.3391C66.679 54.9606 65.3242 53.5251 65.3242 51.7743V38.8445C65.3242 37.09 66.6825 35.6582 68.3391 35.6582H79.5482C81.2083 35.6582 82.5631 37.0937 82.5631 38.8445V51.7743C82.5665 53.5251 81.2083 54.9606 79.5482 54.9606Z" fill="#8CB876" stroke="#231F20" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M73.9492 43.9824V73.9926" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M70.0586 47.0762L73.9447 56.3558" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M77.4786 45.1992L73.9492 49.839" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M44.1506 10.4246C44.1506 11.7368 43.1456 12.7953 41.9074 12.7953H25.6873C24.4902 12.7953 23.5195 11.7695 23.5195 10.5044C23.5195 9.23931 24.4902 8.21347 25.6873 8.21347C25.7696 8.21347 25.8485 8.2171 25.9239 8.22797C25.9205 8.22435 25.9205 8.22072 25.9205 8.21347C25.7696 7.83286 25.6907 7.416 25.6907 6.9774C25.6907 5.18672 27.0627 3.73315 28.7605 3.73315C29.1378 3.73315 29.4945 3.80564 29.8272 3.93614C30.3863 2.22883 31.9229 1 33.7305 1C35.7027 1 37.3525 2.4572 37.7641 4.40737C38.1036 4.2805 38.4706 4.21525 38.8514 4.21525C40.6864 4.21525 42.175 5.78844 42.175 7.72775C42.175 7.84374 42.1715 7.95611 42.1612 8.06485C43.2794 8.19535 44.1506 9.20306 44.1506 10.4246Z" fill="#C4E5F2" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M25.9242 8.22543C25.8487 8.21818 25.7698 8.21094 25.6875 8.21094H25.9173C25.9173 8.21819 25.9207 8.22181 25.9242 8.22543Z" fill="#C4E5F2" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M70.2404 19.6395C70.2404 20.9517 69.2355 22.0101 67.9973 22.0101H51.7771C50.58 22.0101 49.6094 20.9843 49.6094 19.7192C49.6094 18.4542 50.58 17.4283 51.7771 17.4283C51.8594 17.4283 51.9383 17.4319 52.0138 17.4428C52.0103 17.4392 52.0103 17.4356 52.0103 17.4283C51.8594 17.0477 51.7805 16.6308 51.7805 16.1922C51.7805 14.4016 53.1525 12.948 54.8503 12.948C55.2276 12.948 55.5843 13.0205 55.917 13.151C56.4761 11.4437 58.0127 10.2148 59.8203 10.2148C61.7925 10.2148 63.4423 11.672 63.8539 13.6222C64.1935 13.4953 64.5605 13.4301 64.9412 13.4301C66.7762 13.4301 68.2648 15.0033 68.2648 16.9426C68.2648 17.0586 68.2614 17.1709 68.2511 17.2797C69.3692 17.4102 70.2404 18.4179 70.2404 19.6395Z" fill="#C4E5F2" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M52.014 17.4422C51.9386 17.435 51.8597 17.4277 51.7773 17.4277H52.0072C52.0072 17.435 52.0106 17.4386 52.014 17.4422Z" fill="#C4E5F2" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M59.0312 75.7523C59.0312 76.1329 59.0518 76.5026 59.0964 76.8687H84.9787C84.4642 73.6462 81.8129 71.1922 78.6128 71.1922C77.6146 71.1922 76.6714 71.4314 75.8311 71.8592C74.4385 68.6657 71.3825 66.4473 67.8359 66.4473C62.9723 66.4436 59.0312 70.6122 59.0312 75.7523Z" fill="#8CB876" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M55.0433 74.7223C55.033 75.4581 54.8855 76.1976 54.6111 76.8682H8.33095C8.32066 76.832 8.30694 76.803 8.29665 76.7667C7.10303 72.8446 11.1195 69.2016 14.6283 70.8763C14.4431 67.3747 16.9401 63.9274 20.1848 63.2025C23.4364 62.4703 27.0344 64.5509 28.2109 67.8314C30.2242 67.1064 32.6252 67.7952 34.0143 69.5025C34.4533 65.9719 37.5986 63.0466 40.9668 63.043C44.335 63.0357 47.4905 65.9501 47.9399 69.4735C50.3648 68.6398 53.3043 69.8178 54.491 72.203C54.8855 72.9787 55.0604 73.8487 55.0433 74.7223Z" fill="#8CB876" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1 77.0449H90" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M30.3359 80H46.0553" stroke="#231F20" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.3906 78.6035H28.11" stroke="#231F20" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M49.6094 78.6035H65.3253" stroke="#231F20" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
    )
}

const Icon2 = () => {
    return (<svg width="91" height="81" viewBox="0 0 91 81" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M79.376 57.4451H67.9788C66.291 57.4451 64.9102 56.1608 64.9102 54.5911V43.02C64.9102 41.4503 66.291 40.166 67.9788 40.166H79.376C81.0638 40.166 82.4446 41.4503 82.4446 43.02V54.5911C82.4446 56.1608 81.0638 57.4451 79.376 57.4451Z" fill="#8CB876" stroke="#231F20" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M73.6758 47.6172V74.4758" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M69.7266 50.3906L73.6757 58.6952" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M77.2681 48.709L73.6758 52.8628" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M41.5606 27.7109H31.0039V41.5715H41.5606V27.7109Z" fill="#EFD789" stroke="black" strokeMiterlimit="10" />
        <path d="M38.3585 33.9551H34.1992V35.3262H38.3585V33.9551Z" fill="#D6BA6B" />
        <path d="M50.8496 29.5039L30.7969 48.179V74.4948H70.4921V48.179L50.8496 29.5039Z" fill="#EFD789" stroke="black" strokeMiterlimit="10" />
        <path d="M23.3672 48.1783L31.0021 40.9627L41.5588 31.1443L47.0856 26.004L50.848 22.5078L78.4256 48.1876L70.4905 48.1783L69.2531 46.9995L50.848 29.5032L41.5588 38.1428L37.8731 41.5707L30.7953 48.1783H23.3672Z" fill="#EAA0B2" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M43.2161 25.2773H28.6602V27.7094H43.2161V25.2773Z" fill="#EAA0B2" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M73.1316 74.4941H28.1562V77.1527H73.1316V74.4941Z" fill="#EAA0B2" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M57.6998 54.5H43.8477V67.3833H57.6998V54.5Z" fill="black" stroke="black" strokeMiterlimit="10" />
        <path d="M59.9067 67.3809H41.3516V69.1615H59.9067V67.3809Z" fill="#D6BA6B" stroke="black" strokeMiterlimit="10" />
        <path d="M50.0927 55.7598H45.7266V60.2238H50.0927V55.7598Z" fill="#61AFAF" />
        <path d="M55.8153 55.7598H51.4492V60.2238H55.8153V55.7598Z" fill="#61AFAF" />
        <path d="M55.8153 61.6602H51.4492V66.1242H55.8153V61.6602Z" fill="#61AFAF" />
        <path d="M50.0927 61.6602H45.7266V66.1242H50.0927V61.6602Z" fill="#61AFAF" />
        <path d="M50.8977 48.1186C53.3458 48.1186 55.3305 46.2728 55.3305 43.9958C55.3305 41.7189 53.3458 39.873 50.8977 39.873C48.4495 39.873 46.4648 41.7189 46.4648 43.9958C46.4648 46.2728 48.4495 48.1186 50.8977 48.1186Z" fill="#E094AA" stroke="black" strokeMiterlimit="10" />
        <path d="M50.8972 47.2725C52.8425 47.2725 54.4195 45.8058 54.4195 43.9966C54.4195 42.1874 52.8425 40.7207 50.8972 40.7207C48.952 40.7207 47.375 42.1874 47.375 43.9966C47.375 45.8058 48.952 47.2725 50.8972 47.2725Z" fill="#49918F" stroke="black" strokeMiterlimit="10" />
        <path d="M36.5656 30.8594H32.4062V32.2305H36.5656V30.8594Z" fill="#D6BA6B" />
        <path d="M40.4484 28.8184H36.2891V30.1895H40.4484V28.8184Z" fill="#D6BA6B" />
        <path d="M28.7971 55.8316H14.4146C12.1699 55.8316 10.332 54.1223 10.332 52.0345V35.8381C10.332 33.7503 12.1699 32.041 14.4146 32.041H28.7971C31.0419 32.041 32.8797 33.7503 32.8797 35.8381V52.0345C32.8764 54.1223 31.0419 55.8316 28.7971 55.8316Z" fill="#B9DDB4" stroke="black" strokeMiterlimit="10" />
        <path d="M21.6016 41.5859V68.4911" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16.5273 45.1504L21.6039 55.8312" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M26.2212 42.9883L21.6016 48.3302" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M47.8076 16.4008C47.8076 17.5734 46.7869 18.5227 45.5261 18.5227H29.029C27.8115 18.5227 26.8242 17.6045 26.8242 16.4722C26.8242 15.3399 27.8115 14.4216 29.029 14.4216C29.1123 14.4216 29.1924 14.4278 29.2691 14.434C29.2658 14.4278 29.2624 14.4278 29.2624 14.4216C29.109 14.0804 29.0256 13.7081 29.0256 13.3141C29.0256 11.7103 30.4232 10.4105 32.1476 10.4105C32.5312 10.4105 32.8948 10.4757 33.235 10.5904C33.8053 9.06106 35.3663 7.96289 37.2075 7.96289C39.2121 7.96289 40.8898 9.26891 41.3101 11.0154C41.657 10.9038 42.0272 10.8417 42.4175 10.8417C44.2853 10.8417 45.7963 12.247 45.7963 13.9842C45.7963 14.0866 45.7896 14.189 45.7796 14.2882C46.9204 14.4061 47.8076 15.3088 47.8076 16.4008Z" fill="#C4E5F2" stroke="black" strokeMiterlimit="10" />
        <path d="M29.2675 14.4343C29.1908 14.4281 29.1107 14.4219 29.0273 14.4219H29.2642C29.2608 14.4281 29.2642 14.4312 29.2675 14.4343Z" fill="#C4E5F2" stroke="black" strokeMiterlimit="10" />
        <path d="M77.2646 9.43792C77.2646 10.6105 76.244 11.5598 74.9832 11.5598H58.486C57.2686 11.5598 56.2812 10.6416 56.2812 9.50927C56.2812 8.37698 57.2686 7.45873 58.486 7.45873C58.5694 7.45873 58.6494 7.46493 58.7261 7.47114C58.7228 7.46493 58.7195 7.46494 58.7195 7.45873C58.566 7.11749 58.4827 6.74523 58.4827 6.35125C58.4827 4.74743 59.8802 3.44762 61.6046 3.44762C61.9882 3.44762 62.3518 3.51276 62.692 3.62754C63.2624 2.09817 64.8234 1 66.6645 1C68.6691 1 70.3469 2.30601 70.7671 4.05254C71.114 3.94086 71.4843 3.87882 71.8745 3.87882C73.7424 3.87882 75.2533 5.28411 75.2533 7.02132C75.2533 7.1237 75.2467 7.22607 75.2367 7.32534C76.3774 7.44322 77.2646 8.34595 77.2646 9.43792Z" fill="#C4E5F2" stroke="black" strokeMiterlimit="10" />
        <path d="M58.7245 7.47139C58.6478 7.46519 58.5678 7.45898 58.4844 7.45898H58.7212C58.7179 7.46519 58.7212 7.46829 58.7245 7.47139Z" fill="#C4E5F2" stroke="black" strokeMiterlimit="10" />
        <path d="M60.4805 76.05C60.4805 76.3882 60.5005 76.7201 60.5438 77.0489H86.8673C86.3436 74.1639 83.6452 71.9676 80.3932 71.9676C79.3792 71.9676 78.4186 72.1816 77.5647 72.5632C76.1471 69.703 73.0385 67.7207 69.4328 67.7207C64.4864 67.7207 60.4805 71.4495 60.4805 76.05Z" fill="#8CB876" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M54.446 75.1301C54.436 75.7909 54.2859 76.4485 54.0058 77.0504H6.93913C6.92913 77.0193 6.91578 76.9914 6.90578 76.9604C5.69167 73.4487 9.77761 70.1884 13.3465 71.6867C13.1564 68.5535 15.698 65.4668 18.9968 64.8185C22.3022 64.1639 25.9646 66.0252 27.1587 68.963C29.2066 68.3146 31.6482 68.932 33.0624 70.4582C33.5094 67.2971 36.7048 64.682 40.1336 64.6758C43.5591 64.6696 46.7678 67.2785 47.2281 70.4334C49.693 69.6889 52.6849 70.7405 53.8924 72.8748C54.2859 73.5697 54.4627 74.3484 54.446 75.1301Z" fill="#8CB876" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1 77.2109H90" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M30.3633 80H39.1622" stroke="#231F20" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.0742 78.6875H27.0611" stroke="#231F20" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M62.4961 78.6875H78.4829" stroke="#231F20" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M45.6367 80H55.8132" stroke="#231F20" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
    )
}

const Icon3 = () => {
    return (
        <svg width="91" height="81" viewBox="0 0 91 81" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M77.229 57.6244H65.6798C63.9696 57.6244 62.5703 56.3482 62.5703 54.7885V43.2909C62.5703 41.7312 63.9696 40.4551 65.6798 40.4551H77.229C78.9393 40.4551 80.3385 41.7312 80.3385 43.2909V54.7885C80.3352 56.3482 78.9359 57.6244 77.229 57.6244Z" fill="#8CB876" stroke="#231F20" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M71.4531 47.8594V74.5504" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M67.4531 50.6152L71.4549 58.867" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M75.0933 48.9434L71.4531 53.0708" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M77.0951 9.88819C77.0951 11.0534 76.0608 11.9966 74.7832 11.9966H58.0662C56.8325 11.9966 55.832 11.0842 55.832 9.95908C55.832 8.83399 56.8325 7.92158 58.0662 7.92158C58.1507 7.92158 58.2318 7.92775 58.3095 7.93391C58.3061 7.92775 58.3028 7.92774 58.3028 7.92158C58.1473 7.58251 58.0628 7.21262 58.0628 6.82114C58.0628 5.22751 59.479 3.93596 61.2264 3.93596C61.6151 3.93596 61.9835 4.0007 62.3282 4.11475C62.9062 2.5951 64.488 1.50391 66.3537 1.50391C68.385 1.50391 70.0852 2.80162 70.511 4.53704C70.8625 4.42607 71.2377 4.36443 71.6331 4.36443C73.5259 4.36443 75.057 5.76078 75.057 7.48695C75.057 7.58867 75.0503 7.6904 75.0401 7.78904C76.196 7.90617 77.0951 8.80316 77.0951 9.88819Z" fill="#C4E5F2" stroke="black" strokeMiterlimit="10" />
            <path d="M58.3098 7.93421C58.232 7.92804 58.1509 7.92188 58.0664 7.92188H58.3064C58.303 7.92804 58.3064 7.93113 58.3098 7.93421Z" fill="#C4E5F2" stroke="black" strokeMiterlimit="10" />
            <path d="M31.3217 17.978C31.3217 19.1432 30.2874 20.0864 29.0098 20.0864H12.2927C11.059 20.0864 10.0586 19.174 10.0586 18.0489C10.0586 16.9238 11.059 16.0114 12.2927 16.0114C12.3772 16.0114 12.4583 16.0176 12.5361 16.0238C12.5327 16.0176 12.5293 16.0176 12.5293 16.0114C12.3738 15.6724 12.2893 15.3025 12.2893 14.911C12.2893 13.3174 13.7055 12.0258 15.4529 12.0258C15.8416 12.0258 16.21 12.0905 16.5548 12.2046C17.1328 10.6849 18.7146 9.59375 20.5803 9.59375C22.6116 9.59375 24.3117 10.8915 24.7376 12.6269C25.0891 12.5159 25.4643 12.4543 25.8597 12.4543C27.7525 12.4543 29.2836 13.8506 29.2836 15.5768C29.2836 15.6785 29.2768 15.7802 29.2667 15.8789C30.4226 15.996 31.3217 16.8899 31.3217 17.978Z" fill="#C4E5F2" stroke="black" strokeMiterlimit="10" />
            <path d="M12.5363 16.0241C12.4586 16.0179 12.3775 16.0117 12.293 16.0117H12.5329C12.5296 16.0179 12.5329 16.0179 12.5363 16.0241Z" fill="#C4E5F2" stroke="black" strokeMiterlimit="10" />
            <path d="M38.545 6.19727H27.7969V20.0375H38.545V6.19727Z" fill="#EFD789" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M68.0107 26.6341L67.8654 26.5015V25.8357H67.1488L48.0084 7.98828L28.2662 25.8357H27.4449V26.5755L27.3672 26.6464H27.4449V74.5724H67.8654V26.6341H68.0107Z" fill="#EFD789" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M69.8716 74.5723H24.0703V77.2293H69.8716V74.5723Z" fill="#EAA0B2" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M35.571 12.3164H31.3359V13.685H35.571V12.3164Z" fill="#D6BA6B" />
            <path d="M55.0185 51.7656H40.9141V64.6287H55.0185V51.7656Z" fill="black" stroke="black" strokeMiterlimit="10" />
            <path d="M57.2687 64.6289H38.375V66.4075H57.2687V64.6289Z" fill="#D6BA6B" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M47.2975 53.2031H43.0117V57.5001H47.2975V53.2031Z" fill="#61AFAF" />
            <path d="M52.9225 53.2031H48.6367V57.5001H52.9225V53.2031Z" fill="#61AFAF" />
            <path d="M52.9225 58.8906H48.6367V63.1876H52.9225V58.8906Z" fill="#61AFAF" />
            <path d="M47.2975 58.8906H43.0117V63.1876H47.2975V58.8906Z" fill="#61AFAF" />
            <path d="M55.0185 33.1094H40.9141V45.9725H55.0185V33.1094Z" fill="black" stroke="black" strokeMiterlimit="10" />
            <path d="M57.2687 45.9727H38.375V47.7512H57.2687V45.9727Z" fill="#D6BA6B" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M47.2885 34.4824H42.9453V38.8379H47.2885V34.4824Z" fill="#61AFAF" />
            <path d="M52.9877 34.4824H48.6445V38.8379H52.9877V34.4824Z" fill="#61AFAF" />
            <path d="M52.9877 40.2441H48.6445V44.5997H52.9877V40.2441Z" fill="#61AFAF" />
            <path d="M47.2885 40.2441H42.9453V44.5997H47.2885V40.2441Z" fill="#61AFAF" />
            <path d="M19.8047 26.646L27.7982 19.43L38.5497 9.6278L44.1773 4.49551L48.0101 1L76.0905 26.646L68.0124 26.6337L66.7484 25.4593L48.0101 7.98793L38.5497 16.6157L34.798 20.0373L27.3689 26.646H19.8047Z" fill="#EAA0B2" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M40.5944 3.76758H25.7734V6.19656H40.5944V3.76758Z" fill="#EAA0B2" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M48.0234 29.0938C50.5173 29.0938 52.539 27.25 52.539 24.9756C52.539 22.7012 50.5173 20.8574 48.0234 20.8574C45.5295 20.8574 43.5078 22.7012 43.5078 24.9756C43.5078 27.25 45.5295 29.0938 48.0234 29.0938Z" fill="#E094AA" stroke="black" strokeMiterlimit="10" />
            <path d="M48.0236 28.2461C50.0041 28.2461 51.6097 26.7818 51.6097 24.9756C51.6097 23.1693 50.0041 21.7051 48.0236 21.7051C46.0431 21.7051 44.4375 23.1693 44.4375 24.9756C44.4375 26.7818 46.0431 28.2461 48.0236 28.2461Z" fill="#49918F" stroke="black" strokeMiterlimit="10" />
            <path d="M32.903 9.22656H28.668V10.5952H32.903V9.22656Z" fill="#D6BA6B" />
            <path d="M37.6999 7.1875H33.4648V8.55611H37.6999V7.1875Z" fill="#D6BA6B" />
            <path d="M25.0433 54.0261H10.4691C8.19437 54.0261 6.33203 52.3277 6.33203 50.2532V34.1596C6.33203 32.0851 8.19437 30.3867 10.4691 30.3867H25.0433C27.3179 30.3867 29.1803 32.0851 29.1803 34.1596V50.2532C29.1803 52.3277 27.3179 54.0261 25.0433 54.0261Z" fill="#B9DDB4" stroke="black" strokeMiterlimit="10" />
            <path d="M17.7578 39.8711V66.6083" stroke="black" strokeMiterlimit="10" />
            <path d="M12.6094 43.4141L17.757 54.027" stroke="black" strokeMiterlimit="10" />
            <path d="M22.439 41.2637L17.7578 46.5717" stroke="black" strokeMiterlimit="10" />
            <path d="M60.082 76.0772C60.082 76.4132 60.1023 76.743 60.1463 77.0697H86.8206C86.2899 74.203 83.5556 72.0207 80.2601 72.0207C79.2326 72.0207 78.2592 72.2334 77.394 72.6125C75.9575 69.7705 72.8074 67.8008 69.1537 67.8008C64.1447 67.8008 60.082 71.5059 60.082 76.0772Z" fill="#8CB876" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M53.9733 75.1613C53.9631 75.8179 53.811 76.4714 53.5271 77.0694H5.83311C5.82297 77.0385 5.80945 77.0108 5.79931 76.98C4.56902 73.4906 8.70942 70.251 12.3259 71.7398C12.1333 68.6265 14.7088 65.5595 18.0515 64.9152C21.401 64.2648 25.1122 66.1143 26.3222 69.0334C28.3974 68.3892 30.8715 69.0026 32.3046 70.5191C32.7575 67.3781 35.9955 64.7796 39.47 64.7734C42.9412 64.7673 46.1927 67.3596 46.6591 70.4945C49.1569 69.7547 52.1887 70.7996 53.4122 72.9204C53.811 73.6109 53.9902 74.3845 53.9733 75.1613Z" fill="#8CB876" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1 77.2285H90" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M29.5664 80H38.486" stroke="#231F20" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.0156 78.6973H26.2156" stroke="#231F20" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M62.125 79.4277H78.325" stroke="#231F20" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M45.0469 80H55.359" stroke="#231F20" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

const Icon4 = () => {
    return (
        <svg width="91" height="81" viewBox="0 0 91 81" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M77.0971 61.5309H65.7534C64.0736 61.5309 62.6992 60.4795 62.6992 59.1944V49.7213C62.6992 48.4362 64.0736 47.3848 65.7534 47.3848H77.0971C78.7769 47.3848 80.1513 48.4362 80.1513 49.7213V59.1944C80.148 60.4795 78.7769 61.5309 77.0971 61.5309Z" fill="#8CB876" stroke="#231F20" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M71.4258 53.4844V75.4756" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M67.4922 55.7559L71.4228 62.5546" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M75.0012 54.3789L71.4258 57.7796" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M21.8847 30.2791C21.8847 31.2391 20.8689 32.0162 19.614 32.0162H3.19438C1.98266 32.0162 1 31.2645 1 30.3375C1 29.4105 1.98266 28.6587 3.19438 28.6587C3.27737 28.6587 3.35704 28.6638 3.4334 28.6689C3.43008 28.6638 3.42676 28.6638 3.42676 28.6587C3.27405 28.3794 3.19106 28.0746 3.19106 27.7521C3.19106 26.439 4.58205 25.3749 6.29837 25.3749C6.68015 25.3749 7.042 25.4282 7.38062 25.5222C7.9483 24.2701 9.50196 23.3711 11.3345 23.3711C13.3297 23.3711 14.9995 24.4403 15.4178 25.8702C15.7631 25.7787 16.1316 25.7279 16.52 25.7279C18.3791 25.7279 19.8829 26.8784 19.8829 28.3006C19.8829 28.3845 19.8763 28.4683 19.8663 28.5495C21.0017 28.6461 21.8847 29.3851 21.8847 30.2791Z" fill="#C4E5F2" stroke="black" strokeMiterlimit="10" />
            <path d="M3.43434 28.6684C3.35798 28.6633 3.27831 28.6582 3.19531 28.6582H3.43102C3.43102 28.6633 3.43102 28.6658 3.43434 28.6684Z" fill="#C4E5F2" stroke="black" strokeMiterlimit="10" />
            <path d="M82.2285 14.3259C82.2285 15.286 81.2126 16.0631 79.9578 16.0631H63.5381C62.3264 16.0631 61.3438 15.3113 61.3438 14.3844C61.3438 13.4574 62.3264 12.7056 63.5381 12.7056C63.6211 12.7056 63.7008 12.7107 63.7772 12.7158C63.7738 12.7107 63.7705 12.7107 63.7705 12.7056C63.6178 12.4263 63.5348 12.1215 63.5348 11.7989C63.5348 10.4859 64.9258 9.42179 66.6421 9.42179C67.0239 9.42179 67.3858 9.47512 67.7244 9.56909C68.2921 8.31702 69.8457 7.41797 71.6782 7.41797C73.6734 7.41797 75.3433 8.48718 75.7616 9.91703C76.1068 9.8256 76.4753 9.77481 76.8637 9.77481C78.7228 9.77481 80.2267 10.9253 80.2267 12.3475C80.2267 12.4313 80.22 12.5151 80.2101 12.5964C81.3454 12.6929 82.2285 13.4294 82.2285 14.3259Z" fill="#C4E5F2" stroke="black" strokeMiterlimit="10" />
            <path d="M63.7781 12.7152C63.7017 12.7102 63.6221 12.7051 63.5391 12.7051H63.7748C63.7714 12.7102 63.7748 12.7102 63.7781 12.7152Z" fill="#C4E5F2" stroke="black" strokeMiterlimit="10" />
            <path d="M38.7401 5.29688H28.1367V16.7509H38.7401V5.29688Z" fill="#EFD789" stroke="black" strokeMiterlimit="10" />
            <path d="M67.8084 22.2125L67.6491 22.088V21.5013H66.8988L48.079 6.78125L28.6682 21.5013H27.7752V22.1794L27.7188 22.2201H27.7752V75.3683H67.6491V22.2125H67.8084Z" fill="#EFD789" stroke="black" strokeMiterlimit="10" />
            <path d="M70.3385 75.3848H25.1562V77.5816H70.3385V75.3848Z" fill="#EAA0B2" stroke="black" strokeMiterlimit="10" />
            <path d="M54.9718 41.1523H41.0586V51.7962H54.9718V41.1523Z" fill="black" stroke="black" strokeMiterlimit="10" />
            <path d="M57.1919 51.7969H38.5547V53.2674H57.1919V51.7969Z" fill="#D6BA6B" stroke="black" strokeMiterlimit="10" />
            <path d="M47.3436 42.2637H43.0312V45.8904H47.3436V42.2637Z" fill="#61AFAF" />
            <path d="M52.9999 42.2637H48.6875V45.8904H52.9999V42.2637Z" fill="#61AFAF" />
            <path d="M52.9999 47.0605H48.6875V50.6872H52.9999V47.0605Z" fill="#61AFAF" />
            <path d="M47.3436 47.0605H43.0312V50.6872H47.3436V47.0605Z" fill="#61AFAF" />
            <path d="M54.9718 56.5918H41.0586V67.2357H54.9718V56.5918Z" fill="black" stroke="black" strokeMiterlimit="10" />
            <path d="M35.8085 10.4785H31.6289V11.6112H35.8085V10.4785Z" fill="#D6BA6B" />
            <path d="M57.1919 67.2363H38.5547V68.7068H57.1919V67.2363Z" fill="#D6BA6B" stroke="black" strokeMiterlimit="10" />
            <path d="M47.329 57.6211H42.9336V61.3189H47.329V57.6211Z" fill="#61AFAF" />
            <path d="M53.0946 57.6211H48.6992V61.3189H53.0946V57.6211Z" fill="#61AFAF" />
            <path d="M53.0946 62.5098H48.6992V66.2076H53.0946V62.5098Z" fill="#61AFAF" />
            <path d="M47.329 62.5098H42.9336V66.2076H47.329V62.5098Z" fill="#61AFAF" />
            <path d="M54.9718 25.7148H41.0586V36.3587H54.9718V25.7148Z" fill="black" stroke="black" strokeMiterlimit="10" />
            <path d="M57.1919 36.3574H38.5547V37.8279H57.1919V36.3574Z" fill="#D6BA6B" stroke="black" strokeMiterlimit="10" />
            <path d="M47.3009 26.7539H42.9453V30.4161H47.3009V26.7539Z" fill="#61AFAF" />
            <path d="M53.0157 26.7539H48.6602V30.4161H53.0157V26.7539Z" fill="#61AFAF" />
            <path d="M53.0157 31.5977H48.6602V35.2599H53.0157V31.5977Z" fill="#61AFAF" />
            <path d="M47.3009 31.5977H42.9453V35.2599H47.3009V31.5977Z" fill="#61AFAF" />
            <path d="M20.2539 22.2192L28.1384 16.2509L38.7451 8.13655L44.2958 3.89018L48.077 1L75.7806 22.2192L67.8064 22.2116L66.5615 21.2389L48.077 6.78035L38.7451 13.9194L35.0435 16.7537L27.7168 22.2192H20.2539Z" fill="#EAA0B2" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M40.7609 3.28906H26.1406V5.29797H40.7609V3.28906Z" fill="#EAA0B2" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M48.0177 22.2033C50.4782 22.2033 52.4728 20.6773 52.4728 18.795C52.4728 16.9127 50.4782 15.3867 48.0177 15.3867C45.5571 15.3867 43.5625 16.9127 43.5625 18.795C43.5625 20.6773 45.5571 22.2033 48.0177 22.2033Z" fill="#E094AA" stroke="black" strokeMiterlimit="10" />
            <path d="M48.0155 21.5025C49.9699 21.5025 51.5543 20.2904 51.5543 18.7952C51.5543 17.3 49.9699 16.0879 48.0155 16.0879C46.061 16.0879 44.4766 17.3 44.4766 18.7952C44.4766 20.2904 46.061 21.5025 48.0155 21.5025Z" fill="#49918F" stroke="black" strokeMiterlimit="10" />
            <path d="M33.1757 7.91992H28.9961V9.05263H33.1757V7.91992Z" fill="#D6BA6B" />
            <path d="M37.9101 6.23242H33.7305V7.36513H37.9101V6.23242Z" fill="#D6BA6B" />
            <path d="M25.101 58.434H10.7861C8.55185 58.434 6.72266 57.0346 6.72266 55.3254V42.0656C6.72266 40.3564 8.55185 38.957 10.7861 38.957H25.101C27.3352 38.957 29.1644 40.3564 29.1644 42.0656V55.3254C29.1611 57.0346 27.3352 58.434 25.101 58.434Z" fill="#B9DDB4" stroke="black" strokeMiterlimit="10" />
            <path d="M17.9414 46.7715V68.8008" stroke="black" strokeMiterlimit="10" />
            <path d="M12.8867 49.6895L17.9394 58.4336" stroke="black" strokeMiterlimit="10" />
            <path d="M22.5393 47.9199L17.9414 52.2933" stroke="black" strokeMiterlimit="10" />
            <path d="M60.6172 76.7644C60.6172 77.0412 60.6371 77.313 60.6803 77.5822H86.88C86.3588 75.2203 83.6731 73.4222 80.4363 73.4222C79.4271 73.4222 78.471 73.5974 77.6211 73.9098C76.2102 71.5682 73.1162 69.9453 69.5275 69.9453C64.6076 69.9453 60.6172 72.998 60.6172 76.7644Z" fill="#8CB876" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M54.6161 76.01C54.6062 76.5509 54.4568 77.0893 54.1779 77.582H7.33248C7.32252 77.5566 7.30924 77.5338 7.29928 77.5084C6.09088 74.6334 10.1576 71.9642 13.7098 73.1909C13.5206 70.6258 16.0502 68.0988 19.3335 67.568C22.6234 67.0321 26.2685 68.556 27.457 70.961C29.4953 70.4302 31.9254 70.9356 33.333 72.1852C33.7779 69.5972 36.9582 67.4563 40.3709 67.4512C43.7804 67.4461 46.974 69.582 47.4321 72.1649C49.8854 71.5553 52.8633 72.4163 54.065 74.1636C54.4568 74.735 54.6327 75.37 54.6161 76.01Z" fill="#8CB876" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1.53906 77.6348L89.9979 77.7135" stroke="black" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M30.6445 80H39.4021" stroke="#231F20" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.4453 78.9238H27.357" stroke="#231F20" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M62.625 79.5254H78.5367" stroke="#231F20" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M45.8477 80H55.9763" stroke="#231F20" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}



export const plans = [
    {
        id: 1,
        title: 'Free Trial',
        description: 'Free for 2 months for all tiers',
        price: 0,
        currency: 'USD',
        items: [
            `Free for 2 months after that can be upgraded to one of the tiers`,
            `All features will be available in admin  panel`
        ],
        icon: 'https://res.cloudinary.com/cornerqube/image/upload/v1699956337/resources/free.png'
    },
    {
        id: 2,
        title: 'One Person Company',
        description: '$250/year',
        price: 250,
        currency: 'USD',
        items: [
            `Best for Consultants`,
            `All features will be available in admin panel`,
            `Choose up to 3 services tags provided`,
            `Detailed analysis with other OPCs`,
            `Only Admin  access to Admin panel`,
            `Verified at additional cost`,
            `Morphline studio 2 free consulting sessions`
        ],
        icon: 'https://res.cloudinary.com/cornerqube/image/upload/v1699956337/resources/one_person.png'
    },
    {
        id: 3,
        title: 'Startup',
        description: 'Starts at $600/year',
        price: 600,
        currency: 'USD',
        items: [
            `Best for Micro and Small businesses`,
            `All features will be available in admin  panel`,
            `Choose up to 7 services tags provided`,
            `Detailed analysis with other businesses`,
            `Add up to 5 users to Admin panel`,
            `Detailed analysis with other businesses`,
            `Free Entropi for first year`,
            `50% off on feddup for first year`,
            `Morphline studio 2 free consulting sessions`
        ],
        icon: 'https://res.cloudinary.com/cornerqube/image/upload/v1699956337/resources/startup.png'
    },
    {
        id: 4,
        title: 'Enterprise',
        description: 'Starts at $1000/year',
        price: 1000,
        currency: 'USD',
        items: [
            `Best for Medium and Large businesses`,
            `All features will be available in admin panel`,
            `Choose up to 10 services tags provided`,
            `Detailed analysis with other businesses`,
            `Add up to 15 users to Admin panel`,
            `Detailed analysis with other businesses`,
            `Free Feddup for first year`,
            `50% off on Greyffiti for first year`,
            `Morphline studio 10 free consulting sessions`
        ],
        icon: 'https://res.cloudinary.com/cornerqube/image/upload/v1699956337/resources/enterprise.png'
    },
]