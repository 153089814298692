import React from 'react';

// Core viewer
import { Viewer } from '@react-pdf-viewer/core';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import { useDispatch, useSelector } from 'react-redux';
import { preview, selectPreview } from '../../slices/companySlice';


function PdfViewer() {
    const dispatch = useDispatch();
    const pdf = useSelector(selectPreview);

    return (
        <>
            {pdf.isOpen && <><div className='fixed top-0 left-0 w-full min-h-screen bg-black bg-opacity-60' style={{ zIndex: 159 }} onClick={() => dispatch(preview({ isOpen: false, pdfUrl: '', title: '' }))}></div>
                <div className='fixed bottom-0 px-5 py-2 w-full h-[98vh] overflow-hidden rounded-t-3xl bg-white transition-y' style={{ zIndex: 60 }}>
                    <div className='w-full flex items-center justify-between p-2 border-b'>
                        <div className='flex items-center gap-3'>
                            {pdf?.title ? <h1 className='text-2xl lg:text-3xl font-semibold text-gray-900'>{pdf?.title}</h1> : ''}
                            {
                                pdf?.projectInfo && <><button className='rounded-full bg-blue text-white py-1 px-5 font-medium'>{pdf?.projectInfo.buttonText}</button>{pdf?.projectInfo.buttonText == 'Public' && <a href={pdf.projectInfo?.link} target="_blank" rel="noreferrer" className='text-gray-800 flex items-center gap-1 hover:text-blue-500 font-medium'><span>Visit</span><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                                </svg></a>}</>
                            }
                        </div>
                        <span className='text-grey-800 cursor-pointer' onClick={() => dispatch(preview({ isOpen: false, pdfUrl: '', title: '' }))}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </span>
                    </div>
                    <div className='w-full lg:w-2/3 xl:1/2 h-full m-2 mx-auto'>
                        {pdf?.pdfUrl && <Viewer
                            fileUrl={pdf?.pdfUrl}
                        />}
                    </div>
                </div></>}
        </>
    )
}

export default PdfViewer