import { useEffect } from "react"
import { toast } from "react-hot-toast"
import { QueryClient, useMutation, useQuery, useQueryClient } from "react-query"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { acceptOrRejectRequestForClient, acceptOrRejectRequestForVendor, addClientByAdmin, addCompanyInformation, addOverAllReview, addUsersForAccessPortal, addVendorByAdmin, feedbackRequest, getAdminInfo, getCompanies, getCompanyDetails, getReviewDetails, getCompanyProjects, login, registerCompany, sendOtp, updateUsersForAccessPortal, updateProjectRequest, createProject, revokeRequest, deleteProject, addEnquiry, updateCompanyInformation, clearEnquiry, updateProfile, validateEmail, hideReview, handleRequestForClientAndVendor, createOrder } from "../services/service"
import { adminDetails } from "../slices/adminSlice"
import { companyList, projectList } from "../slices/companySlice"
import { setReviewData } from "../slices/reviewSlice"

export const useInitialLoad = () => {
    const dispatch = useDispatch();
    let { isLoading, data, isError, error, isFetching } = useQuery('adminInfo', getAdminInfo, { refetchOnWindowFocus: false, refetchInterval: 6000000, retry: false });
    let { data: companyRes } = useQuery('getCompanies', getCompanies, { refetchOnWindowFocus: false, refetchInterval: 600000, retry: false });
    let { data: projectRes, refetch: fetchProjects } = useQuery('getCompanyProjects', getCompanyProjects, { refetchOnWindowFocus: false, refetchInterval: 600000, enabled: false })

    useEffect(() => {
        if (data?.data) {
            dispatch(adminDetails(data.data));
            fetchProjects();
        }
    }, [data])

    useEffect(() => {
        if (companyRes?.data?.companies) {
            dispatch(companyList(companyRes.data.companies))
        }
    }, [companyRes?.data?.companies])

    useEffect(() => {
        if (projectRes?.data?.projects) {
            dispatch(companyList(projectRes.data.projects))
        }
    }, [projectRes?.data?.projects])

    return { isLoading, isError, error, isFetching }
}


export const useSendOtp = () => {
    const history = useHistory();

    return useMutation(sendOtp, {
        onSuccess: (response) => {
            if (response.data.status === true) {
                toast.success("OTP sent successfully!!", { id: 'success' })
                history.push(`/verifyotp`, response.data.data, { id: 'error' })
            } else {
                console.log(response.data.message)
                toast.error(response.data.message)
            }
        },
        onError: (e) => {
            toast.error("Oops! Unable to send OTP", { id: 'error' })
        }
    })
}

export const useLogin = (callback) => {
    return useMutation(login, {
        onSuccess: (response) => {
            const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
            if (response.data.status === true) {
                if (response.data?.expired) {
                    callback(response.data.expired)
                }
                else {

                    localStorage.setItem('corner_qube', response.data.accessToken)
                    if (response.data.user?.progress > 2) {
                        window.location.pathname = `/home/${response.data.user?.companyName}`
                    }
                    else {
                        window.location.pathname = '/welcome'
                    }
                }
            } else {
                toast.error(msg, { id: 'error' })
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Unable to login user", { id: 'error' })
        }
    })
}

export const useAddUsersForAccessPortal = (type, closePopUp) => {
    const queryClient = useQueryClient()
    return useMutation(type == 'add' ? addUsersForAccessPortal : updateUsersForAccessPortal, {
        onSuccess: (response) => {
            if (response.data.status === true) {
                toast.success(`User ${type == 'add' ? 'added' : 'updated'} successfully!!`, { id: 'success' });
                queryClient.invalidateQueries('user-list');
                try {
                    closePopUp();
                } catch (error) {
                    console.log('close popup')
                }
            }
        },
        onError: (e) => {
            toast.error("Oops! Unable to add user", { id: 'error' })
        }
    })
}

export const useRegisterCompany = (callback) => {
    return useMutation(registerCompany, {
        onSuccess: (response) => {
            const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
            if (response.data.status === true) {
                toast.success("User Registered successfully!!")
                if (callback)
                    callback();
            }
            else {
                toast.error(msg)
            }
        },
        onError: (e) => {
            toast.error("Oops! Unable to register user", { id: 'error' })
        }
    })
}

export const useAddCompanyInformation = (callback) => {
    return useMutation(addCompanyInformation, {
        onSuccess: (response) => {
            const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
            if (response.data.status === true) {
                callback(response);
            }
            else {
                toast.error(msg)
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}

export const useAddVendorAndClient = (type, callback) => {
    return useMutation(type == 'vendor' ? addVendorByAdmin : addClientByAdmin, {
        onSuccess: (response) => {
            const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
            if (response.data.status === true) {
                toast.success(`Add request sent for ${type}`)
                if (callback)
                    callback();
            }
            else {
                toast.error(msg)
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}


export const useUpdateRequest = (callback) => {

    const queryClient = useQueryClient();
    return useMutation(handleRequestForClientAndVendor, {
        onSuccess: (response) => {
            const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
            if (response.data.status === true) {
                // if (type == 'vendor') {
                //     queryClient.setQueryData('getAllVendorStatus', (oldData) => {
                //         let data = oldData
                //         let arr = [...oldData.data.vendorRequests]
                //         let i = arr.findIndex(item => item.id == response.data.docId)
                //         if (i > -1) {
                //             arr.splice(i, 1);
                //             data.data.vendorRequests = arr
                //         }
                //         return data
                //     })
                // }
                // else {
                //     queryClient.setQueryData('getAllClientStatus', (oldData) => {
                //         let data = oldData
                //         let arr = [...oldData.data.clientRequests]
                //         let i = arr.findIndex(item => item.id == response.data.docId)
                //         if (i > -1) {
                //             arr.splice(i, 1);
                //             data.data.clientRequests = arr
                //         }
                //         return data
                //     })
                // }
                if (callback)
                    callback();

                queryClient.invalidateQueries('getAllRequests')
                queryClient.invalidateQueries('getAllClientStatus')
                queryClient.invalidateQueries('getAllVendorStatus')
                toast.success(response.data.message);
            }
            else {
                toast.error(msg)
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}

export const useUpdateProjectRequest = () => {
    const queryClient = useQueryClient();
    return useMutation(updateProjectRequest, {
        onSuccess: (response) => {
            const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
            if (response.data.status === true) {
                queryClient.invalidateQueries('projectRequest')
                queryClient.invalidateQueries('getAssignedProjects')
                toast.success(response.data.message);
            }
            else {
                toast.error(msg)
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}

export const useFeedbackRequest = (callback) => {
    return useMutation(feedbackRequest, {
        onSuccess: (response) => {
            const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
            if (response.data.status === true) {
                toast.success(`Feedback request sent for ${response.data.type || ''}`)
                if (callback)
                    callback();
            }
            else {
                toast.error(msg)
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}

export const useOverAllReview = (callback) => {
    const queryClient = useQueryClient()
    return useMutation(addOverAllReview, {
        onSuccess: (response) => {
            const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
            if (response.data.status === true) {
                queryClient.invalidateQueries('feedbackRequestList')
                queryClient.invalidateQueries('getAllReviewsByCompany')
                queryClient.invalidateQueries('projectFeedbackRequest')
                toast.success(`Review added Successfully`)
                if (callback)
                    callback();
            }
            else {
                toast.error(msg)
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}

export const useAddProject = (callback) => {
    const queryClient = useQueryClient()
    return useMutation(createProject, {
        onSuccess: (response) => {
            const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
            if (response.data.status === true) {
                queryClient.invalidateQueries('getProjectsByAdmin')
                toast.success(msg)
                if (callback)
                    callback();
            }
            else {
                toast.error(msg)
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}


export const useGetCompanyDetails = (companyName) => {
    return useQuery('getCompanyDetails', () => getCompanyDetails(companyName), {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: 600000,
        onError: (e) => {
            console.log(e)
        }
    })
}

export const useGetReviewDetails = () => {
    const dispatch = useDispatch();
    return useMutation('getReviewDetails', (id) => getReviewDetails(id), {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: 600000,
        onError: (e) => {
            toast.error("Unable to get Review Details")
            console.log(e)
        },
        onSuccess: (response) => {
            const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1);
            if (response.data.status === true) {
                dispatch(setReviewData({ ...response.data, open: true }))
            } else {
                toast.error(msg)
            }
        },
        onMutate: () => toast('Loading Review', { id: 'review', duration: 600 })
    })
}

export const useRevokeRequest = (invalidate) => {
    const queryClient = useQueryClient()
    return useMutation(revokeRequest, {
        onSuccess: (response) => {
            const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
            if (response.data.status === true) {
                if (invalidate)
                    queryClient.invalidateQueries(invalidate)
                toast.success(msg)
            }
            else {
                toast.error(msg)
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}

export const useDeleteProject = (invalidate, callback) => {
    const queryClient = useQueryClient()
    return useMutation(deleteProject, {
        onSuccess: (response) => {
            const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
            if (response.data.status === true) {
                if (invalidate)
                    queryClient.invalidateQueries(invalidate)
                toast.success(msg)
                if (callback) {
                    callback()
                }
            }
            else {
                toast.error(msg)
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}
export const useAddEnquiry = (callback) => {
    return useMutation((data) => addEnquiry(data), {
        onSuccess: (response) => {
            const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
            if (response.data.status === true) {
                if (callback) {
                    callback()
                }
            }
            else {
                toast.error(msg)
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}
export const useClearEnquiry = (callback) => {
    const queryClient = useQueryClient()
    return useMutation((id) => clearEnquiry(id), {
        onSuccess: (response) => {
            const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
            if (response.data.status === true) {
                queryClient.invalidateQueries('getEnquiry');
                toast.success('Enquiry Cleared Successfully', { id: 'success' })
                if (callback) {
                    callback()
                }
            }
            else {
                toast.error(msg)
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}
export const useUpdateCompanyInformation = (callback) => {
    return useMutation((data) => updateCompanyInformation(data), {
        onSuccess: (response) => {
            const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
            if (response.data.status === true) {
                toast.success("Updated Successfully", { id: 'success' })
                if (callback) {
                    callback()
                }
            }
            else {
                toast.error(msg)
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}

export const useUpdateProfile = (callback) => {
    const queryClient = useQueryClient()
    return useMutation((data) => updateProfile(data), {
        onSuccess: (response) => {
            const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
            if (response.data.status === true) {
                toast.success("Updated Successfully", { id: 'success' });
                queryClient.invalidateQueries('adminInfo')
                if (callback) {
                    callback()
                }
            }
            else {
                toast.error(msg)
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}

export const useValidateEmail = (callback) => {
    return useMutation((data) => validateEmail(data), {
        onSuccess: (response) => {
            const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
            if (response.data.status === true) {
                if (callback) {
                    callback()
                }
            }
            else {
                toast.error(msg, { id: 'error' })
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}

export const useHideReview = (callback) => {
    const queryClient = useQueryClient()
    return useMutation((data) => hideReview(data), {
        onSuccess: (response) => {

            const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
            if (response.data.status === true) {
                toast.success(msg, { id: 'success' })
                queryClient.invalidateQueries('vendorFeedbacks')
                queryClient.invalidateQueries('clientFeedbacks')
                if (callback) {
                    callback(response?.data?.hide)
                }
            }
            else {
                toast.error(msg, { id: 'error' })
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}

export const useCreateOrder = (callback) => {
    return useMutation(createOrder, {
        onSuccess: (res) => {
            let response = res?.data
            if (response.success === true) {
                if (typeof callback == 'function') {
                    callback(response.data)
                }
            }
        },
        onError: (e) => {
            console.log(e)
            console.error("Oops! Unable to create order")
            toast("Oops! Unable to create order", { id: 'err' })
        }
    })
}