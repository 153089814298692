import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { setReviewData } from '../../slices/reviewSlice';
import RadarChart from '../Charts/RadarChart';
import fillRating from '../../images/reviewstar.svg';
import emptyRating from '../../images/rating_empty.svg';

let vendorArr = [
    { typeOfReview: 'Requirements', rating: 0 },
    { typeOfReview: 'Processes', rating: 0 },
    { typeOfReview: 'Financial', rating: 0 },
    { typeOfReview: 'Stake holder management', rating: 0 },
    { typeOfReview: 'Communication', rating: 0 },
    { typeOfReview: 'Quality check', rating: 0 }
]
let clientArr = [
    { typeOfReview: 'Delivery Mng.', rating: 0 },
    { typeOfReview: 'Contract Mng.', rating: 0 },
    { typeOfReview: 'Vendor Mng.', rating: 0 },
    { typeOfReview: 'Stakeholder Mng.', rating: 0 },
    { typeOfReview: 'Cybersec. Mng.', rating: 0 },
    { typeOfReview: 'Resource Mng.', rating: 0 }
]

function ReviewDetail({ reviewData }) {
    const reviewDetail = reviewData.getReviewDetails
    const dispatch = useDispatch()
    const [pageNo, setPageNo] = useState(1);

    const closeReviewDetail = () => {
        setPageNo(1);
        dispatch(setReviewData(null))
    }

    const nextPage = () => {
        let totalPages = 3;
        if (pageNo < totalPages) {
            setPageNo(pageNo + 1)
        }
        else {
            setPageNo(1)
        }
    }
    const previousPage = () => {
        if (pageNo > 1) {
            setPageNo(pageNo - 1)
        }
        else {
            setPageNo(3)
        }
    }

    return (
        <div className={`fixed top-0 left-0 right-0 w-full min-h-screen bg-black bg-opacity-40 flex items-center justify-center z-[103] p-4 overflow-hidden md:inset-0 ${reviewDetail ? '' : 'hidden'}`}>
            <div className="relative w-full max-w-6xl overflow-hidden">
                <div className="relative bg-white rounded-lg shadow h-[700px]">
                    <button type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" onClick={() => closeReviewDetail()}>
                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </button>

                    <div className="px-10 py-2 flex flex-col gap-6 w-full">

                        <div className='w-full h-20 relative max-w-6xl mx-auto mt-6'>
                            <div className='flex items-center justify-center'>
                                <div className='relative max-w-max mx-auto'>
                                    <div className='bg-white rounded border-2 border-bermuda py-2 px-8 max-w-max z-20 relative'>
                                        <span className='text-2xl font-bold'>Full Review</span>
                                    </div>
                                    <div className=' bg-lightblue rounded border-2 border-bermuda absolute inset-0 transform translate-x-1.5 translate-y-1.5 z-10'>
                                    </div>
                                    <div className=' bg-lightviolet rounded border-2 border-bermuda absolute inset-0 transform translate-x-3 translate-y-3 z-0'>
                                    </div>
                                </div>
                            </div>
                                <div className='flex-grow flex items-center gap-4 pt-8'>
                                    <div className='grid place-items-center'>
                                        <RadarChart width={450} draggable={false} heading={'Factor Rating'} initialData={reviewData.getGraphRating?.length ? reviewData?.getGraphRating : reviewDetail?.type == 1 ? clientArr : vendorArr} graphData={reviewData.getGraphRating?.length ? reviewData?.getGraphRating : reviewDetail?.type == 1 ? clientArr : vendorArr} backgroundColor={reviewDetail?.type == 1 ? 'rgba(239 ,185 ,255 ,0.6)' : 'rgba(184, 227, 236, 0.6)'} borderColor={reviewDetail?.type == 1 ? 'rgb(222, 22, 255)' : 'rgb(20 ,201 ,201)'} />
                                    </div>

                                    <div className='flex-grow border-2 border-bermuda rounded relative p-5 min-h-[26rem]'>
                                        <div className='flex items-center justify-end gap-6'>
                                            <div className='cursor-pointer' onClick={previousPage}>
                                                <svg width="14" height="23" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0.935443 10.3563C0.349656 10.9421 0.349656 11.8919 0.935443 12.4777L10.4814 22.0236C11.0672 22.6094 12.0169 22.6094 12.6027 22.0236C13.1885 21.4378 13.1885 20.4881 12.6027 19.9023L4.11742 11.417L12.6027 2.93171C13.1885 2.34592 13.1885 1.39618 12.6027 0.81039C12.0169 0.224604 11.0672 0.224604 10.4814 0.81039L0.935443 10.3563ZM3.125 9.91699H1.9961V12.917H3.125V9.91699Z" fill="black" />
                                                </svg>
                                            </div>
                                            <div className='cursor-pointer' onClick={nextPage}>
                                                <svg width="14" height="23" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M12.1349 12.3927C12.7207 11.8069 12.7207 10.8572 12.1349 10.2714L2.58894 0.72543C2.00315 0.139643 1.0534 0.139643 0.467617 0.72543C-0.11817 1.31122 -0.11817 2.26096 0.467617 2.84675L8.9529 11.332L0.467617 19.8173C-0.11817 20.4031 -0.11817 21.3528 0.467617 21.9386C1.0534 22.5244 2.00315 22.5244 2.58894 21.9386L12.1349 12.3927ZM10.0742 12.832H11.0742V9.83203H10.0742V12.832Z" fill="black" />
                                                </svg>
                                            </div>
                                        </div>
                                        {
                                            pageNo == 1 ?
                                                <div className="text-base text-semibold"><br />
                                                    <div className=' text-black font-medium text-base'> How were you related to the firm?</div>
                                                    <div className={`mt-3 text-black font-semibold text-base border-2 border-bermuda max-w-max py-1.5 px-5 rounded-lg ${reviewDetail?.type == 1 ? 'bg-lightviolet' : 'bg-signupBgColor'}`}>{reviewDetail?.type == 1 ? "Client" : "Vendor"}</div>
                                                    <div className='mt-5 text-black font-medium text-base'>How likely are you to work again with {reviewDetail?.companyName}?</div>
                                                    <p className='font-semibold'>{reviewDetail?.durationOfEngagement} month{"("}s{")"}</p>
                                                    <div className='mt-5 text-black font-medium text-base'>{reviewDetail?.type == 1 ? "What services did you utilize from this firm" : "what services did you offer"}</div>
                                                    <div className='mt-2 text-base font-semibold'>{reviewDetail?.type == 1 ?
                                                        <div>{reviewDetail?.offeringTags?.map((data, i) => (
                                                            <div className={`bg-lightviolet mr-4 mb-2 pt-2 inline-flex w-auto rounded-full justify-center border-b-4 border-gray-900 px-6 py-2 text-sm font-medium`}>{data?.industryServices}</div>
                                                        ))}
                                                        </div>
                                                        :
                                                        <div>{reviewDetail?.offeringTags?.map((data, i) => (
                                                            <div className={`bg-signupBgColor mr-4 mb-2 pt-2 inline-flex w-auto rounded-full justify-center border-b-4 border-gray-900 px-6 py-2 text-sm font-medium`}>{data?.industryServices}</div>
                                                        ))}
                                                        </div>}
                                                    </div>
                                                </div>
                                                :
                                                pageNo == 2
                                                    ?
                                                    <div className="text-base text-semibold">
                                                        <span className='font-medium'>Overall rating</span>
                                                        {reviewDetail?.rating == 1 ? <div className='flex flex-row justify-center items-center'><img src={fillRating} className="w-8 m-1" /><img src={emptyRating} className="w-8 m-1" /><img src={emptyRating} className="w-8 m-1" /><img src={emptyRating} className="w-8 m-1" /><img src={emptyRating} className="w-8 m-1" /></div>
                                                            : (reviewDetail?.rating == 2 ? <div className='flex flex-row justify-center items-center'><img src={fillRating} className="w-8 m-1" /><img src={fillRating} className="w-8 m-1" /><img src={emptyRating} className="w-8 m-1" /><img src={emptyRating} className="w-8 m-1" /><img src={emptyRating} className="w-8 m-1" /></div>
                                                                : (reviewDetail?.rating == 3 ? <div className='flex flex-row justify-center items-center'><img src={fillRating} className="w-8 m-1" /><img src={fillRating} className="w-8 m-1" /><img src={fillRating} className="w-8 m-1" /><img src={emptyRating} className="w-8 m-1" /><img src={emptyRating} className="w-8 m-1" /></div>
                                                                    : (reviewDetail?.rating == 4 ? <div className='flex flex-row justify-center items-center'><img src={fillRating} className="w-8 m-1" /><img src={fillRating} className="w-8 m-1" /><img src={fillRating} className="w-8 m-1" /><img src={fillRating} className="w-8 m-1" /><img src={emptyRating} className="w-8 m-1" /></div>
                                                                        : <div className='flex flex-row justify-center items-center'> <img src={fillRating} className="w-8 m-1" /><img src={fillRating} className="w-8 m-1" /><img src={fillRating} className="w-8 m-1" /><img src={fillRating} className="w-8 m-1" /><img src={fillRating} className="w-8 m-1" />
                                                                        </div>
                                                                    )))}

                                                        <div className='grid grid-cols-2 gap-4 mt-3'>
                                                            <div className='flex flex-col items-center justify-center gap-2 border-r-2 border-gray-800'>
                                                                <div className='text-black font-medium text-base'>Which factor impressed you the most?</div>
                                                                <div className={`text-black font-medium text-base py-2 px-5 rounded-xl border-b-4 border-black text-center ${reviewDetail?.type == 1 ? 'bg-lightviolet' : 'bg-signupBgColor'}`}>
                                                                    {reviewDetail?.positiveReviewComment}
                                                                </div>
                                                                <div className='mt-5 text-black font-medium text-base'>Which factor should the firm improve in?</div>

                                                                <div className={`text-black font-medium text-base py-2 px-5 rounded-xl border-b-4 border-black text-center ${reviewDetail?.type == 1 ? 'bg-lightviolet' : 'bg-signupBgColor'}`}>
                                                                    {reviewDetail?.improvementReviewComment}
                                                                </div>
                                                            </div>
                                                            <div className='flex flex-col items-center justify-center gap-2'>
                                                                <div className='text-black font-medium text-base'>{reviewDetail?.type == 1 ? 'Which service impressed you the most?' : 'Which service did client utilize the most?'}</div>
                                                                <div className={`text-black font-medium text-base py-2 px-5 rounded-xl border-b-4 border-black text-center ${reviewDetail?.type == 1 ? 'bg-lightviolet' : 'bg-signupBgColor'}`}>
                                                                    {reviewDetail?.serviceImpressed?.industryServices}
                                                                </div>
                                                                <div className='mt-5 text-black font-medium text-base'>{reviewDetail?.type == 1 ? 'Which service should the firm improve in?' : 'Which service did client utilize the least?'}</div>

                                                                <div className={`text-black font-medium text-base py-2 px-5 rounded-xl border-b-4 border-black text-center ${reviewDetail?.type == 1 ? 'bg-lightviolet' : 'bg-signupBgColor'}`}>
                                                                    {reviewDetail?.serviceImprove?.industryServices}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    :
                                                    <>
                                                        <div className="text-base text-semibold">
                                                            <div className='text-black font-medium text-base mt-2'>How likely is {reviewDetail?.companyName} to recommend this company to others?</div>
                                                            <div className='my-6 flex items-center gap-2'>
                                                                <div className='border-2 border-bermuda rounded-lg w-12 h-1.5 relative cursor-pointer bg-black'>
                                                                    <div className={`absolute left-1/2 transform -translate-x-1/2 ${reviewDetail?.recommendTheCompanyToOthers == 1 ? 'top-4' : reviewDetail?.recommendTheCompanyToOthers == 2 ? 'top-2' : reviewDetail?.recommendTheCompanyToOthers == 3 ? '-top-3' : reviewDetail?.recommendTheCompanyToOthers == 4 ? '-top-8' : '-top-10'}`}>
                                                                        <svg width="22" height="28" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path opacity="0.75" d="M1 26.0857L15.483 17.7227L29.89 26.0607L15.483 34.4457L1 26.0857Z" fill="#8ACEDA" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                            <path opacity="0.75" d="M15.483 1V17.72L1 26.083V9.36298L15.483 1Z" fill="#F8B6C7" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                            <path opacity="0.75" d="M29.8628 9.44727V26.1703L15.3828 34.4323V17.8073L29.8628 9.44727Z" fill="#F8B6C7" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                            <path opacity="0.78" d="M1 9.36328L15.483 17.7233V34.4432L1 26.0833V9.36328Z" fill="#C1E1B7" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                            <path opacity="0.75" d="M1 9.36298L15.483 17.723L29.966 9.36298L15.483 1L1 9.36298Z" fill="#F9EBA4" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                            <path opacity="0.75" d="M1 9.36298L15.483 17.723L29.966 9.36298L15.483 1L1 9.36298Z" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                            <path opacity="0.75" d="M29.8628 9.44727V26.1703L15.3828 34.4323V17.8073L29.8628 9.44727Z" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                                <span className='text-gray-600'>
                                                                    {reviewDetail?.typeOfRecommend}
                                                                </span>
                                                            </div>

                                                            <div className='mt-10 text-black font-medium text-base'>How Likely is {reviewDetail?.companyName} to work again with this firm?</div>
                                                            <div className='my-6 flex items-center gap-2'>
                                                                <div className='border-2 border-bermuda rounded-lg w-12 h-1.5 relative cursor-pointer bg-black'>
                                                                    <div className={`absolute left-1/2 transform -translate-x-1/2 ${true == 1 ? 'top-4' : reviewDetail?.workAgainWithCompany == 2 ? 'top-2' : reviewDetail?.workAgainWithCompany == 3 ? '-top-3' : reviewDetail?.workAgainWithCompany == 4 ? '-top-8' : '-top-10'}`}>
                                                                        <svg width="22" viewBox="0 0 31 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path opacity="0.75" d="M1 26.0857L15.483 17.7227L29.89 26.0607L15.483 34.4457L1 26.0857Z" fill="#8ACEDA" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                            <path opacity="0.75" d="M15.483 1V17.72L1 26.083V9.36298L15.483 1Z" fill="#F8B6C7" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                            <path opacity="0.75" d="M29.8628 9.44727V26.1703L15.3828 34.4323V17.8073L29.8628 9.44727Z" fill="#F8B6C7" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                            <path opacity="0.78" d="M1 9.36328L15.483 17.7233V34.4432L1 26.0833V9.36328Z" fill="#C1E1B7" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                            <path opacity="0.75" d="M1 9.36298L15.483 17.723L29.966 9.36298L15.483 1L1 9.36298Z" fill="#F9EBA4" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                            <path opacity="0.75" d="M1 9.36298L15.483 17.723L29.966 9.36298L15.483 1L1 9.36298Z" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                            <path opacity="0.75" d="M29.8628 9.44727V26.1703L15.3828 34.4323V17.8073L29.8628 9.44727Z" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                                <span className='text-gray-600'>
                                                                    {reviewDetail?.workAgainWithCompany}
                                                                </span>
                                                            </div>
                                                            <div className='mt-10 text-black font-semibold text-base'>Additional thoughts</div>
                                                            <div className='mt-1 text-black text-xs xl:text-sm'>{reviewDetail?.reviewComment}</div>
                                                        </div>
                                                    </>
                                        }

                                    </div>
                                </div>
                                <div className='flex flex-row gap-6 justify-center py-4 items-center'>
                                    <div className=" z-[100] relative rounded bg-bermuda h-10">
                                        <div className="z-50 bg-bermuda rounded">
                                            <button className={`border-2 z-[60] py-2 px-8 bg-pinkshade transform -translate-x-1 -translate-y-1 border-bermuda rounded`}
                                                onClick={() => closeReviewDetail()}>
                                                <span className='font-medium'>
                                                    Close
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                <div className='absolute -bottom-48 -left-48'>
                    <svg width="500" height="400" viewBox="0 0 663 549" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle opacity="0.23" cx="312" cy="323" r="186" fill="#BFEBF2" />
                        <circle opacity="0.3" cx="477" cy="363" r="186" fill="#BFEBF2" />
                        <circle opacity="0.23" cx="186" cy="186" r="186" fill="#BFEBF2" />
                    </svg>
                </div>

                <div className='absolute -bottom-48 -right-48'>
                    <svg width="500" height="400" viewBox="0 0 805 673" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle opacity="0.23" cx="434.817" cy="377.366" r="186" transform="rotate(-60.4709 434.817 377.366)" fill="#E6BCFA" />
                        <circle opacity="0.23" cx="253.513" cy="419.476" r="186" transform="rotate(-60.4709 253.513 419.476)" fill="#E6BCFA" />
                        <circle opacity="0.3" cx="550.946" cy="253.512" r="186" transform="rotate(-60.4709 550.946 253.512)" fill="#E6BCFA" />
                    </svg>
                </div>
            </div>
        </div>
    )
}

export default ReviewDetail