import React, { useState } from 'react';
import logo from '../../images/corner-cube-logo.svg';
import deleteicon from '../../images/deleteicon.svg';
import axios from 'axios';
import { config } from '../../services/config';
import Circles from '../Reusable/Circles';
import { useSelector } from 'react-redux';
import { selectAdminDetails } from '../../slices/adminSlice';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { isValidEmail, openLink } from '../../services/constant';
import { useAddUsersForAccessPortal } from '../../hooks';
import { useQuery } from 'react-query';
import { getOrganizationAndAccessRoleData } from '../../services/service';


export default function AddUsers() {
    const adminInfo = useSelector(selectAdminDetails);
    const history = useHistory()
    const [formValues, setFormValues] = useState([{ name: "", email: "", roleInOrganization: "", accessRight: "" }])
    const [accessRightArr, setAccessRightArr] = useState([])
    const [roleInOrganizationArr, setRoleInOrganizationArr] = useState([]);
    const [success, setSuccess] = useState(false)

    useQuery('getOrganizationAndAccessRoleData', getOrganizationAndAccessRoleData, {
        refetchOnMount: false, refetchOnWindowFocus: false,
        onSuccess: (res) => {
            setAccessRightArr(res.data.accessRight)
            setRoleInOrganizationArr(res.data.roleInOrganization)
        },
        onError: (e) => console.log(e)
    })
    const [popUp, setPopUp] = useState(false)

    const handleChange = (e, i) => {
        e.preventDefault();
        let arr = [...formValues]
        let id = e.target.id
        arr[i][id] = e.target.value
        setFormValues(arr)
    }

    const addFormFields = () => {
        setFormValues([...formValues, { name: "", email: "", roleInOrganization: "", accessRight: "" }])
    }
    const removeData = (i) => {
        setFormValues([{ name: "", email: "", roleInOrganization: "", accessRight: "" }])
    }
    const removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
    }

    const validateEmail = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "web"
        }

        var payload = {
            "email": formValues.map(item => item.email.toLowerCase())
        }
        axios.post(config.adminUrl + '/validateEmailByAdmin', payload, { headers: headers })
            .then(function (response) {
                const msg = response.data.message.charAt(0).toUpperCase() + response.data.message.slice(1)
                if (response.data.status === true) {
                    console.log("Added successfully!!")
                    // setRedirect(true)
                }
                else {
                    console.log(msg)
                    document.getElementById('status').innerText = msg
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    let { isLoading, mutate } = useAddUsersForAccessPortal('add', () => setSuccess(true))

    const addUsersData = () => {
        for (let data of formValues) {
            if (!data.name) {
                return toast.error(`Name is required`, { id: 'error' });
            }
            if (!isValidEmail(data.email)) {
                return toast.error(`Email format is incorrect for user "${data.name}"`, { id: 'error' });
            }
            if (!data.roleInOrganization) {
                return toast.error(`role is required for user "${data.name}"`, { id: 'error' });
            }
            if (!data.accessRight) {
                return toast.error(`provide an access right to user "${data.name}"`, { id: 'error' });
            }
        }
        var addUsersDataArr = []
        for (var i = 0; i < formValues.length; i++) {
            addUsersDataArr[i] = ({
                name: formValues[i].name,
                email: formValues[i].email,
                roleInOrganization: parseInt(formValues[i].roleInOrganization),
                accessRight: parseInt(formValues[i].accessRight),
                companyId: adminInfo?.companyId,
                adminId: adminInfo?.uuid
            })
        }

        mutate({ addUsersDataArr })
    }

    return (
        <>
            <section className='z-10 relative'>
                <Link to="/"><img src={logo} className="absolute top-3 left-6" style={{ width: "120px" }} /></Link>
                <center style={{ color: "#0091A8" }} className="pt-7 text-4xl font-semibold">Add Users Who Can Access Portal</center>
                <div className='-mt-6 float-right mr-16 px-2'>
                    <div onClick={() => setPopUp(true)} className="z-40 rounded-sm w-full h-10 text-black-500 bg-bermuda border-bermuda border-2 ">
                        <button className={` rounded-sm w-full h-10 border-2 font-bold bg-babyblue border-bermuda px-4 transform -translate-x-1.5 -translate-y-2`}
                        >&nbsp;Access Rights&nbsp;</button>
                    </div>
                </div>
                <div className='mt-8 px-40 font-semibold text-xl'>Admin</div>
                <div className='px-36 mt-4 grid grid-cols-3 grid-gap-2'>
                    <div className='px-4'>
                        <div className=" rounded w-full h-12 border-2 bg-lightgreen border-bermuda">
                            <input type="text" aria-describedby="text"
                                name="name"
                                disabled={true}
                                className="z-[60] bg-gray-200 rounded w-full h-12 text-black-500 border-black border-2 px-4 transform -translate-x-1.5 -translate-y-2.5"
                                value={adminInfo?.companyAdminName}
                            />
                        </div>

                        <div className="mt-8  rounded w-full h-12 border-2 bg-lightgreen border-bermuda">
                            <input type="text" aria-describedby="text"
                                name="role"
                                disabled={true}
                                className="z-[60] bg-gray-200 rounded w-full h-12 text-black-500 border-black border-2 px-4 transform -translate-x-1.5 -translate-y-2.5"
                                value={adminInfo?.companyRole}
                            />
                        </div>
                    </div>
                    <div className='px-4'>
                        <div className=" rounded w-full h-12 border-2 bg-lightgreen border-bermuda">
                            <input type="email" aria-describedby="text"
                                name="email"
                                disabled={true}
                                className="z-[60] bg-gray-200 rounded w-full h-12 text-black-500 border-black border-2 px-4 transform -translate-x-1.5 -translate-y-2.5"
                                value={adminInfo?.companyAdminEmail}
                            />
                        </div>
                    </div>
                    <div className='px-4'>
                        <div className=" rounded w-full h-12 border-2 bg-lightgreen border-bermuda">
                            <input type="text" aria-describedby="text"
                                name="type"
                                disabled={true}
                                className="z-[60] bg-gray-200 rounded w-full h-12 text-black-500 border-black border-2 px-4 transform -translate-x-1.5 -translate-y-2.5"
                                value={adminInfo?.userRole}
                            />
                        </div>
                    </div>
                </div>
                <div className='mt-5 ml-40 text-lg font-semibold'>Other Users {formValues.length}/3</div>
                {formValues.map((element, index) => (
                    <div className='mt-7'>
                        <div className='px-36 mt-4 grid grid-cols-3 grid-gap-2'>
                            <div className='px-4'>
                                <div className="z-50 rounded w-full h-12 border-2 bg-lightgreen border-bermuda">
                                    <input type="text" aria-describedby="text"
                                        id="name" value={element.name || ""} onChange={e => handleChange(e, index)}
                                        className="z-[60] rounded w-full h-12 text-black-500 border-black border-2 px-4 transform -translate-x-1.5 -translate-y-2.5"
                                        placeholder="Name"
                                    />
                                </div>


                                <div className="mt-8 z-50 rounded w-full h-12 border-2 bg-lightgreen border-bermuda">
                                    <select id="accessRight" value={element.accessRight || ""} className="z-[60] rounded w-full h-12 text-black-500 border-black border-2 px-4 transform -translate-x-1.5 -translate-y-2.5"
                                        onChange={e => handleChange(e, index)}>
                                        <option value="" selected disabled>Access Right</option>
                                        {accessRightArr.map((data, i) => (<option value={data.id}>{data.role}</option>))}
                                    </select>
                                </div>
                            </div>
                            <div className='px-4'>

                                <div className="z-50 rounded w-full h-12 border-2 bg-lightgreen border-bermuda">
                                    <input type="email" aria-describedby="text"
                                        id="email" value={element.email || ""}
                                        onBlur={validateEmail}
                                        onChange={e => handleChange(e, index)}
                                        className="z-[60] rounded w-full h-12 text-black-500 border-black border-2 px-4 transform -translate-x-1.5 -translate-y-2.5"
                                        placeholder="Email Id"
                                    />
                                </div>

                                <div className='flex'>
                                    {formValues.length < 3 && <div onClick={() => addFormFields()} className="mt-10 z-40 rounded max-w-max text-black-500 bg-bermuda">
                                        <button className={`z-50 rounded font-semibold bg-lightpink px-10 py-2 transform -translate-x-1 -translate-y-1`}
                                        >+ Add user</button>
                                    </div>}

                                    {index ?
                                        <div className='ml-4 mt-10 text-3xl cursor-pointer' onClick={() => removeFormFields(index)}><img src={deleteicon} className="w-8" /></div>
                                        : <div className='ml-4 mt-10 cursor-pointer' onClick={() => removeData(index)}><img src={deleteicon} className="w-8" /></div>
                                    }
                                </div>
                            </div>

                            <div className='px-4'>
                                <div className="z-50 rounded w-full h-12 border-2 bg-lightgreen border-bermuda">
                                    <select id="roleInOrganization" value={element.roleInOrganization || ""} className="z-[60] rounded w-full h-12 text-black-500 border-black border-2 px-4 transform -translate-x-1.5 -translate-y-2.5"
                                        onChange={e => handleChange(e, index)}>
                                        <option value="" selected disabled>Role in Organization</option>
                                        {roleInOrganizationArr.map((data, i) => (<option value={data.id}>{data.companyRole}</option>))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                <div className='flex items-center justify-end gap-6 p-10 pr-20'>
                    <div className="rounded max-w-max text-black-500 bg-bermuda">
                        <button className={`z-50 rounded w-full py-2 text-lg font-semibold bg-lightpink px-6 transform -translate-x-1 -translate-y-1`}
                            onClick={() => history.push(`/dashboard/${adminInfo?.companyName}`)}>Skip for now</button>
                    </div>
                    <div className="rounded max-w-max text-black-500 bg-bermuda ">
                        <button onClick={addUsersData} disabled={isLoading} className={`z-50 rounded w-full py-2 text-lg font-semibold bg-lightgreen px-6 transform -translate-x-1 -translate-y-1`}
                        >Confirm</button>
                    </div>
                </div>
            </section>

            <div className={`fixed top-0 left-0 right-0 z-50 p-4 grid place-items-center bg-black bg-opacity-50 overflow-x-hidden overflow-y-auto md:inset-0 ${popUp ? '' : 'hidden'}`}>
                <div className="relative max-w-5xl">
                    <div className="relative bg-white rounded-lg shadow" >
                        <button onClick={() => setPopUp(false)} type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center">
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                        </button>
                        <div className="px-6 py-2 items-center">
                            <center style={{ color: "#0091A8" }} className="mt-2 text-4xl font-semibold">Access Rights</center>
                            <div className='mt-2 ml-10 text-lg font-semibold'>Admin </div>
                            <div className='ml-10 text-base font-medium'>Only 1 user can be assigned assigned as admin. User with admin access and edit company information, provide feedbacks and add cleits and vendors</div>
                            <div className='mt-3 ml-10 text-lg font-semibold'>Sub Admin </div>
                            <div className='ml-10 text-base font-medium'>Upto 3 users can be added as subadmin. User with admin access and edit company information, provide feedbacks and add cleits and vendors</div>
                            <div className='mt-3 ml-10 text-lg font-semibold'>Read Only </div>
                            <div className='ml-10 text-base font-medium'>User with this access right will be able to only view data presented on selected section in the admin panel</div>
                            <div className='mt-3 ml-10 text-lg font-semibold'>Read and Write </div>
                            <div className='ml-10 text-base font-medium'>User with this access right will be able to view and provide feedback to other companies add clients and vendors to lists</div>

                            <center className='mt-6'>
                                <div className="mt-10 z-40 rounded-sm w-32 h-10 text-black-500 bg-bermuda border-bermuda border-2 ">
                                    <button onClick={() => setPopUp(false)} className={`z-50 rounded-sm w-full h-10 border-2 font-bold bg-lightpink border-bermuda px-4 transform -translate-x-1.5 -translate-y-2`}
                                    >&nbsp;Close&nbsp;</button>
                                </div>
                            </center><br />
                        </div>
                    </div>
                </div>
            </div>
            <Circles />

            {success && <div className='fixed inset-0 w-full min-h-screen overflow-hidden bg-transparent grid place-items-center'>
                <div onClick={() => openLink(`https://admin.cornerqube.com/dashboard/${adminInfo?.companyName}`)} className='fixed inset-0 w-full h-full bg-black bg-opacity-50 cursor-pointer'>
                </div>

                <div className='relative bg-white overflow-hidden rounded-lg p-10 max-w-4xl flex flex-col items-center justify-center'>
                    <img src="https://res.cloudinary.com/greyffiti/image/upload/v1684325809/SquareEarth.svg" className=" max-w-xl" alt='' />
                    <p className='text-lg text-green-600 z-10 text-center mb-10'>We have sent an email to the user(s) added with an temporary password, this can be changed when they login for the first time.</p>
                    <button onClick={() => openLink(`https://admin.cornerqube.com/dashboard/${adminInfo?.companyName}`)} disabled={isLoading} type='submit' className='bg-lightgreen text-green-600 rounded-lg shadow-xl font-medium text-lg py-2 px-6 z-10'>
                        Continue
                    </button>

                    <div className='absolute -bottom-48 -left-48'>
                        <svg width="500" height="400" viewBox="0 0 663 549" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle opacity="0.23" cx="312" cy="323" r="186" fill="#FBE9AF" />
                            <circle opacity="0.3" cx="477" cy="363" r="186" fill="#FBE9AF" />
                            <circle opacity="0.23" cx="186" cy="186" r="186" fill="#FBE9AF" />
                        </svg>
                    </div>

                    <div className='absolute -bottom-48 -right-48'>
                        <svg width="500" height="400" viewBox="0 0 805 673" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle opacity="0.23" cx="434.817" cy="377.366" r="186" transform="rotate(-60.4709 434.817 377.366)" fill="#BDF0BC" />
                            <circle opacity="0.23" cx="253.513" cy="419.476" r="186" transform="rotate(-60.4709 253.513 419.476)" fill="#BDF0BC" />
                            <circle opacity="0.3" cx="550.946" cy="253.512" r="186" transform="rotate(-60.4709 550.946 253.512)" fill="#BDF0BC" />
                        </svg>
                    </div>
                </div>
            </div>}
        </>
    );
}










