import React, { useEffect, useState } from 'react';
import logo from '../../images/corner-cube-logo.svg';
import { Link, useHistory } from 'react-router-dom';
import uploadicon from '../../images/uploadicon.svg';
import rightarrow from '../../images/rightarrow.svg';
import { FileUploader } from "react-drag-drop-files";
import useDebounce, { fetchPlace, uploadImage } from '../../services/constant';
import Circles from '../Reusable/Circles';
import { toast } from 'react-hot-toast';
import { useRegisterCompany, useSendOtp, useValidateEmail } from '../../hooks';
import { useQuery } from 'react-query';
import { getSignUpRefData } from '../../services/service';

const validEmailRegex =
    /^[a-z0-9+_.-]+@[a-z0-9.-]+$/
const validPasswordRegex =
    /^.*(?=.{10,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&!^*]).*$/
const validPhoneNoRegex = RegExp(
    /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[789]\d{9}$/i
);

const fileTypes = ["PDF"];

const hwyl = [
    {
        id: 1,
        title: 'To offer services',
    },
    {
        id: 2,
        title: 'To utilize services from firms',
    },
    {
        id: 3,
        title: 'Both'
    }
]
export default function SignUp() {

    const history = useHistory()

    const [companyNameError, setCompanyNameError] = useState(false)
    const [typeOfFirm, setTypeOfFirm] = useState("")
    const [registrationCertificate, setRegistrationCertificate] = useState(null)
    const [taxReference, setTaxReference] = useState(null)
    const [gstCertificate, setGstCertificate] = useState(null)
    const [boardOfDirectors, setBoardOfDirectors] = useState(null)
    const [companyAdminName, setCompanyAdminName] = useState("")
    const [idProof, setIdProof] = useState("")
    const [proofOfEmployment, setProofOfEmployment] = useState("")
    const [companyEmail, setCompanyEmail] = useState("")
    const [companyPhone, setCompanyPhone] = useState("")
    const [companyAddress, setCompanyAddress] = useState("")
    const [zipCode, setZipCode] = useState("")
    const [gender, setGender] = useState("")
    const [companyAdminPhone, setCompanyAdminPhone] = useState("")

    const [showCompanyInfo, setShowCompanyInfo] = useState(false)
    const [companyAdminNameError, setCompanyAdminNameError] = useState("")
    const [emailError, setEmailError] = useState("")
    const [genderError, setGenderError] = useState("")
    const [companyAdminPhoneError, setCompanyAdminPhoneError] = useState("")
    const [companyEmailError, setCompanyEmailError] = useState("")
    const [zipCodeError, setZipCodeError] = useState("")
    const [companyAddressError, setCompanyAddressError] = useState("")
    const [typeOfIndustryError, setTypeOfIndustryError] = useState("")
    const [companyPhoneError, setCompanyPhoneError] = useState("")
    const [companyDocumentsArr, setCompanyDocumentsArr] = useState([])
    const [companyRoleArr, setCompanyRoleArr] = useState([])
    const [minProjectCostArr, setMinProjectCostArr] = useState([])
    const [noOfEmployeesArr, setNoOfEmployeesArr] = useState([])
    const [typeOfFirmArr, setTypeOfFirmArr] = useState([])
    const [industrySectorArr, setIndustrySectorArr] = useState([])
    const [industryTypeArr, setIndustryTypeArr] = useState([])
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('')
    const [location, setLocation] = useState("");
    const [locationModal, setLocationModal] = useState(false);
    const [autocompleteCities, setAutocompleteCities] = useState([]);

    useQuery('getSignUpRefData', getSignUpRefData, {
        refetchOnMount: false, refetchOnWindowFocus: false, onSuccess: (response) => {
            setCompanyDocumentsArr(response.data.companyDocument)
            setCompanyRoleArr(response.data.companyRole)
            setMinProjectCostArr(response.data.minimumProjectCost)
            setNoOfEmployeesArr(response.data.numberOfEmployee)
            setTypeOfFirmArr(response.data.typeOfFirm)
            setIndustrySectorArr(response.data.industrySector)
            setIndustryTypeArr(response.data.industryType)
        },
        onError: (e) => console.log(e)
    })
    const [form, setForm] = useState(0)
    const [formData, setFormData] = useState({ email: '' })

    const [successModal, setSuccessModal] = useState(false)

    const handleRegistrationCerticate = (file) => {
        setRegistrationCertificate(file);
    };
    const handleTaxReference = (file) => {
        setTaxReference(file);
    };
    const handleGstCertificate = (file) => {
        setGstCertificate(file);
    };
    const handleBoardOfDirectors = (file) => {
        setBoardOfDirectors(file);
    };
    const handleIdProof = (file) => {
        setIdProof(file);
    };
    const handleProofOfEmployment = (file) => {
        setProofOfEmployment(file);
    };

    const handleBlur = (event) => {
        const { name } = event.target;
        validateField(name);
        return;
    }
    const handleCompanyBlur = (event) => {
        const { name } = event.target;
        validateCompanyField(name);
        return;
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        postDetails()
    }

    const addCompanyInfo = (e) => {
        e.preventDefault();
        let formFileds = [
            "companyAdminName",
            "email",
            "gender",
            "companyAdminPhone",
            "confirmPassword"

        ];
        let isValid = true;
        formFileds.forEach(field => {
            isValid = validateField(field) && isValid;
        });
        if (!isValid) {
            console.log("Please enter the details")
            setShowCompanyInfo(false)
        } else {
            setShowCompanyInfo(true)
        }
    }
    const validateField = (name) => {
        let isValid = false;
        if (name === "companyAdminName") isValid = validateCompanyAdminName();
        else if (name === "email") isValid = validateEmailAddress();
        else if (name === "gender") isValid = validateGender();
        else if (name === "companyAdminPhone") isValid = validateCompanyAdminPhone();
        return isValid;
    }
    const validateCompanyAdminName = () => {
        let companyAdminNameError = "";
        const value = companyAdminName;
        if (value.trim() === "") companyAdminNameError = "Admin Name must be at least 3 characters long!";
        else if (value.length < 3)
            companyAdminNameError = 'Admin Name must be at least 3 characters long!';
        setCompanyAdminNameError(companyAdminNameError)
        return companyAdminNameError === "";
    }
    const validateEmailAddress = () => {
        let emailError = "";
        const value = formData.email;
        if (value.trim === "") emailError = "Email is required";
        else if (!validEmailRegex.test(value))
            emailError = "Please Enter Email";
        setEmailError(emailError)
        return emailError === "";
    }
    const validateCompanyAdminPhone = () => {
        let companyAdminPhoneError = "";
        const value = companyAdminPhone;
        if (value.trim === "") companyAdminPhoneError = "Please enter valid phone number";
        else if (!validPhoneNoRegex.test(value))
            companyAdminPhoneError = "Please enter valid phone number";
        setCompanyAdminPhoneError(companyAdminPhoneError)
        return companyAdminPhoneError === "";
    }
    const validateGender = () => {
        let genderError = "";
        const value = gender;
        if (value.trim() === "") genderError = "Please select gender";
        else if (value.length < 1)
            genderError = 'Please select gender';
        setGenderError(genderError)
        return genderError === "";
    }

    const validateCompanyField = (name) => {
        let isValid = false;
        if (name === "companyName") isValid = validateCompanyName();
        else if (name === "companyEmail") isValid = validateCompanyEmail();
        else if (name === "companyAddress") isValid = validateCompanyAddress();
        else if (name === "typeOfIndustry") isValid = validateCompanyTypeOfIndustry();
        else if (name === "companyPhone") isValid = validateCompanyPhone();
        else if (name === "validateCompanyZipCode") isValid = validateCompanyZipCode();
        return isValid;
    }
    const validateCompanyName = () => {
        let companyNameError = "";
        const value = formData.companyName;
        if (value.trim() === "") companyNameError = "Company Name must be at least 3 characters long!";
        else if (value.length < 3)
            companyNameError = 'Company Name must be at least 3 characters long!';
        setCompanyNameError(companyNameError)
        return companyNameError === "";
    }
    const validateCompanyEmail = () => {
        let companyEmailError = "";
        const value = companyEmail;
        if (value.trim === "") companyEmailError = "Email is required";
        else if (!validEmailRegex.test(value))
            companyEmailError = "Please Enter Email";
        setCompanyEmailError(companyEmailError)
        return companyEmailError === "";
    }
    const validateCompanyAddress = () => {
        let companyAddressError = "";
        const value = companyAddress;
        if (value.trim() === "") companyAddressError = "Company Address must be at least 5 characters long!";
        else if (value.length < 6)
            companyAddressError = 'Company Address must be at least 5 characters long!';
        setCompanyAddressError(companyAddressError)
        return companyAddressError === "";
    }
    const validateCompanyPhone = () => {
        let companyPhoneError = "";
        const value = companyPhone;
        if (value.trim === "") companyPhoneError = "Please enter valid phone number";
        else if (!validPhoneNoRegex.test(value))
            companyPhoneError = "Please enter valid phone number";
        setCompanyPhoneError(companyPhoneError)
        return companyPhoneError === "";
    }
    const validateCompanyZipCode = () => {
        let zipCodeError = "";
        const value = zipCode;
        if (value.trim() === "") zipCodeError = "Please add zip code";
        else if (value.length < 6)
            zipCodeError = 'Please select Zip code';
        setZipCodeError(zipCodeError)
        return zipCodeError === "";
    }
    const validateCompanyTypeOfIndustry = () => {
        let typeOfIndustryError = "";
        const value = formData.typeOfIndustry;
        if (value.trim() === "") typeOfIndustryError = "Please select type of industry'";
        else if (value.length < 1)
            typeOfIndustryError = 'Please select type of industry';
        setTypeOfIndustryError(typeOfIndustryError)
        return typeOfIndustryError === "";
    }

    const { mutate: registerCompany, isLoading: adding } = useRegisterCompany(() => { setSuccessModal(true) })

    const postDetails = async (e) => {
        setLoading(true)
        let toastId = toast.loading("Finishing Signup ...")

        let { companyEstablishmentYear, companyName, employeeCount, headquarters, howWouldYouLike, minProjectCost, sector, typeOfFirm, typeOfIndustry, websiteLink, email, companyAdminName, companyRoleId } = formData
        let documentData = []
        if (registrationCertificate) {
            documentData.push({ documentType: 'registration certificate', documentData: await uploadImage(registrationCertificate) })
        }
        if (taxReference) {
            documentData.push({ documentType: 'tax reference', documentData: await uploadImage(taxReference) })
        }
        if (gstCertificate) {
            documentData.push({ documentType: 'gst certificate', documentData: await uploadImage(gstCertificate) })
        }
        if (boardOfDirectors) {
            documentData.push({ documentType: 'board of directors', documentData: await uploadImage(boardOfDirectors) })
        }
        let locationArr = location?.split(",")
        var payload = {
            "howWouldYouLike": howWouldYouLike,
            "typeOfFirm": typeOfFirm,
            "companyName": companyName,
            "typeOfIndustry": typeOfIndustry,
            "industrySector": sector,
            "companyEmployeeScale": employeeCount,
            "companyYearOfEstablishment": companyEstablishmentYear,
            "headQuartered": location,
            "companyCountry": locationArr.length ? locationArr[locationArr.length - 1].trim() : '',
            "minimumProjectCost": minProjectCost,
            "companyWebsite": websiteLink,
            "companyAdminName": companyAdminName,
            "documentData": documentData,
            "email": email.toLowerCase(),
            "companyRoleId": companyRoleId,
            "idProof": await uploadImage(idProof),
            "proofOfEmployment": await uploadImage(proofOfEmployment)
        }
        toast.dismiss(toastId)
        setLoading(false)

        registerCompany(payload)
    }

    const handleChange = (e) => {
        e.preventDefault();
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        if (formData?.typeOfFirm) {
            if (formData?.typeOfFirm == 1) {
                setFormData({ ...formData, employeeCount: 1, typeOfIndustry: 0 })
            }
            else if (formData?.typeOfFirm == 2 && formData?.industryType == 0) {
                setFormData({ ...formData, employeeCount: 1, typeOfIndustry: '' })
            }
            else if (formData?.typeOfFirm == 2) {
                setFormData({ ...formData, employeeCount: 1 })
            }
            // else {
            //     setFormData({ ...formData, employeeCount: '', typeOfIndustry: '' })
            // }
        }
    }, [formData?.typeOfFirm, formData?.typeOfIndustry])

    let { mutate: validateEmail, data: emailRes } = useValidateEmail()

    const debounced = useDebounce(formData.email, 400)
    useEffect(() => {
        if (debounced?.includes('@'))
            validateEmail({ email: debounced })
    }, [debounced])

    const validateData = () => {
        if (form == 0) {
            let { companyEstablishmentYear, companyName, employeeCount, howWouldYouLike, minProjectCost, sector, typeOfFirm, typeOfIndustry, websiteLink } = formData

            if (companyEstablishmentYear?.toString()?.length !== 4 || companyEstablishmentYear < 1600 || companyEstablishmentYear > Number(new Date().getFullYear()) + 5) {
                return toast.error('Invalid company establishment year', { id: 'error' })
            }
            console.log(companyEstablishmentYear?.toString()?.length == 4, companyName, employeeCount, location, howWouldYouLike, minProjectCost, sector, typeOfFirm, Number(typeOfIndustry) > -1)
            if (companyEstablishmentYear?.toString()?.length == 4 && companyName && employeeCount && location && howWouldYouLike && minProjectCost && sector && typeOfFirm && Number(typeOfIndustry) > -1) {
            } else return toast.error('Please fill all the fields', { id: 'error' })
        }
        else if (form == 1) {
            // if (registrationCertificate && taxReference) {
            //     console.log()
            //     // console.log(registrationCertificate, taxReference, gstCertificate, boardOfDirectors)
            // }
            // else return toast.error('Please fill required field', { id: 'error' })
        }
        else if (form == 2) {
            if (!emailRes?.data?.status) {
                return toast.error(emailRes?.data?.message || 'Please enter a valid email', { id: 'error' })
            }
            let { email, companyAdminName, companyRoleId, } = formData
            if (!email.includes('@')) {
                return toast.error('Please enter a valid email', { id: 'error' })
            }

            if (email && companyAdminName && companyRoleId) {
                console.log()
                // console.log(email, companyAdminName, companyRoleId, idProof, proofOfEmployment)
            }
            else return toast.error('Please fill required field', { id: 'error' })
        }

        setForm(form + 1);
    }

    const { mutate: sendOtp } = useSendOtp()
    const sendVerificationEmail = (e) => {
        var payload = {
            "companyAdminEmail": formData.email.toLowerCase(),
            "registerFlow": true,
            "verify": true
        }
        sendOtp(payload);
    }

    const handleLocationChange = async (e) => {
        setLocation(e.target.value);
        setLocationModal(true)
        if (!location) return;

        const res = await fetchPlace(location);

        !autocompleteCities.includes(e.target.value) &&
            res.data &&
            setAutocompleteCities(res.data.map((place) => place.location));
    };

    return (
        <>
            <div className='min-h-screen relative'>
                <img src={logo} className='absolute left-16 top-4 w-28 cursor-pointer' onClick={() => history.push('/')} />
                <div className='absolute right-12 top-8'>
                    <Link to="/login">
                        <div className="z-40 rounded-sm w-full h-10 text-black-500 bg-bermuda border-bermuda border-2 ">
                            <button className={` rounded-sm w-full h-10 border-2 bg-lightgreen border-bermuda px-4 transform -translate-x-1.5 -translate-y-2`}
                            >&nbsp;&nbsp;Back to Home page&nbsp;&nbsp;</button>
                        </div>
                    </Link>
                </div>


                <section className='p-8 max-w-[90rem] mx-auto'>
                    <div className='w-full h-20 relative max-w-6xl mx-auto'>
                        <h1 className='text-5xl font-semibold text-center my-4'>Join Corner Qube</h1>
                        <div className='w-full h-0.5 bg-slate-200 relative rounded mt-10'>
                            <div className='absolute top-0 left-0 h-full bg-cream' style={{ width: `${form < 4 ? (form) * 100 / 3 : 100}%` }}>
                            </div>
                            {
                                ['Company Information', 'Company Documents', 'Admin Information', 'Review Details'].map((item, i) => (
                                    <div className={`flex flex-col justify-center gap-3 items-center text-bermuda absolute -top-4 transform -translate-x-10`} style={{ left: `${(i / 3) * 100}%` }}>
                                        <div className="text-slate-900 transition duration-500 ease-in-out bg-white">
                                            {
                                                form < i ?
                                                    <div className='bg-gray-300 w-8 h-8 rounded-full'></div>
                                                    :
                                                    <div className='bg-lightgreen w-8 h-8 rounded-full'></div>
                                            }
                                        </div>
                                        <div className="text-center text-sm font-semibold text-bermuda max-w-[100px]">{item}</div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    {
                        form == 0 ?
                            <div className="border-none rounded py-10 z-40 relative">
                                <div className='mt-6 grid grid-cols-2 grid-gap-2'>
                                    <div className='px-16'>
                                        <div>
                                            <div className="mt-12 rounded w-full h-12 border-2 bg-lightgreen border-bermuda">
                                                <div className='rounded bg-white w-full h-12 text-black-500 border-black border-2 pr-4 transform -translate-x-1.5 -translate-y-2.5'>
                                                    <select name="howWouldYouLike" value={formData.howWouldYouLike} onBlur={handleBlur} className=" rounded w-full h-full outline-none pl-4"
                                                        onChange={handleChange} >
                                                        <option value="" selected disabled>How would you like to use Corner Qube?</option>
                                                        {hwyl.map(item => <option key={item.id} value={item.id}>{item.title}</option>)}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="mt-8 rounded w-full h-12 border-2 bg-lightgreen border-bermuda">
                                                <input type="text" aria-describedby="text"
                                                    className="rounded w-full h-12 text-black-500 border-black border-2 px-4 transform -translate-x-1.5 -translate-y-2.5"
                                                    placeholder="Enter Company Name here"
                                                    name="companyName"
                                                    value={formData.companyName}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className='error'>{companyNameError && (
                                                <div className="error">{companyAdminNameError}</div>
                                            )}</div>
                                            <div className="mt-8  rounded w-full h-12 border-2 bg-lightgreen border-bermuda">
                                                <div className='rounded bg-white w-full h-12 text-black-500 border-black border-2 pr-4 transform -translate-x-1.5 -translate-y-2.5'>
                                                    <select name="typeOfIndustry" value={formData.typeOfIndustry} onBlur={handleBlur} className="rounded w-full h-full outline-none pl-4"
                                                        onChange={handleChange} disabled={formData?.typeOfFirm ? formData?.typeOfFirm == 1 ? true : false : false}>
                                                        <option value="" selected disabled>Select industry type</option>
                                                        {industryTypeArr.map((data, i) => (<option disabled={(formData?.typeOfFirm == 2) && (data.id == 0)} value={data.id}>{data.typeOfIndustry}</option>))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='error'>{companyAdminPhoneError && (
                                                <div className="error">{companyAdminPhoneError}</div>
                                            )}</div>
                                            <div className="mt-8  rounded w-full h-12 border-2 bg-lightgreen border-bermuda">
                                                <input type="text" aria-describedby="text"
                                                    className="rounded w-full h-12 text-black-500 border-black border-2 px-4 transform -translate-x-1.5 -translate-y-2.5"
                                                    placeholder="Year Of Establishment"
                                                    name="companyEstablishmentYear"
                                                    value={formData.companyEstablishmentYear}
                                                    onBlur={handleBlur}
                                                    onChange={(e) => { if (e.target.value > -1) setFormData({ ...formData, [e.target.name]: e.target.value }) }}
                                                />
                                            </div>
                                            <div className="mt-8  rounded w-full h-12 border-2 bg-lightgreen border-bermuda">
                                                <div className='rounded bg-white w-full h-12 text-black-500 border-black border-2 pr-4 transform -translate-x-1.5 -translate-y-2.5'>
                                                    <select name="minProjectCost" value={formData.minProjectCost} onBlur={handleBlur} className="rounded w-full h-full outline-none pl-4"
                                                        onChange={handleChange} >
                                                        <option value="" selected disabled>Min Project Cost</option>
                                                        {minProjectCostArr.map((data, i) => (
                                                            <option value={data.id}>{data.minimumProjectCost}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='px-16'>
                                        <div className="mt-12 relative rounded w-full h-12 border-2 bg-lightgreen border-bermuda">
                                            <div className='rounded bg-white w-full h-12 text-black-500 border-black border-2 pr-4 transform -translate-x-1.5 -translate-y-2.5'>
                                                <select name="typeOfFirm" value={formData.typeOfFirm} onBlur={handleBlur} className="rounded w-full h-full outline-none pl-4"
                                                    onChange={handleChange} >
                                                    <option value="" selected disabled>Select type of firm</option>
                                                    {typeOfFirmArr.map((data, i) => (
                                                        <option value={data.id}>{data.typeOfFirm}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="mt-8 relative rounded w-full h-12 border-2 bg-lightgreen border-bermuda">
                                            <input type="text" aria-describedby="text"
                                                className="rounded w-full h-12 text-black-500 border-black border-2 px-4 transform -translate-x-1.5 -translate-y-2.5"
                                                placeholder="Enter sector"
                                                value={search || industrySectorArr[Number(formData.sector) - 1]?.sectorOfIndustry}
                                                onBlur={handleBlur}
                                                onChange={(e) => { setSearch(e.target.value); setFormData({ ...formData, sector: '' }) }}
                                            />
                                            {search ? <div className='absolute top-12 left-0 w-full p-2 z-20 bg-white max-h-40 overflow-y-auto rounded shadow-lg'>
                                                {industrySectorArr.map((data, i) => (
                                                    data.sectorOfIndustry?.toLowerCase()?.includes(search?.toLowerCase()) && <p className='px-2 pb-1 cursor-pointer' onClick={() => { setSearch(''); setFormData({ ...formData, sector: data.id }) }} value={data.id}>{data.sectorOfIndustry}</p>
                                                ))}
                                            </div> : <></>}
                                        </div>

                                        <div className={`mt-8 rounded w-full h-12 border-2 bg-lightgreen border-bermuda`}>
                                            <div className={'rounded w-full h-12 text-black-500 border-black border-2 pr-4 transform -translate-x-1.5 -translate-y-2.5 ' + (formData?.typeOfFirm == formData?.employeeCount ? 'bg-gray-100' : 'bg-white')}>
                                                <select name="employeeCount" value={formData.employeeCount || ''} onBlur={handleBlur} className=" disabled:bg-gray-100 rounded w-full h-full outline-none pl-4"
                                                    onChange={(e) => handleChange(e)} disabled={formData?.typeOfFirm == formData?.employeeCount ? true : false}>
                                                    <option value={''} selected={formData?.type == '1'} disabled>Number of Employees</option>
                                                    {noOfEmployeesArr?.slice(formData.typeOfFirm == 2 ? 1 : 0, noOfEmployeesArr?.length)?.map((data, i) => (
                                                        <option value={data.id} selected={formData?.employeeCount == data?.id}>{data.numberOfEmployee}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="mt-8 rounded w-full h-12 border-2 bg-lightgreen border-bermuda relative">
                                            <div className='relative transform -translate-x-1.5 -translate-y-2.5'>
                                                {location?.length > 2 && <div className='p-1 absolute top-1/2 right-2 transform -translate-y-1/2 cursor-pointer z-10 bg-white' onClick={() => { setLocation('') }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-red-500">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                    </svg>
                                                </div>}
                                                <input type="text" aria-describedby="text"
                                                    className="rounded w-full h-12 text-black-500 border-black border-2 px-4 capitalize"
                                                    placeholder="Head Quarters"
                                                    name="headquarters"
                                                    value={location}
                                                    onChange={(e) => handleLocationChange(e)}
                                                />
                                            </div>
                                            {location.length > 1 && locationModal ?
                                                <div style={{ zIndex: 60, }} className='w-full max-h-44 overflow-y-auto bg-white border border-gray-200 rounded absolute -left-1 top-9 shadow-md'>
                                                    {autocompleteCities.length == 0 ? <div className='py-1 px-4'>
                                                        No Results found</div> :
                                                        <div className='p-1 z-10'>
                                                            {autocompleteCities.map((data, i) =>
                                                            (
                                                                <div key={i} className="border-b last:border-none cursor-pointer p-2 hover:bg-blue-100 rounded capitalize" onClick={() => { setLocation(data); setLocationModal(false) }}>
                                                                    {data}
                                                                </div>
                                                            ))}
                                                        </div>}
                                                </div> : ""
                                            }
                                        </div>
                                        <div className="mt-8  rounded w-full h-12 border-2 bg-lightgreen border-bermuda">
                                            <input type="text" aria-describedby="text"
                                                className="rounded w-full h-12 text-black-500 border-black border-2 px-4 transform -translate-x-1.5 -translate-y-2.5"
                                                placeholder="Website Link"
                                                name="websiteLink"
                                                value={formData.websiteLink}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <center>
                                    <div className="mt-8 relative rounded-sm bg-bermuda max-w-xs h-12">
                                        <div className=" bg-bermuda rounded-sm w-full h-full border-2 border-bermuda">
                                        </div>
                                        <button onClick={() => validateData()} className={`border-2 focus:outline-none bg-white absolute top-0 transform -translate-x-1.5 -translate-y-1.5 left-0 w-full h-full border-bermuda rounded-sm`}
                                        ><span className="flex px-12">Upload company docs<img src={rightarrow} style={{ marginLeft: "12px", width: "20px" }} /></span></button>
                                    </div>
                                </center>
                            </div>
                            :
                            form == 1 ?
                                <div className="border-none rounded py-10 z-40 relative min-h-[530px] px-16">
                                    <div className='mt-16 text-2xl font-semibold'>Company Documents <span className='text-lg italic font-light'>(Optional)</span></div>
                                    <i className='text-sm font-medium'>Only PDF's are allowed</i>
                                    <div className='mt-10 grid grid-cols-4 gap-6 px-12' style={{ wordBreak: 'break-all' }}>
                                        <div>
                                            <label className='text-xl font-semibold'>Registration Certificate</label>
                                            <div className='relative'>
                                                {registrationCertificate && <span onClick={() => setRegistrationCertificate('')} className='text-red-400 absolute -top-3 -right-3 bg-white rounded-full cursor-pointer z-10 p-0.5'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                    </svg>
                                                </span>}

                                                <FileUploader
                                                    multiple={false}
                                                    handleChange={handleRegistrationCerticate}
                                                    name="registrationCertificate"
                                                    types={fileTypes}
                                                    children={
                                                        <div className='mt-1 border-2 h-28 rounded-lg border-bermuda px-10 py-4 cursor-pointer grid place-items-center'>
                                                            <div>
                                                                {registrationCertificate
                                                                    ? <h1 className='text-sm text-center'>{`${registrationCertificate?.name?.length > 15 ? registrationCertificate?.name?.slice(0, 13) + '... .pdf' : registrationCertificate?.name}`}</h1>
                                                                    :
                                                                    <div className='mt-2 drag-drop'>
                                                                        <div className="flex justify-center items-center">
                                                                            <div><img src={uploadicon} style={{ width: "25px" }} /></div>
                                                                            <div className="ml-2 text-xl font-semibold">Upload doc</div>
                                                                        </div>
                                                                        <center style={{ fontSize: "12px" }}>choose or drag and drop</center>
                                                                    </div>
                                                                }
                                                            </div>

                                                        </div>
                                                    }
                                                />
                                            </div>
                                            {/* <label className='mt-1 float-right text-xs font-medium'>(mandatory)</label> */}
                                        </div>
                                        <div>
                                            <label className='text-xl font-semibold'>Tax referrence</label>
                                            <div className='relative'>
                                                {taxReference && <span onClick={() => setTaxReference('')} className='text-red-400 absolute -top-3 -right-3 bg-white rounded-full cursor-pointer z-10 p-0.5'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                    </svg>
                                                </span>}
                                                <FileUploader
                                                    multiple={false}
                                                    handleChange={handleTaxReference}
                                                    name="taxReference"
                                                    types={fileTypes}
                                                    children={
                                                        <div className='mt-1 border-2 h-28 rounded-lg border-bermuda px-10 py-4 cursor-pointer grid place-items-center'>
                                                            <div>{taxReference ?
                                                                <h1 className='text-sm text-center'>{`${taxReference?.name?.length > 15 ? taxReference?.name?.slice(0, 13) + '... .pdf' : taxReference?.name}`}</h1>
                                                                :
                                                                <div className='mt-2 drag-drop'>
                                                                    <div className="flex justify-center items-center">
                                                                        <div><img src={uploadicon} style={{ width: "25px" }} /></div>
                                                                        <div className="ml-2 text-xl font-semibold">Upload doc</div>
                                                                    </div>
                                                                    <center style={{ fontSize: "12px" }}>choose or drag and drop</center>
                                                                </div>}
                                                            </div>
                                                        </div>
                                                    }
                                                />
                                            </div>
                                            {/* <label className='mt-1 float-right text-xs font-medium'>(mandatory)</label> */}
                                        </div>
                                        <div>
                                            <label className='text-xl font-semibold'>GST Certificate</label>
                                            <div className='relative'>
                                                {gstCertificate && <span onClick={() => setGstCertificate('')} className='text-red-400 absolute -top-3 -right-3 bg-white rounded-full cursor-pointer z-10 p-0.5'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                    </svg>
                                                </span>}
                                                <FileUploader
                                                    multiple={false}
                                                    handleChange={handleGstCertificate}
                                                    name="gstCertificate"
                                                    types={fileTypes}
                                                    children={
                                                        <div className='mt-1 border-2 h-28 rounded-lg border-bermuda px-10 py-4 cursor-pointer grid place-items-center'>
                                                            <div>{gstCertificate ?
                                                                <h1 className='text-sm text-center'>{`${gstCertificate?.name?.length > 15 ? gstCertificate?.name?.slice(0, 13) + '... .pdf' : gstCertificate?.name}`}</h1>
                                                                :
                                                                <div className='mt-2 drag-drop'>
                                                                    <div className="flex justify-center items-center">
                                                                        <div><img src={uploadicon} style={{ width: "25px" }} /></div>
                                                                        <div className="ml-2 text-xl font-semibold">Upload doc</div>
                                                                    </div>
                                                                    <center style={{ fontSize: "12px" }}>choose or drag and drop</center>
                                                                </div>}
                                                            </div>

                                                        </div>
                                                    }
                                                />
                                            </div>
                                            {/* <label className='mt-1 float-right text-xs font-medium'>(Not mandatory)</label> */}
                                        </div>
                                        <div>
                                            <label className='text-xl font-semibold'>Board of Directors</label>
                                            <div className='relative'>
                                                {boardOfDirectors && <span onClick={() => setBoardOfDirectors('')} className='text-red-400 absolute -top-3 -right-3 bg-white rounded-full cursor-pointer z-10 p-0.5'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                    </svg>
                                                </span>}
                                                <FileUploader
                                                    multiple={false}
                                                    handleChange={handleBoardOfDirectors}
                                                    name="boardOfDirectors"
                                                    types={fileTypes}
                                                    children={
                                                        <div className='mt-1 border-2 w-full h-28 rounded-lg border-bermuda px-10 py-4 cursor-pointer grid place-items-center'>
                                                            <div>{boardOfDirectors ?
                                                                <h1 className='text-sm text-center'>{`${boardOfDirectors?.name?.length > 15 ? boardOfDirectors?.name?.slice(0, 13) + '... .pdf' : boardOfDirectors?.name}`}</h1>
                                                                :
                                                                <div className='mt-2 drag-drop w-full'>
                                                                    <div className="flex justify-center items-center">
                                                                        <div><img src={uploadicon} style={{ width: "25px" }} /></div>
                                                                        <div className="ml-2 text-xl font-semibold">Upload doc</div>
                                                                    </div>
                                                                    <center style={{ fontSize: "12px", }}>choose or drag and drop</center>
                                                                </div>}
                                                            </div>
                                                        </div>
                                                    }
                                                />
                                            </div>
                                            {/* <label className='mt-1 float-right text-xs font-medium'>(Not mandatory)</label> */}
                                        </div>
                                    </div >

                                    <div className='flex flex-col items-center justify-center gap-1 text-xl mt-10'>
                                        <h1 className='font-light'>Uploading documents allows us to verify details and provide your company with</h1>
                                        <div className='flex items-center justify-center gap-1'>
                                            <img src="/verified.svg" className='w-20' alt="" />
                                            <span>Stamp</span>
                                        </div>
                                        <h1 className='font-light mt-2 text-lg'>Files can be uploaded from admin portal after registration</h1>
                                    </div>
                                </div >
                                :
                                form == 2 ?
                                    <div className="border-none rounded py-10 z-40 relative min-h-[530px] mx-auto px-16">
                                        <div className='mt-10 grid grid-cols-2 gap-10'>
                                            <div className=''>
                                                <div className="mt-8 max-w-xl  rounded w-full h-12 border-2 bg-lightgreen border-bermuda">
                                                    <input type="text" aria-describedby="text"
                                                        className="z-[60] rounded w-full h-12 text-black-500 border-black border-2 px-4 transform -translate-x-1.5 -translate-y-2.5"
                                                        placeholder="Enter Admin Name here"
                                                        name="companyAdminName"
                                                        value={formData.companyAdminName}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                </div>


                                                <div className="mt-8 max-w-xl  rounded w-full h-12 border-2 bg-lightgreen border-bermuda">
                                                    <div className='rounded bg-white w-full h-12 text-black-500 border-black border-2 pr-4 transform -translate-x-1.5 -translate-y-2.5'>
                                                        <select name="companyRoleId" value={formData.companyRoleId} onBlur={handleBlur} className="rounded w-full h-full pl-4 outline-none"
                                                            onChange={handleChange} >
                                                            <option value="" selected disabled>Select Role</option>
                                                            {companyRoleArr.map((data, i) => (<option value={data.id}>{data.companyRole}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=''>
                                                <div className="mt-8 max-w-xl  rounded w-full h-12 border-2 bg-lightgreen border-bermuda">
                                                    <input type="email" aria-describedby="text"
                                                        className="z-[60] rounded w-full h-12 text-black-500 border-black border-2 px-4 transform -translate-x-1.5 -translate-y-2.5"
                                                        placeholder="Enter Email"
                                                        name="email"
                                                        value={formData.email}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mt-6 text-2xl font-semibold'>Upload Docs <span className='text-lg italic font-light'>(Optional)</span></div>
                                        <div className='mt-2 text-sm font-medium'>Upload your preferred identification proof, proof of employment</div>
                                        <div className='mt-4 grid grid-cols-4 gap-6 px-12'>
                                            <div>
                                                <label className='text-xl font-semibold'>ID Proof</label>
                                                <div className='relative'>
                                                    {idProof && <span onClick={() => setIdProof('')} className='text-red-400 absolute -top-3 -right-3 bg-white rounded-full cursor-pointer z-10 p-0.5'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                        </svg>
                                                    </span>}
                                                    <FileUploader
                                                        multiple={false}
                                                        handleChange={handleIdProof}
                                                        name="idProof"
                                                        types={fileTypes}
                                                        children={
                                                            <div className='mt-1 border-2 h-28 bg-white rounded-lg border-bermuda px-10 py-2 cursor-pointer grid place-items-center'>
                                                                <div>{idProof ?
                                                                    <h1 className='text-sm text-center'>{`${idProof?.name?.length > 15 ? idProof.name?.slice(0, 13) + '... .pdf' : idProof?.name}`}</h1>
                                                                    :
                                                                    <div className='mt-2 drag-drop'>
                                                                        <div className="flex justify-center items-center">
                                                                            <div><img src={uploadicon} style={{ width: "25px" }} /></div>
                                                                            <div className="ml-2 text-xl font-semibold">Upload doc</div>
                                                                        </div>
                                                                        <center style={{ fontSize: "12px" }}>choose or drag and drop</center>
                                                                    </div>}
                                                                </div>
                                                            </div>
                                                        }
                                                    />
                                                </div>
                                                {/* <label className='mt-1 float-right text-xs font-medium'>(mandatory)</label> */}
                                            </div>
                                            <div>
                                                <label className='text-xl font-semibold'>Proof of Employment</label>
                                                <div className='relative'>
                                                    {proofOfEmployment && <span onClick={() => setProofOfEmployment('')} className='text-red-400 absolute -top-3 -right-3 bg-white rounded-full cursor-pointer z-10 p-0.5'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                        </svg>
                                                    </span>}
                                                    <FileUploader
                                                        multiple={false}
                                                        handleChange={handleProofOfEmployment}
                                                        name="proofOfEmployment"
                                                        types={fileTypes}
                                                        children={
                                                            <div className='mt-1 border-2 h-28 bg-white rounded-lg border-bermuda px-10 py-2 cursor-pointer grid place-items-center'>
                                                                <div>{proofOfEmployment ?
                                                                    <h1 className='text-sm text-center'>{`${proofOfEmployment?.name?.length > 15 ? proofOfEmployment?.name?.slice(0, 13) + '... .pdf' : proofOfEmployment?.name}`}</h1>
                                                                    :
                                                                    <div className='mt-2 drag-drop'>
                                                                        <div className="flex justify-center items-center">
                                                                            <div><img src={uploadicon} style={{ width: "25px" }} /></div>
                                                                            <div className="ml-2 text-xl font-semibold">Upload doc</div>
                                                                        </div>
                                                                        <center style={{ fontSize: "12px" }}>choose or drag and drop</center>
                                                                    </div>}
                                                                </div>
                                                            </div>
                                                        }
                                                    />
                                                </div>
                                                {/* <label className='mt-1 float-right text-xs font-medium'>(mandatory)</label> */}
                                            </div>
                                            <div>
                                            </div>
                                            <div>
                                            </div>
                                        </div >
                                    </div >
                                    :
                                    <div className='z-40 relative'>
                                        <div className="border-none rounded py-10 z-40 relative">
                                            <div className='mt-16'><span className='ml-16 text-2xl font-semibold'>Company Information</span>
                                                <div className='float-right mr-16'>
                                                    <div className="z-40 rounded-sm w-full h-10 text-black-500 bg-bermuda border-bermuda border-2 ">
                                                        <button onClick={() => setForm(0)} className={` rounded-sm w-full h-10 border-2 bg-signuppb border-bermuda px-6 transform -translate-x-1 -translate-y-2`}
                                                        >Edit Details</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='mt-5 grid grid-cols-2 grid-gap-2'>
                                                <div className='px-16'>
                                                    <div>
                                                        <div className="mt-8  rounded w-full h-12 opacity-80 border-2 bg-lightgreen border-bermuda">
                                                            <input name="howWouldYouLike" disabled={true} value={hwyl[Number(formData.howWouldYouLike) - 1]?.title} onBlur={handleBlur} className="opacity-100 z-[60] rounded w-full h-12 bg-white text-black-500 border-black border-2 px-4 transform -translate-x-1.5 -translate-y-2.5"
                                                            />
                                                        </div>

                                                        <div className="mt-8  rounded w-full h-12 opacity-80 border-2 bg-lightgreen border-bermuda">
                                                            <input type="text" aria-describedby="text" disabled={true}
                                                                className="z-[60] bg-white rounded w-full h-12 text-black-500 border-black border-2 px-4 transform -translate-x-1.5 -translate-y-2.5"
                                                                placeholder="Enter Company Name here"
                                                                name="companyName"
                                                                value={formData.companyName}
                                                                onBlur={handleBlur}
                                                            />
                                                        </div>

                                                        <div className="mt-8  rounded w-full h-12 border-2 opacity-80 bg-lightgreen border-bermuda">
                                                            <input name="typeOfIndustry" value={industryTypeArr[Number(formData.typeOfIndustry) - 1]?.typeOfIndustry} disabled={true} onBlur={handleBlur} className="opacity-100 bg-white z-[60] rounded w-full h-12 text-black-500 border-black border-2 px-4 transform -translate-x-1.5 -translate-y-2.5"
                                                            />
                                                        </div>

                                                        <div className="mt-8  rounded w-full h-12 border-2 opacity-80 bg-lightgreen border-bermuda">
                                                            <input type="text" aria-describedby="text"
                                                                disabled={true}
                                                                className="z-[60] bg-white rounded w-full h-12 text-black-500 border-black border-2 px-4 transform -translate-x-1.5 -translate-y-2.5"
                                                                placeholder="Year Of Establishment"
                                                                name="companyEstablishmentYear"
                                                                value={formData.companyEstablishmentYear}
                                                                onBlur={handleBlur}
                                                            />
                                                        </div>

                                                        <div className="mt-8  rounded w-full h-12 opacity-80 border-2 bg-lightgreen border-bermuda">
                                                            <input name="minProjectCost" value={minProjectCostArr[Number(formData.minProjectCost) - 1].minimumProjectCost} disabled={true} onBlur={handleBlur} className="opacity-100 bg-white rounded w-full h-12 text-black-500 border-black border-2 px-4 transform -translate-x-1.5 -translate-y-2.5"
                                                            />
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className='px-16'>
                                                    <div className="mt-8  rounded w-full h-12 border-2 opacity-80 bg-lightgreen border-bermuda">
                                                        <input name="typeOfFirm" value={typeOfFirmArr[Number(formData.typeOfFirm) - 1]?.typeOfFirm} disabled={true} onBlur={handleBlur} className="opacity-100 bg-white z-[60] rounded w-full h-12 text-black-500 border-black border-2 px-4 transform -translate-x-1.5 -translate-y-2.5"
                                                        />
                                                    </div>
                                                    <div className="mt-8  rounded w-full h-12 opacity-80 border-2 bg-lightgreen border-bermuda">
                                                        <input name="sector" value={industrySectorArr[Number(formData.sector) - 1]?.sectorOfIndustry} disabled={true} onBlur={handleBlur} className="opacity-100 bg-white z-[60] rounded w-full h-12 text-black-500 border-black border-2 px-4 transform -translate-x-1.5 -translate-y-2.5"
                                                        />

                                                    </div>
                                                    <div className={`${typeOfFirm == 1 ? "opacity-60" : "opacity-80"} mt-8  rounded w-full h-12 border-2 bg-lightgreen border-bermuda`}>
                                                        <input name="employeeCount" value={noOfEmployeesArr[Number(formData.employeeCount) - 1]?.numberOfEmployee} disabled={true} onBlur={handleBlur} className="opacity-100 bg-white z-[60] rounded w-full h-12 text-black-500 border-black border-2 px-4 transform -translate-x-1.5 -translate-y-2.5"
                                                        />
                                                    </div>
                                                    <div className="mt-8  rounded w-full h-12 opacity-80 border-2 bg-lightgreen border-bermuda">
                                                        <input type="text" aria-describedby="text"
                                                            className="z-[60] bg-white rounded w-full h-12 text-black-500 border-black border-2 px-4 transform -translate-x-1.5 -translate-y-2.5 capitalize"
                                                            placeholder="Head Quartered"
                                                            disabled={true}
                                                            name="headquarters"
                                                            value={location}
                                                            onBlur={handleBlur}
                                                        />
                                                    </div>

                                                    <div className="mt-8  rounded w-full opacity-80 h-12 border-2 bg-lightgreen border-bermuda">
                                                        <input type="text" aria-describedby="text"
                                                            className="z-[60] bg-white rounded w-full h-12 text-black-500 border-black border-2 px-4 transform -translate-x-1.5 -translate-y-2.5"
                                                            placeholder="Website Link"
                                                            disabled={true}
                                                            name="websiteLink"
                                                            value={formData.websiteLink}
                                                            onBlur={handleBlur}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            <div className='mt-10'><span className='ml-16 text-2xl font-semibold'>Company Documents</span>
                                                <div className='float-right mr-16'>
                                                    <div className="z-40 rounded-sm w-full h-10 text-black-500 bg-bermuda border-bermuda border-2 ">
                                                        <button onClick={() => setForm(1)} className={` rounded-sm w-full h-10 border-2 bg-signuppb border-bermuda px-6 transform -translate-x-1 -translate-y-2`}
                                                        >Edit Details</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='mt-6 grid grid-cols-4 gap-6 px-16'>
                                                <div>
                                                    <label className='text-xl font-semibold'>Registration Certificate</label>
                                                    <div className='relative'>
                                                        <div className='mt-1 border-2 h-28 bg-white rounded-lg border-bermuda px-10 py-4 grid place-items-center'>
                                                            <div>{registrationCertificate ?
                                                                <h1 className='text-sm text-center'>{`${registrationCertificate?.name?.length > 15 ? registrationCertificate?.name?.slice(0, 13) + '... .pdf' : registrationCertificate?.name}`}</h1>
                                                                :
                                                                <h1 className='text-lg italic'>Not Uploaded</h1>
                                                            }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <label className='mt-1 float-right text-xs font-medium'>(mandatory)</label> */}
                                                </div>
                                                <div>
                                                    <label className='text-xl font-semibold'>Tax referrence</label>
                                                    <div className='relative'>
                                                        <div className='mt-1 border-2 h-28 bg-white rounded-lg border-bermuda px-10 py-4 grid place-items-center'>
                                                            <div>{taxReference ? <h1 className='text-sm text-center'>{`${taxReference?.name?.length > 15 ? taxReference?.name?.slice(0, 13) + '... .pdf' : taxReference?.name}`}</h1>
                                                                :
                                                                <h1 className='text-lg italic'>Not Uploaded</h1>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <label className='mt-1 float-right text-xs font-medium'>(mandatory)</label> */}
                                                </div>
                                                <div>
                                                    <label className='text-xl font-semibold'>GST Certificate</label>
                                                    <div className='relative'>
                                                        <div className='mt-1 border-2 h-28 bg-white rounded-lg border-bermuda px-10 py-4 grid place-items-center'>
                                                            <div>{gstCertificate ?
                                                                <h1 className='text-sm text-center'>{`${gstCertificate?.name?.length > 15 ? gstCertificate?.name?.slice(0, 13) + '... .pdf' : gstCertificate?.name}`}</h1>
                                                                :
                                                                <h1 className='text-lg italic'>Not Uploaded</h1>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <label className='mt-1 float-right text-xs font-medium'>(Not mandatory)</label> */}
                                                </div>
                                                <div>
                                                    <label className='text-xl font-semibold'>Board of Directors</label>
                                                    <div className='relative'>
                                                        <div className='mt-1 border-2 h-28 bg-white rounded-lg border-bermuda px-10 py-4 grid place-items-center'>
                                                            <div>{boardOfDirectors ?
                                                                <h1 className='text-sm text-center'>{`${boardOfDirectors?.name?.length > 15 ? boardOfDirectors?.name?.slice(0, 13) + '... .pdf' : boardOfDirectors?.name}`}</h1>
                                                                :
                                                                <h1 className='text-lg italic'>Not Uploaded</h1>
                                                            }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <label className='mt-1 float-right text-xs font-medium'>(Not mandatory)</label> */}
                                                </div>
                                            </div >
                                            <div className='mt-10'><span className='ml-16 text-2xl font-semibold'>Admin Information</span>
                                                <div className='float-right mr-16'>
                                                    <div className="z-40 rounded-sm w-full h-10 text-black-500 bg-bermuda border-bermuda border-2 ">
                                                        <button onClick={() => setForm(2)} className={` rounded-sm w-full h-10 border-2 bg-signuppb border-bermuda px-6 transform -translate-x-1 -translate-y-2`}
                                                        >Edit Details</button>
                                                    </div>
                                                </div></div>
                                            <div className='mt-1 grid grid-cols-2 grid-gap-2'>
                                                <div className='px-16'>
                                                    <div className="mt-8  rounded w-full h-12 opacity-80 border-2 bg-lightgreen border-bermuda">
                                                        <input name="companyRoleId" value={companyRoleArr[Number(formData.companyRoleId) - 1]?.companyRole} onBlur={handleBlur} disabled={true} className="opacity-100 bg-white z-[60] rounded w-full h-12 text-black-500 border-black border-2 px-4 transform -translate-x-1.5 -translate-y-2.5"
                                                        />
                                                    </div>
                                                    <div className="mt-12  rounded w-full h-12 opacity-80 border-2 bg-lightgreen border-bermuda">
                                                        <input type="email" aria-describedby="text"
                                                            className="z-[60] bg-white rounded w-full h-12 text-black-500 border-black border-2 px-4 transform -translate-x-1.5 -translate-y-2.5"
                                                            placeholder="Enter Email"
                                                            name="email"
                                                            disabled={true}
                                                            value={formData.email}
                                                            onBlur={handleBlur}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='px-16'>
                                                    <div className="mt-8  rounded w-full h-12 opacity-80 border-2 bg-lightgreen border-bermuda">
                                                        <input type="text" aria-describedby="text"
                                                            className="z-[60] bg-white rounded w-full h-12 text-black-500 border-black border-2 px-4 transform -translate-x-1.5 -translate-y-2.5"
                                                            placeholder="Enter Admin Name here"
                                                            name="companyAdminName"
                                                            disabled={true}
                                                            value={formData.companyAdminName}
                                                            onBlur={handleBlur}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='ml-16 mt-10 text-2xl font-semibold'>Upload Docs</div>
                                            <div className='ml-16 mt-2 text-sm font-medium'>Upload your preferred identification proof, proof of employment</div>
                                            <div className='mt-10 grid grid-cols-4 gap-6 px-16'>
                                                <div>
                                                    <label className='text-xl font-semibold'>ID Proof</label>
                                                    <div className='relative'>
                                                        <div className='mt-1 border-2 h-28 bg-white rounded-lg border-bermuda px-10 py-4 grid place-items-center'>
                                                            <div>{idProof ?
                                                                <h1 className='text-sm text-center'>{`${idProof?.name?.length > 15 ? idProof?.name?.slice(0, 13) + '... .pdf' : idProof?.name}`}</h1>
                                                                :
                                                                <h1 className='text-lg italic'>Not Uploaded</h1>
                                                            }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <label className='text-xl font-semibold'>Proof of Employment</label>
                                                    <div className='relative'>
                                                        <div className='mt-1 border-2 h-28 bg-white rounded-lg border-bermuda px-10 py-4 grid place-items-center'>
                                                            <div>{proofOfEmployment ?
                                                                <h1 className='text-sm text-center'>{`${proofOfEmployment?.name?.length > 15 ? proofOfEmployment?.name?.slice(0, 13) + '... .pdf' : proofOfEmployment?.name}`}</h1>
                                                                :
                                                                <h1 className='text-lg italic'>Not Uploaded</h1>
                                                            }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                </div>
                                                <div>
                                                </div>
                                            </div >

                                        </div>
                                    </div>


                    }

                    {
                        form == 1 || form == 2 ?
                            <div className='relative flex items-center justify-between z-40 px-16'>

                                <div className="relative rounded-sm bg-bermuda w-40 h-12">
                                    <div className=" bg-bermuda rounded-sm w-full h-full border-2 border-bermuda">
                                    </div>
                                    <button onClick={() => setForm(form - 1)} className={`border-2 z-[60] focus:outline-none bg-white absolute top-0 transform -translate-x-1.5 -translate-y-1.5 left-0 w-full h-full border-bermuda rounded-sm`}
                                    ><span className="flex px-12"><img src={rightarrow} style={{ width: "20px", transform: "rotate(180deg)" }} />&nbsp;&nbsp; &nbsp;<span className='mt-2.5'>Back</span></span></button>
                                </div>

                                <div className="rounded-sm h-10 text-black-500 bg-bermuda border-bermuda border-2 ">
                                    <button onClick={() => validateData()} className={` rounded-sm w-full h-10 border-2 bg-white border-bermuda px-4 transform -translate-x-1.5 -translate-y-2`}
                                    ><span className="flex px-4"> &nbsp; &nbsp;Continue<img src={rightarrow} style={{ marginLeft: "12px", width: "20px" }} /></span></button>
                                </div>

                            </div>
                            :
                            form == 3 ?
                                <>
                                    <div className='float-right mr-16 z-40 relative mb-6'>
                                        <div className="z-40 rounded-sm w-full text-black-500 bg-bermuda border-bermuda border-2 ">
                                            <button disabled={loading || adding} className={` rounded-sm w-full text-xl font-semibold border-2 bg-lightgreen disabled:bg-gray-300 border-bermuda px-8 py-2 transform -translate-x-1.5 -translate-y-1.5`}
                                                onClick={(e) => handleSubmit(e)}>Submit Details</button>
                                        </div>
                                    </div>
                                </>
                                :
                                <></>
                    }
                </section>


            </div >
            <Circles />

            {
                successModal && <div className='fixed inset-0 w-full min-h-screen overflow-hidden grid place-items-center bg-black bg-opacity-50 z-50'>
                    <div className='w-full bg-white max-w-xl rounded-lg flex flex-col items-center justify-center gap-6 py-10'>
                        <svg className='rounded-full shadow-lg' width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="75" cy="75" r="75" fill="#E6BCFA" />
                            <path d="M61.1843 103.639L39.2972 80.7833C38.4666 79.914 38 78.7357 38 77.5072C38 76.2788 38.4666 75.1005 39.2972 74.2312L41.9563 71.4491C42.7883 70.5811 43.9159 70.0936 45.0915 70.0936C46.2672 70.0936 47.3948 70.5811 48.2268 71.4491L64.1369 88.0631L101.606 46.4566C102.413 45.5627 103.525 45.0396 104.7 45.0022C105.875 44.9647 107.017 45.416 107.874 46.2569L110.604 48.9498C111.461 49.7924 111.962 50.9559 111.998 52.1845C112.034 53.4132 111.601 54.6064 110.795 55.502L67.5607 103.536C67.1536 103.989 66.6634 104.352 66.1193 104.604C65.5752 104.855 64.9883 104.99 64.3935 104.999C63.7986 105.009 63.2079 104.894 62.6566 104.66C62.1053 104.426 61.6046 104.079 61.1843 103.639Z" fill="white" />
                        </svg>
                        <p className='text-xl'>Thanks for registering to corner qube</p>

                        <div className='rounded bg-bermuda max-w-max'>
                            <button onClick={sendVerificationEmail} className='bg-lightviolet rounded py-2 px-10 text-lg font-medium transform -translate-x-1 -translate-y-1'>Verify Email</button>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}
