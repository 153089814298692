import React from 'react';
import {
    Chart as ChartJS,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bubble } from 'react-chartjs-2';
import { formatNumber } from '../../services/constant';

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);

function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
}


export function BubbleChart({ width, height, containerStyle, containerClass, firstData = [], secondData = [] }) {

    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: (context) => {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        label += '(';
                        if (context.parsed.x !== null && context.parsed.x !== undefined) {
                            label += 'Cost: $' + formatNumber(context.parsed.x);
                        }
                        if (context.parsed.y !== null && context.parsed.y !== undefined) {
                            label += ', Duration: ' + formatNumber(context.parsed.y) + " months";
                        }
                        if (context.raw.r !== null && context.raw.r !== undefined) {
                            label += ', Projects: ' + formatNumber(Number(context.raw.r) - 10);
                        }
                        label += ')';

                        return label;
                    }
                }
            },
            legend: {
                display: false,
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                type: 'linear',
                display: true,
                position: 'left',
                grid: {
                    drawOnChartArea: true,
                    color: 'rgba(0,0,0,0.08)',
                    drawBorder: false,
                    borderDash: [5, 6]
                },
            },
            x: {
                beginAtZero: true,
                display: true,
                position: 'left',
                grid: {
                    color: 'transparent',
                }
            }
        },
    };

    let others = secondData.map(item => (
        {
            label: item.companyName,
            data: [{
                x: item.cost,
                y: item.duration,
                r: 1 + (item.count || 1),
            }],
            backgroundColor: 'rgba(22, 93, 255, 0.6)',
            borderColor: 'rgba(22, 93, 255, 0.8)',
        }
    ))
    const data = {
        datasets: [
            {
                label: firstData.map(item => item.companyName) || '',
                data: firstData.map(item => ({
                    x: item.cost,
                    y: item.duration,
                    r: 1 + (item.count || 1),
                })),
                backgroundColor: 'rgba(29, 180, 64, 0.6)',
                borderColor: 'rgba(29, 180, 64, 0.8)',
            },
            ...others
        ],
    };

    return (
        <div className={containerClass} style={{ width: width, height: height, overflow: 'hidden', display: 'flex', alignItems: 'center', justifyContent: 'center', ...containerStyle }}>
            <Bubble width={width} height={height} data={data} options={options} />
        </div>
    )
}
