import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import logo from '../../images/logo-white.svg';
import { adminDetails, selectAdminDetails, selectAdminPlan } from '../../slices/adminSlice';
import { openLink, useClickOutside } from '../../services/constant';
import moment from 'moment/moment';
import PaymentSuccess from '../Reusable/PaymentSuccess';
import usePayment from '../../hooks/usePayment';

export default function NavBar() {
    const history = useHistory()
    const dispatch = useDispatch()
    var adminInfo = useSelector(selectAdminDetails);
    const adminPlan = useSelector(selectAdminPlan);
    const [menu, setMenu] = useState(false)
    const ref = useRef();
    const [open, setOpen] = useState(false)

    useClickOutside(() => setOpen(false), ref);
    const { createOrder, success, setSuccess } = usePayment()

    const closeSuccess = () => {
        setSuccess(false);
        window.location.replace('/');
    }

    const showItems = () => {
        setMenu(!menu)
    }
    if (!localStorage.getItem('corner_qube')) {
        adminInfo = null
    }

    const valRoute = () => window.location.pathname == '/' || window.location.pathname.includes('searchresults') || window.location.pathname.includes('/company/')

    return (
        <>
            {success ? <PaymentSuccess {...success} closeSuccess={closeSuccess} /> : <></>}
            <nav className="bg-bermuda shadow-lg w-full sticky top-0 z-[100]">
                <div className="w-full mx-auto px-1">
                    <div className="flex items-center justify-between px-6 2xl:px-10">
                        <div className="flex items-center flex-grow">
                            <div>
                                <span onClick={() => history.push('/')} className="flex py-2 cursor-pointer">
                                    <img src={logo} alt="Logo" className="w-32" />
                                </span>
                            </div>
                            <ul className="flex flex-grow items-center justify-center gap-6">
                                <li onClick={() => history.push('/')} className="py-4 px-10 ml-6 text-gray-50 cursor-pointer">Home</li>
                                <li onClick={() => history.push('/explore')} className="py-4 px-10 text-gray-50 cursor-pointer">Industry services</li>
                                <li onClick={() => history.push('/pricing')} className="py-4 px-10 text-gray-50 cursor-pointer">Pricing</li>
                            </ul>
                        </div>
                        {
                            adminInfo ?
                                <div className='inline-flex bg-cream h-max rounded-lg border border-bermuda' ref={ref}>
                                    <div className='flex relative items-center gap-3 bg-lightgreen h-max py-1 px-2 rounded-lg transform -translate-x-1 -translate-y-1 border-2 border-bermuda'>
                                        <div onClick={() => setOpen(!open)} className='w-11 h-11 overflow-hidden rounded bg-white p-1 shadow grid place-items-center cursor-pointer'>
                                            {
                                                adminInfo?.companyIcon ?
                                                    <img src={adminInfo?.companyIcon} className="w-full h-full object-contain" alt="" />
                                                    : <span className='text-2xl font-semibold text-green-500'>{adminInfo?.companyAdminName ? adminInfo?.companyAdminName?.[0].toUpperCase() : ''}</span>
                                            }
                                        </div>
                                        <div onClick={() => setOpen(!open)}>
                                            <h1 className='font-medium text-gray-900 cursor-pointer'>{adminInfo?.companyAdminName}</h1>
                                            <p className='text-sm text-gray-700 cursor-pointer'>Client portal</p>
                                        </div>
                                        {open ? <div className='absolute top-14 -right-1 z-[200] bg-white custom-shadow w-full min-w-[10rem] rounded '>

                                            <div onClick={() => openLink(`https://admin.cornerqube.com/dashboard/${adminInfo?.companyName}`)} className='border border-bermuda p-3 font-medium rounded-t flex items-center justify-between text-gray-500 hover:text-gray-800 cursor-pointer'>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.5 20L2 15.5M2 15.5L6.5 11M2 15.5H15.5M15.5 2L20 6.5M20 6.5L15.5 11M20 6.5H6.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                                <span>Admin Portal</span>
                                            </div>
                                            <div onClick={() => { localStorage.setItem('corner_qube', ''); dispatch(adminDetails(null)); window.location.replace('/login'); }} className='flex items-center rounded-b justify-between border border-bermuda p-3 font-medium text-gray-500 hover:text-gray-800 cursor-pointer'>
                                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M7.89535 9.23C7.45785 9.23 7.11192 9.57 7.11192 10C7.11192 10.42 7.45785 10.77 7.89535 10.77H14V15.55C14 18 11.9753 20 9.47238 20H4.51744C2.02471 20 0 18.01 0 15.56V4.45C0 1.99 2.03488 0 4.52762 0H9.49273C11.9753 0 14 1.99 14 4.44V9.23H7.89535ZM17.6302 6.5402L20.5502 9.4502C20.7002 9.6002 20.7802 9.7902 20.7802 10.0002C20.7802 10.2002 20.7002 10.4002 20.5502 10.5402L17.6302 13.4502C17.4802 13.6002 17.2802 13.6802 17.0902 13.6802C16.8902 13.6802 16.6902 13.6002 16.5402 13.4502C16.2402 13.1502 16.2402 12.6602 16.5402 12.3602L18.1402 10.7702H14.0002V9.2302H18.1402L16.5402 7.6402C16.2402 7.3402 16.2402 6.8502 16.5402 6.5502C16.8402 6.2402 17.3302 6.2402 17.6302 6.5402Z" fill="#E04F5F" />
                                                </svg>
                                                <span>Logout</span>
                                            </div>
                                        </div> : <></>}
                                    </div>
                                </div>
                                :

                                <div className="flex items-center space-y-4">
                                    <a href="/login">
                                        <div className="z-[100] mr-8 mt-1 relative rounded w-36 h-14">
                                            <div className="z-50 bg-cream rounded w-full h-full border-2 border-bermuda">
                                            </div>
                                            <button className="border-2 z-[60] bg-lightgreen absolute top-0 transform -translate-x-1 -translate-y-1 left-0 w-full h-full border-bermuda font-medium text-lg rounded">Login</button>
                                        </div>
                                    </a>
                                </div>
                        }
                    </div>
                </div>
               
            </nav>
            {(adminPlan && valRoute() && moment(adminPlan?.validUpto).diff(moment(), 'days') < 31 && moment(adminPlan?.validUpto).diff(moment(), 'days') >= 0) ? <div className='px-4 py-1 bg-[#FFC6A7] flex items-center gap-2 border-2 border-bermuda rounded-lg max-w-max fixed top-20 left-1/2 transform -translate-x-1/2 z-[101]' style={{ boxShadow: '4px 5px 0px rgba(0,0,0,1) ' }}>
                <p className='text-sm min-w-max'>{moment(adminPlan?.validUpto).diff(moment(), 'days') + 1} days left on your Corner Qube {adminPlan?.title} plan</p>
                {
                    (adminInfo?.userRole?.toLowerCase() == 'subadmin' || adminInfo?.userRole?.toLowerCase() == 'admin')
                        ?
                        adminPlan.price == 0
                            ?
                            <button onClick={() => history.push('/pricing')} className='bg-[#BDF0BC] py-2 px-3 rounded-lg text-sm min-w-max'>Upgrade plan</button>
                            :
                            <button onClick={() => createOrder({ planId: adminPlan?.id })} className='bg-[#BDF0BC] py-2 px-3 rounded-lg text-sm min-w-max'>Renew plan!</button>
                        :
                        <></>
                }
            </div> : <></>}
        </>
    )
}