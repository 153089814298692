import React, { useEffect } from 'react'
import { BarChart } from '../Charts/BarChart'
import { HorizontalBarChart } from '../Charts/HorizontalBarChart'
import { useQuery } from 'react-query'
import { getAllReviewsByCompany, getCompanySearch, getDashboardData } from '../../services/service'
import { useSelector } from 'react-redux'
import { selectAdminDetails } from '../../slices/adminSlice'
import { useWindowSize } from '../../services/constant'
import { useParams } from 'react-router-dom'

let data = [
    {
        label: 'Jan',
        value: Math.round(Math.random() * 100)
    },
    {
        label: 'Feb',
        value: Math.round(Math.random() * 100)
    },
    {
        label: 'Mar',
        value: Math.round(Math.random() * 100)
    },
    {
        label: 'Apr',
        value: Math.round(Math.random() * 100)
    },
    {
        label: 'May',
        value: Math.round(Math.random() * 100)
    },
    {
        label: 'Jun',
        value: Math.round(Math.random() * 100)
    },
    {
        label: 'Jul',
        value: Math.round(Math.random() * 100)
    },
    {
        label: 'Aug',
        value: Math.round(Math.random() * 100)
    },
    {
        label: 'Sep',
        value: Math.round(Math.random() * 100)
    },
    {
        label: 'Oct',
        value: Math.round(Math.random() * 100)
    },
    {
        label: 'Nov',
        value: Math.round(Math.random() * 100)
    },
    {
        label: 'Dec',
        value: Math.round(Math.random() * 100)
    },
]

function Home() {
    const adminInfo = useSelector(selectAdminDetails);
    const { companyName } = useParams()
    let screen = useWindowSize()
    let { data: reviewRes, refetch } = useQuery('getAllReviewsByCompany', () => getAllReviewsByCompany(adminInfo?.companyName), {
        enabled: false,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
    })
    let { data: dashboardRes } = useQuery('getDashboardData', getDashboardData, {
        refetchOnMount: true,
        refetchOnWindowFocus: false,
    })
    let { data: SearchRes } = useQuery('getCompanySearch', getCompanySearch, {
        refetchOnMount: true,
        refetchOnWindowFocus: false,
    })

    useEffect(() => {
        if (adminInfo?.companyName) {
            if (adminInfo?.companyName != companyName) {
                window.location.replace(`/dashboard/${adminInfo?.companyName}`)
            }
            refetch()
        }
    }, [adminInfo])

    return (
        <section className='p-5'>
            <div className='flex items-start gap-6'>
                <div className='bg-white rounded-lg shadow p-4 pb-0'>
                    <h1 className='text-xl font-medium'>Number of firms</h1>
                    <BarChart width={screen?.width > 1440 ? 700 : 600} containerClass={'my-4 w-full max-w-xl 2xl:max-w-2xl'} graphData={dashboardRes?.data?.sectors?.map(item => {
                        return {
                            label: item?.sectorOfIndustry,
                            value: item?.count || 0
                        }
                    })} redraw={false} />
                </div>
                <div className='grid grid-cols-2 gap-6'>
                    {SearchRes?.data?.knowFirms?.map((item, i) => {
                        if (i == 0) return <Card1 {...item} />
                        if (i == 1) return <Card2 {...item} />
                        if (i == 2) return <Card3 {...item} />
                        if (i == 3) return <Card4 {...item} />
                    })}
                </div>
            </div>

            <div className='flex items-start py-5 gap-6'>
                <div className='grid grid-cols-2 gap-6 w-[47%]'>
                    {reviewRes?.data?.overallClientRating && <FactorStats reviewData={reviewRes?.data?.overallClientRating} />}

                    <div className='col-span-2 p-3 bg-white rounded-lg shadow-md relative min-h-[170px] 2xl:min-h-[250px]'>
                        <svg className='absolute -top-4 -left-4' width="50" height="50" viewBox="0 0 67 68" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M28.7489 4.65752L36.5014 13.4038L46.5234 7.49285L47.7304 19.1939L59.2614 20.391L53.4617 30.5774L62.0973 38.4253L51.5063 43.2062L53.948 54.7072L42.6111 52.2565L37.9261 63.0177L30.1736 54.2714L20.1516 60.1824L18.9446 48.4813L7.41363 47.2842L13.2133 37.0978L4.57772 29.2499L15.1687 24.469L12.7271 12.968L24.064 15.4187L28.7489 4.65752Z" fill="#E04F5F" />
                            <path d="M30.3398 20.2274L37.3725 38.624L33.9201 40.0568L26.2393 21.9292L30.3398 20.2274ZM38.7183 46.9481C38.0286 47.2343 37.3412 47.2371 36.656 46.9566C35.9674 46.6677 35.4878 46.1753 35.2171 45.4792C34.9331 44.7984 34.9388 44.1143 35.2341 43.4268C35.526 42.7311 36.0167 42.2401 36.7064 41.9538C37.3794 41.6745 38.0602 41.6793 38.7488 41.9682C39.434 42.2487 39.9186 42.7294 40.2025 43.4102C40.3791 43.8726 40.4284 44.344 40.3504 44.8244C40.2773 45.293 40.0992 45.7175 39.816 46.098C39.5329 46.4785 39.167 46.7618 38.7183 46.9481Z" fill="white" />
                        </svg>
                        <p className='text-gray-400 text-right'>{new Date().toLocaleDateString()}</p>
                        <h1 className='text-lg text-green-600 font-medium'>Now You Can Also Remove clients from list !!</h1>
                        <p className='text-sm'>admins and sub admins can now remove client list. Navigate to Clients tab to remove clients. you can add them back after 5 days day</p>
                    </div>
                </div>
                <div className='rounded-lg shadow-md bg-white p-3 w-[53%]'>
                    <h1 className='font-medium'>Sector wise Client vs Vendor</h1>
                    <div className='flex items-center text-xs justify-center gap-6 w-full'>
                        <div className='flex items-center gap-2'>
                            <div className='w-3 h-3 rounded-full bg-[#165DFF]'>
                            </div>
                            <span>Vendor</span>
                        </div>
                        <div className='flex items-center gap-2'>
                            <div className='w-3 h-3 rounded-full bg-[#14C9C9]'>
                            </div>
                            <span>Client</span>
                        </div>
                    </div>
                    <HorizontalBarChart initialData={dashboardRes?.data?.sectors} />
                </div>
            </div>
        </section>
    )
}

export default Home

const FactorStats = ({ reviewData }) => {
    let reviewArr = reviewData || [];
    let graphRating = [];
    let topServices = [];
    let impressed = [];
    let improve = [];
    let ftImprove = [];
    let ftImpressed = [];
    let totalImpressed = 0
    let totalImprove = 0
    let totalFTImpressed = 0
    let totalFTImprove = 0


    for (let i = 0; i < reviewArr?.length; i++) {
        for (let j = 0; j < reviewArr[i].graphRating?.length; j++) {
            let temp = { typeOfReview: reviewArr[i].graphRating[j].typeOfReview }
            if (i == 0) {
                temp.rating = reviewArr[i].graphRating[j].rating
            }
            else {
                temp.rating = reviewArr[i - 1].graphRating[j].rating + reviewArr[i].graphRating[j].rating
            }
            graphRating[j] = temp
        }
        ftImprove.push(reviewArr[i].improvementReviewComment)
        ftImpressed.push(reviewArr[i].positiveReviewComment)
    }

    for (let i of graphRating) {
        i.rating /= reviewArr.length
    }

    const data = reviewArr.reduce((acc, crr) => {
        const temp = acc || crr
        topServices.push(...crr.offeringTags)
        impressed.push(crr.serviceImpressed)
        improve.push(crr.serviceImprove)
        temp.avgRating = Number(acc?.avgRating || 0) + Number(crr.rating)
        return temp
    }, {})

    let elementCounts = {}
    let obj = []


    elementCounts = {}
    obj = []
    impressed.forEach(function (i) { elementCounts[i] = (elementCounts[i] || 0) + 1; });;

    impressed = Object.entries(elementCounts).sort((a, b) => b[1] - a[1]);
    for (let i of impressed) {
        obj.push({
            industryServices: i[0],
            value: i[1]
        });
        totalImpressed += i[1]
    }
    data.impressed = obj[0]

    elementCounts = {}
    obj = []
    improve.forEach(function (i) { elementCounts[i] = (elementCounts[i] || 0) + 1; });;

    improve = Object.entries(elementCounts).sort((a, b) => b[1] - a[1]);
    for (let i of improve) {
        obj.push({
            industryServices: i[0],
            value: i[1]
        });
        totalImprove += i[1]
    }
    data.improve = obj[0]


    elementCounts = {}
    obj = []
    ftImpressed.forEach(function (i) { elementCounts[i] = (elementCounts[i] || 0) + 1; });;

    ftImpressed = Object.entries(elementCounts).sort((a, b) => b[1] - a[1]);
    for (let i of ftImpressed) {
        obj.push({
            industryServices: i[0],
            value: i[1]
        });
        totalFTImpressed += i[1]
    }
    data.ftImpressed = obj[0]

    elementCounts = {}
    obj = []
    ftImprove.forEach(function (i) { elementCounts[i] = (elementCounts[i] || 0) + 1; });;

    ftImprove = Object.entries(elementCounts).sort((a, b) => b[1] - a[1]);
    for (let i of ftImprove) {
        obj.push({
            industryServices: i[0],
            value: i[1]
        });
        totalFTImprove += i[1]
    }
    data.ftImprove = obj[0]

    data.totalImprove = totalImpressed
    data.totalImpressed = totalImprove
    data.totalFTImprove = totalFTImpressed
    data.totalFTImpressed = totalFTImprove
    data.avgRating = Number(data.avgRating / reviewArr.length).toFixed(1)

    impressed = Math.round((data?.impressed?.value / data?.totalImpressed) * 100)
    improve = Math.round((data?.improve?.value / data?.totalImprove) * 100)
    ftImpressed = Math.round((data?.ftImpressed?.value / data?.totalFTImpressed) * 100)
    ftImprove = Math.round((data?.ftImprove?.value / data?.totalFTImprove) * 100)

    return (
        <>
            <div className='rounded-xl p-4 bg-lightgreen shadow-md'>
                {
                    data?.ftImpressed ? <>
                        <h1 className=' font-medium'>{data?.ftImpressed?.industryServices}</h1>
                        <p className='text-green-600 text-sm'>Over {ftImpressed}% of clients were impressed by this factor</p>
                    </>
                        :
                        <h1 className=' text-center '>Factor Impressed Not Applicable</h1>
                }
            </div>
            <div className='rounded-xl p-4 bg-lightgreen shadow-md'>
                {
                    data?.impressed ?
                        <>
                            <h1 className=' font-medium'>{data?.impressed?.industryServices}</h1>
                            <p className='text-green-600 text-sm'>Over {impressed}% of clients were impressed by this service</p>
                        </>
                        :
                        <h1 className=' text-center'>Service Impressed Not Applicable</h1>
                }
            </div>
        </>
    )
}


const Card1 = ({ type, count }) => {
    return (<div className='bg-lightblue rounded-xl overflow-hidden shadow-lg max-w-[230px]'>
        <p className='p-3 pb-0 capitalize'>{type}</p>
        <div className='flex items-center gap-1 px-3'>
            <h1 className='text-2xl 2xl:text-3xl text-sky-600 font-medium'>{Intl.NumberFormat('en', {
                notation: 'compact'
            }).format(count || 0)}</h1>
            <svg width="33" height="33" viewBox="0 0 33 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.123 6.72925L16.123 30.4792" stroke="#00A9C3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7.83888 16.3081L16.1219 6.72894L24.4062 16.3081" stroke="#00A9C3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </div>
        <svg className='w-full' viewBox="0 0 204 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.1" fill-rule="evenodd" clip-rule="evenodd" d="M0 66.5894C0 66.5894 8.04152 64.1139 17.1843 70.0609C34.5732 81.3719 35.5652 60.6588 42.0132 50.6714C47.9124 41.534 52.798 47.1091 59.3664 58.2834C64.2108 66.5249 71.2709 80.3592 78.5825 78.1321C85.266 76.0962 90.004 59.8705 99.2681 55.744C106.61 52.4735 112.577 61.4584 121.032 58.2834C132.502 53.9761 137.625 28.619 145.822 28.619C155.94 28.619 163.063 7.17285 172.267 7.17285C181.416 7.17285 182.658 19.7837 192.667 28.619C200.318 35.3737 204 28.619 204 28.619V101H0V66.5894Z" fill="#00A9C3" />
            <path d="M2 59.7338C2 59.7338 7.03653 59.6264 16 65.5C33.048 76.6712 35.1784 54.8641 41.5 45C47.2835 35.9755 53.7628 40.4941 60.2023 51.5304C64.9517 59.6702 71.8735 73.3336 79.0416 71.1339C85.5941 69.1232 90.2392 53.0979 99.3216 49.0224C106.52 45.7923 112.37 54.6662 120.659 51.5304C131.904 47.2764 136.926 22.2325 144.963 22.2325C154.882 22.2325 163.476 1 172.5 1C181.47 1 181.947 13.5063 191.759 22.2325C199.261 28.9037 202 22.2325 202 22.2325" stroke="#00A9C3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    </div>)
}
const Card2 = ({ type, count }) => {
    return (<div className='bg-cream rounded-xl overflow-hidden shadow-lg max-w-[230px]'>
        <p className='p-3 pb-0 capitalize'>{type}</p>
        <div className='flex items-center gap-1 px-3'>
            <h1 className='text-2xl 2xl:text-3xl text-yellow-600 font-medium'>{Intl.NumberFormat('en', {
                notation: 'compact'
            }).format(count || 0)}</h1>
            <svg width="33" height="33" viewBox="0 0 33 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.123 6.72925L16.123 30.4792" stroke="#DEA900" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7.83888 16.3081L16.1219 6.72894L24.4062 16.3081" stroke="#DEA900" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </div>
        <svg className='w-full' viewBox="0 0 204 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.1" fill-rule="evenodd" clip-rule="evenodd" d="M0 66.5894C0 66.5894 8.04152 64.1139 17.1843 70.0609C34.5732 81.3719 35.5652 60.6588 42.0132 50.6714C47.9124 41.534 52.798 47.1091 59.3664 58.2834C64.2108 66.5249 71.2709 80.3592 78.5825 78.1321C85.266 76.0962 90.004 59.8705 99.2681 55.744C106.61 52.4735 112.577 61.4584 121.032 58.2834C132.502 53.9761 137.625 28.619 145.822 28.619C155.94 28.619 163.063 7.17285 172.267 7.17285C181.416 7.17285 182.658 19.7837 192.667 28.619C200.318 35.3737 204 28.619 204 28.619V101H0V66.5894Z" fill="#DEA900" />
            <path d="M2 59.7338C2 59.7338 7.03653 59.6264 16 65.5C33.048 76.6712 35.1784 54.8641 41.5 45C47.2835 35.9755 53.7628 40.4941 60.2023 51.5304C64.9517 59.6702 71.8735 73.3336 79.0416 71.1339C85.5941 69.1232 90.2392 53.0979 99.3216 49.0224C106.52 45.7923 112.37 54.6662 120.659 51.5304C131.904 47.2764 136.926 22.2325 144.963 22.2325C154.882 22.2325 163.476 1 172.5 1C181.47 1 181.947 13.5063 191.759 22.2325C199.261 28.9037 202 22.2325 202 22.2325" stroke="#DEA900" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    </div>)
}
const Card3 = ({ type, count }) => {
    return (<div className='bg-lightviolet rounded-xl overflow-hidden shadow-lg max-w-[230px]'>
        <p className='p-3 pb-0 capitalize'>{type}</p>
        <p className='px-3 text-[10px] opacity-0'>Events, Conferences, etc</p>
        <div className='flex items-center gap-1 px-3'>
            <h1 className='text-2xl 2xl:text-3xl text-purple-600 font-medium'>{Intl.NumberFormat('en', {
                notation: 'compact'
            }).format(count || 0)}</h1>
            <svg width="33" height="33" viewBox="0 0 33 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.123 6.72925L16.123 30.4792" stroke="#7E00BA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7.83888 16.3081L16.1219 6.72894L24.4062 16.3081" stroke="#7E00BA" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </div>
        <svg className='w-full' viewBox="0 0 204 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.1" fill-rule="evenodd" clip-rule="evenodd" d="M0 66.5894C0 66.5894 8.04152 64.1139 17.1843 70.0609C34.5732 81.3719 35.5652 60.6588 42.0132 50.6714C47.9124 41.534 52.798 47.1091 59.3664 58.2834C64.2108 66.5249 71.2709 80.3592 78.5825 78.1321C85.266 76.0962 90.004 59.8705 99.2681 55.744C106.61 52.4735 112.577 61.4584 121.032 58.2834C132.502 53.9761 137.625 28.619 145.822 28.619C155.94 28.619 163.063 7.17285 172.267 7.17285C181.416 7.17285 182.658 19.7837 192.667 28.619C200.318 35.3737 204 28.619 204 28.619V101H0V66.5894Z" fill="#7E00BA" />
            <path d="M2 59.7338C2 59.7338 7.03653 59.6264 16 65.5C33.048 76.6712 35.1784 54.8641 41.5 45C47.2835 35.9755 53.7628 40.4941 60.2023 51.5304C64.9517 59.6702 71.8735 73.3336 79.0416 71.1339C85.5941 69.1232 90.2392 53.0979 99.3216 49.0224C106.52 45.7923 112.37 54.6662 120.659 51.5304C131.904 47.2764 136.926 22.2325 144.963 22.2325C154.882 22.2325 163.476 1 172.5 1C181.47 1 181.947 13.5063 191.759 22.2325C199.261 28.9037 202 22.2325 202 22.2325" stroke="#7E00BA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    </div>)
}
const Card4 = ({ type, count }) => {
    return (<div className='bg-lightgreen rounded-xl overflow-hidden shadow-lg max-w-[230px]'>
        <p className='p-3 pb-0 capitalize'>{type}</p>
        <p className='px-3 text-[10px]'>Events, Conferences, etc</p>
        <div className='flex items-center gap-1 px-3'>
            <h1 className='text-2xl 2xl:text-3xl text-green-600 font-medium'>{Intl.NumberFormat('en', {
                notation: 'compact'
            }).format(count || 0)}</h1>
            <svg width="33" height="33" viewBox="0 0 33 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.123 6.72925L16.123 30.4792" stroke="#03A400" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7.83888 16.3081L16.1219 6.72894L24.4062 16.3081" stroke="#03A400" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </div>
        <svg className='w-full' viewBox="0 0 204 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.1" fill-rule="evenodd" clip-rule="evenodd" d="M0 66.5894C0 66.5894 8.04152 64.1139 17.1843 70.0609C34.5732 81.3719 35.5652 60.6588 42.0132 50.6714C47.9124 41.534 52.798 47.1091 59.3664 58.2834C64.2108 66.5249 71.2709 80.3592 78.5825 78.1321C85.266 76.0962 90.004 59.8705 99.2681 55.744C106.61 52.4735 112.577 61.4584 121.032 58.2834C132.502 53.9761 137.625 28.619 145.822 28.619C155.94 28.619 163.063 7.17285 172.267 7.17285C181.416 7.17285 182.658 19.7837 192.667 28.619C200.318 35.3737 204 28.619 204 28.619V101H0V66.5894Z" fill="#03A400" />
            <path d="M2 59.7338C2 59.7338 7.03653 59.6264 16 65.5C33.048 76.6712 35.1784 54.8641 41.5 45C47.2835 35.9755 53.7628 40.4941 60.2023 51.5304C64.9517 59.6702 71.8735 73.3336 79.0416 71.1339C85.5941 69.1232 90.2392 53.0979 99.3216 49.0224C106.52 45.7923 112.37 54.6662 120.659 51.5304C131.904 47.2764 136.926 22.2325 144.963 22.2325C154.882 22.2325 163.476 1 172.5 1C181.47 1 181.947 13.5063 191.759 22.2325C199.261 28.9037 202 22.2325 202 22.2325" stroke="#03A400" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    </div>)
}

