import React, { useState } from 'react'
import { toast } from 'react-hot-toast'
import { decodeToken, encrypt } from '../../services/constant'
import { updatePassword } from '../../services/service'

function ResetPassword({ closePopUp, progress }) {

    const [cred, setCred] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    })
    const [view, setView] = useState({
        oldPassword: false,
        newPassword: false,
        confirmPassword: false,
    })
    const [errors, setErrors] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    })

    const handleChange = e => {
        e.preventDefault();
        setCred({ ...cred, [e.target.name]: e.target.value })
        setErrors({
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
        })
    }

    const handleErrors = (data) => setErrors({ ...errors, ...data })
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (cred.newPassword !== cred.confirmPassword) {
            handleErrors({ confirmPassword: 'Password not matched' })
        }
        else if (cred.oldPassword.length < 6)
            handleErrors({ oldPassword: 'Password must be greater than 6 characters' })
        else if (cred.newPassword.length < 6)
            handleErrors({ newPassword: 'Password must be greater than 6 characters' })
        else {
            let user = decodeToken()
            if (!user) {
                return toast.error("Token expired, log in again to continue", { id: 'error' })
            }
            let res = await updatePassword({ companyAdminEmail: user.adminEmail.toLowerCase(), oldPassword: encrypt(cred.oldPassword), newPassword: encrypt(cred.newPassword), progress: progress || 5 })
            if (res.userStatus == 'wrong Password') {
                handleErrors({ oldPassword: 'wrong Password' })
            }
            else if (!res.status) {
                toast.error('Something went wrong, try again later', { id: 'error' })
            }
            else {
                toast.success("Password updated successfully", { id: 'success' })
                localStorage.setItem('corner_qube', res.accessToken)
                closePopUp()
            }
        }
    }

    return (
        <>
            <div className='fixed inset-0 w-full h-screen overflow-hidden bg-black bg-opacity-70 grid place-items-center z-50'>
                <form onSubmit={handleSubmit} className='w-full max-w-3xl py-8 px-16 2xl:py-10 rounded-lg bg-white font-medium relative'>
                    {progress == 5 && <button type="button" className="absolute top-4 right-4 z-10" onClick={() => closePopUp()}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>}
                    <h1 className='text-lightbrown text-4xl text-center'>Welcome to Corner Qube</h1>
                    {/* <p className='text-gray-600 text-center my-2'>Leading B2B Company Review Platform</p> */}
                    <div className='max-w-lg mt-10 flex flex-col gap-4'>
                        <h1 className='text-lightbrown text-2xl'>{progress !== 5 && 'Please '}Reset Your Password</h1>
                        <div className='grid grid-cols-2 items-center gap-4'>
                            <label className='text-right'>Enter Current Password</label>
                            <div className='relative'>
                                <div className='flex items-center bg-white rounded shadow border-2 border-gray-100 p-1 w-full max-w-xs'>
                                    <input type={view?.oldPassword ? "text" : "password"} name='oldPassword' value={cred.oldPassword} onChange={handleChange} className=' outline-none px-1 flex-grow' />
                                    {view?.oldPassword ?
                                        <svg onClick={() => setView({ ...view, oldPassword: false })} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 cursor-pointer flex-shrink-0">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 10.5V6.75a4.5 4.5 0 119 0v3.75M3.75 21.75h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H3.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                                        </svg>
                                        :
                                        <svg onClick={() => setView({ ...view, oldPassword: true })} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 cursor-pointer flex-shrink-0">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                                        </svg>
                                    }
                                </div>
                                <p className='error'>{errors.oldPassword}</p>
                            </div>
                        </div>
                        <div className='grid grid-cols-2 items-center gap-4'>
                            <label className='text-right'>Enter New Password</label>
                            <div className='flex items-center bg-white rounded shadow border-2 border-gray-100 p-1 w-full max-w-xs'>
                                <input type={view?.newPassword ? "text" : "password"} name='newPassword' value={cred.newPassword} onChange={handleChange} className='outline-none px-1 flex-grow' />
                                {view?.newPassword ?
                                    <svg onClick={() => setView({ ...view, newPassword: false })} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 cursor-pointer flex-shrink-0">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 10.5V6.75a4.5 4.5 0 119 0v3.75M3.75 21.75h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H3.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                                    </svg>
                                    :
                                    <svg onClick={() => setView({ ...view, newPassword: true })} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 cursor-pointer flex-shrink-0">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                                    </svg>
                                }
                                <p className='error'>{errors.newPassword}</p>
                            </div>
                        </div>
                        <div className='grid grid-cols-2 items-center gap-4'>
                            <label className='text-right'>Re-Enter New Password</label>
                            <div className='flex items-center bg-white rounded shadow border-2 border-gray-100 p-1 w-full max-w-xs'>
                                <input type={view?.confirmPassword ? "text" : "password"} name='confirmPassword' value={cred.confirmPassword} onChange={handleChange} className='outline-none px-1 flex-grow' />
                                {view?.confirmPassword ?
                                    <svg onClick={() => setView({ ...view, confirmPassword: false })} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 cursor-pointer flex-shrink-0">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 10.5V6.75a4.5 4.5 0 119 0v3.75M3.75 21.75h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H3.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                                    </svg>
                                    :
                                    <svg onClick={() => setView({ ...view, confirmPassword: true })} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 cursor-pointer flex-shrink-0">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                                    </svg>
                                }
                                <p className='error'>{errors.confirmPassword}</p>
                            </div>
                        </div>


                    </div>
                    <div className=' text-right pt-8 pr-6'>
                        <button type='submit' className='py-2 px-5 bg-cream text-yellow-600 font-medium text-lg rounded-md shadow-lg shadow-yellow-50'>Reset Password</button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default ResetPassword