import { createSlice } from '@reduxjs/toolkit'
import { groupBy } from '../services/constant';

const initialState = {
    reviewData: null,
    projectReviews: null
}

export const reviewSlice = createSlice({
    name: 'review',
    initialState,
    reducers: {
        setReviewData: (state, action) => {
            state.reviewData = action.payload || null;
        },
        setProjectReviewData: (state, action) => {
            state.projectReviews = action.payload || [];
        },
    },
})

// Action creators are generated for each case reducer function
export const { setReviewData, setProjectReviewData } = reviewSlice.actions

export const selectReviewData = (state) => state.review.reviewData

export const selectProjectReviewData = (state) => state.review.projectReviews

export const selectProjectMyReview = ({ state, adminId }) => {
    let arr = state.review.projectReviews;
    arr = arr.filter(item => item.reviewGivenById == adminId)
    return arr || []
}
export const selectProjectMyClientReview = ({ state, companyId }) => {
    let arr = state.review.projectReviews;
    arr = arr.filter(item => item.companyId == companyId);
    arr = groupBy(arr, rev => rev.companyName)
    return arr || []
}

export const selectProjectByCompany = ({ state, companyId }) => {
    let arr = state.review.projectReviews;
    arr = arr.filter(item => item.companyId != companyId);
    arr = groupBy(arr, rev => rev.companyName)
    return arr || []
}

export default reviewSlice.reducer