import React, { useEffect, useState } from 'react';
import logo from '../../images/corner-cube-logo.svg';
import banner from '../../images/banner tetris -01.png';
import { Link, useHistory } from 'react-router-dom';
import { encrypt } from '../../services/constant';
import { useLogin } from '../../hooks';

export default function Login() {
    const history = useHistory();
    const [email, setEmail] = useState("");
    const [view, setView] = useState(false);
    const [password, setPassword] = useState("");
    const [isExpired, setIsExpired] = useState(false)

    useEffect(() => { localStorage.setItem("corner_qube", ''); }, [])

    const { mutate: loginUser, isLoading } = useLogin((expired) => expired ? setIsExpired(expired) : {});

    const validateLoginDetails = (e) => {
        e.preventDefault()
        var payload = {
            "companyAdminEmail": email.toLowerCase(),
            "companyAdminPassword": encrypt(password)
        }
        loginUser(payload)
    };

    return (
        <div>
            {isExpired ? <Expired history={history} /> : <></>}
            <img src={logo} className="cursor-pointer" style={{ position: "absolute", top: "10px", left: "4rem", width: "120px" }} onClick={() => history.push('/')} />
            <div className="enable-center flex pl-24 flex-col sm:flex-row" style={{ minHeight: `100dvh` }}>
                <div className="border-none rounded py-4 w-1/2">
                    <form onSubmit={validateLoginDetails} className='pl-20 pr-24'>
                        <center className="-mt-2 text-2xl font-semibold">Sign into dashboard</center>
                        <div>
                            <div className="mt-14 z-50 rounded w-full h-12 border-2 bg-lightgreen border-bermuda">
                                <input type="text" aria-describedby="text"
                                    className="z-[60] rounded w-full h-12 text-black-500 border-black border-2 px-4 transform -translate-x-1.5 -translate-y-2.5"
                                    placeholder="Email"
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="mt-8 z-50 rounded w-full h-12 border-2 bg-lightgreen border-bermuda">
                            <div className='flex items-center rounded w-full h-12 bg-white text-black-500 border-black border-2 px-4 transform -translate-x-1.5 -translate-y-2.5'>
                                <input type={view ? "text" : "password"} aria-describedby="text"
                                    placeholder="Password"
                                    onChange={(e) => setPassword(e.target.value)}
                                    className='p-1 flex-grow outline-none bg-white'
                                />
                                {view ?
                                    <svg onClick={() => setView(false)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 cursor-pointer flex-shrink-0">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 10.5V6.75a4.5 4.5 0 119 0v3.75M3.75 21.75h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H3.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                                    </svg>
                                    :
                                    <svg onClick={() => setView(true)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 cursor-pointer flex-shrink-0">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                                    </svg>
                                }
                            </div>
                        </div>
                        <span id="status" className="error"></span>
                        <Link to="/forgotpassword"><div className='mt-4 float-right text-sm text-black-500 font-semibold'>Forgot Password</div></Link>
                        <div className="mt-16 z-40 rounded w-full h-12 text-black-500 bg-bermuda border-bermuda border-2 ">
                            <button disabled={isLoading} className={`z-50 rounded w-full h-12 border-2 bg-lightgreen disabled:bg-gray-300 border-bermuda px-4 transform -translate-x-1.5 -translate-y-2.5`}
                                type='submit'>LOGIN</button>
                        </div>
                        <center className="text-base font-semibold mt-10">Don't have an account ? <span className='cursor-pointer' onClick={() => history.push('/signup')}>Signup</span></center>
                    </form>
                </div>
                <div className="w-1/2 h-[100dvh] border-none bg-lightturquoise">
                    <img src={banner} className="w-full h-full object-bottom" style={{ maxHeight: `100dvh` }} />
                    <div className="z-40 rounded absolute top-36 right-40 w-3/12 h-14 text-black-500 bg-bermuda border-bermuda border-2 ">
                        <button className="z-50 rounded w-full h-14 border-2 font-semibold text-3xl tracking-tightest bg-cream border-bermuda px-4 transform -translate-x-1.5 -translate-y-2.5">Welcome Back!</button>
                    </div>
                </div>
            </div>
        </div>
    );
}


const Expired = ({ history }) => {
    return (
        <section className='fixed inset-0 z-[100000] grid place-items-center p-5'>
            <div onClick={() => history.push('/signup')} className='fixed inset-0 bg-black bg-opacity-80'></div>

            <div className='w-full max-w-2xl rounded-lg bg-white relative p-10 flex flex-col items-center justify-center gap-3'>
                <svg width="126" height="126" viewBox="0 0 126 126" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="9.67969" y="9.66797" width="114.779" height="114.779" rx="5.25" fill="#F4BCC7" stroke="black" stroke-width="1.5" />
                    <rect x="5.60547" y="6.17969" width="114.779" height="114.779" rx="5.25" fill="#BDF0BC" stroke="black" stroke-width="1.5" />
                    <rect x="1.53906" y="0.947266" width="114.779" height="114.779" rx="5.25" fill="white" stroke="black" stroke-width="1.5" />
                    <rect x="6.60547" y="47.8711" width="104.651" height="20.9302" rx="10" fill="#333333" />
                </svg>

                <h1 className='text-3xl font-semibold mt-4'>Login failed!</h1>
                <p className='text-lg font-medium text-center'>You have not upgraded your company to a paid plan!</p>
                <p className='text-lg font-medium'>Please create a new Account</p>

                <button onClick={() => history.push('/signup')} className='py-2 px-10 mt-4 rounded-lg bg-lightviolet text-lg font-medium' style={{ boxShadow: '4px 4px 0px rgba(0,0,0,0.8)' }}>Sign up now</button>
            </div>
        </section>
    )
}

