import React, { useState } from 'react'
import bgrating from '../../images/rating-bg.svg';
import fillRating from '../../images/reviewstar.svg';
import { DoughnutChart } from '../Charts/DoughnutChart';
import RadarChart from '../Charts/RadarChart';
import moment from 'moment/moment';
import { useGetReviewDetails } from '../../hooks';
import { useQuery } from 'react-query';
import { getAllReviewsByCompany, getOverallRating } from '../../services/service';
import FullReviewDetail from '../Reusable/FullReviewDetail';
import { getObjectWithMaxValue, getSum } from '../../services/constant';

function Reviews({ companyDetails, setId, setSpecificCompany }) {
    const [type, setType] = useState(1)
    const [typeExternal, setTypeExternal] = useState(false)
    let { isLoading: ratingLoading, data: OverallRatingRes } = useQuery('getOverallRating', () => getOverallRating(companyDetails?.companyName), {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    })

    let { data: reviewRes } = useQuery('getAllReviewsByCompany', () => getAllReviewsByCompany(companyDetails?.companyName), {
        refetchOnWindowFocus: false,
    })

    const { mutate } = useGetReviewDetails();

    const getReviewDetailsById = (id) => {
        mutate(id)
    }

    return (
        <div>
            <div className='ml-14 mt-10 text-2xl font-semibold'>Ratings & Feedbacks</div>
            <div className='p-6'>
                <div className='flex items-center justify-between px-10'>
                    <div>
                        {type == 1 ?
                            <>
                                {OverallRatingRes?.data?.clientOverallRating?.map((data, i) => (
                                    <div className='flex items-center gap-2'>
                                        <img src={fillRating} className="w-10 -mt-1" />
                                        <div className='text-2xl font-semibold'>{data.avgRating == 0 ? "No Ratings yet" : data.avgRating}</div>
                                        <div className='ml-4 text-lg'>{data.numberOfFeedback == 0 ? "0" : data.numberOfFeedback} Feedbacks</div>
                                    </div>
                                ))}
                            </> :
                            <>
                                {OverallRatingRes?.data?.vendorOverallRating?.map((data, i) => (
                                    <div className='flex items-center gap-2'>
                                        <img src={fillRating} className="w-10 -mt-1" />
                                        <div className='text-2xl font-semibold'>{data.avgRating == 0 ? "No Ratings yet" : data.avgRating}</div>
                                        <div className='ml-4 text-lg'>{data.numberOfFeedback == 0 ? "0" : data.numberOfFeedback} Feedbacks</div>
                                    </div>
                                ))}
                            </>
                        }
                    </div>
                    <div className=''>
                        <button className={`${type == 1 ? "bg-lightviolet" : "bg-white"} w-32 h-12 border-2 border-black transform translate-x-0.5 rounded-l-lg`} onClick={() => setType(1)}>Client</button>
                        <button className={`${type == 2 ? "bg-signupBgColor" : "bg-white"} w-32 h-12 border-2 border-black border-l-transparent rounded-r-lg`} onClick={() => setType(2)}>Vendor</button>
                    </div>
                </div>

                <div className='mt-8 px-12 w-full'>
                    <div className={`z-50 rounded  w-full h-56 border-2 ${type == 1 ? 'bg-lightviolet' : 'bg-signupBgColor'} border-bermuda`}>
                        <div className="px-4 z-[60] rounded w-full h-full bg-white text-black-500 border-black border-2 transform -translate-x-2.5 -translate-y-3">
                            {type == 1 ?
                                <div className='grid grid-cols-3 gap-2 mx-auto 2xl:ml-20'>
                                    {OverallRatingRes?.data?.clientTypeWiseRating?.map((data, i) => (
                                        <div className='mt-8 flex'>
                                            <div className='px-4'><img src={`../${data.typeOfReview}.svg`} className="w-full" /></div>
                                            <div className='flex-1'>
                                                <div className='text-xl font-medium'>{data.typeOfReview}</div>
                                                <div className='flex text-lg font-medium mt-1'><img src={fillRating} className="-mt-1 mr-2 w-8" /> {data.avgRating}/5</div>
                                            </div>
                                        </div>

                                    ))}
                                </div> :
                                <div className='grid grid-cols-3 gap-2 mx-auto 2xl:ml-20'>
                                    {OverallRatingRes?.data?.vendorTypeWiseRating?.map((data, i) => (
                                        <div className='mt-8 flex'>
                                            <div className='px-4'><img src={`../${data.typeOfReview}.svg`} className="w-full" /></div>
                                            <div className='flex-1'>
                                                <div className='text-xl font-medium'>{data.typeOfReview}</div>
                                                <div className='flex text-lg font-medium mt-1'><img src={fillRating} className="-mt-1 mr-2 w-8" /> {data.avgRating}/5</div>
                                            </div>
                                        </div>

                                    ))}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>


            <div className='flex items-center justify-between p-6 2xl:px-10'>
                <div>
                    <h1 className='text-3xl'>Company wise Review</h1>
                    <p className='mt-2'>{type == 2 ? 'Reviews this firm (client) has received from its vendors.' : 'Reviews this firm (vendor) has received from its clients.'}</p>
                </div>

                <div className='flex items-center gap-20'>
                    <div className='flex items-center'>
                        <button className={`border border-bermuda transform translate-x-0.5 rounded-l-3xl py-1.5 px-5 text-lg font-medium ${!typeExternal ? 'bg-lightgreen' : 'bg-white'}`} onClick={() => setTypeExternal(false)}>Registered</button>
                        <button className={`border border-bermuda border-l-transparent rounded-r-3xl py-1.5 px-5 text-lg font-medium ${typeExternal ? 'bg-lightgreen' : 'bg-white'}`} onClick={() => setTypeExternal(true)}>External</button>
                    </div>

                    <div className={`rounded-lg border border-bermuda ${type == 1 ? "bg-lightviolet" : "bg-signupBgColor"}`}>
                        <div className='bg-white font-medium rounded-lg border border-bermuda py-2 px-5 transform -translate-x-1 -translate-y-1'>
                            Filter & sort
                        </div>
                    </div>
                </div>
            </div>

            {
                ratingLoading ?
                    <div className='w-full min-h-[250px] grid place-items-center'>
                        <div className='border-4 border-gray-600 border-b-transparent animate-spin rounded-full w-10 h-10'>
                        </div>
                    </div>
                    :
                    <>
                        {
                            (!reviewRes?.data?.externalClientReview?.length && typeExternal && type == 1) ?
                                <h1 className='capitalize w-full text-3xl py-4 text-center font-medium text-gray-300'>No External Client Review Available</h1>
                                :
                                (!reviewRes?.data?.externalVendorReview?.length && typeExternal && type == 2) ?
                                    <h1 className='capitalize w-full text-3xl py-4 text-center font-medium text-gray-300'>No External Vendor Review Available</h1>
                                    :
                                    (reviewRes?.data?.registeredClientReview && !Object.keys(reviewRes?.data?.registeredClientReview)?.length && !typeExternal && type == 1) ?
                                        <h1 className='capitalize w-full text-3xl py-4 text-center font-medium text-gray-300'>No Registered Client Review Available</h1>
                                        :
                                        (reviewRes?.data?.registeredClientReview && !Object.keys(reviewRes?.data?.registeredVendorReview)?.length && !typeExternal && type == 2) ?
                                            <h1 className='capitalize w-full text-3xl py-4 text-center font-medium text-gray-300'>No Registered Vendor Review Available</h1>
                                            :
                                            <></>
                        }
                    </>
            }

            {reviewRes?.data ? <div className="px-11 ml-2 grid-cols-1 sm:grid-cols-2 grid flex-[1] h-full align-middle gap-4 p-4 min-h-[250px]">
                {type == 1 ?
                    <>
                        {
                            typeExternal ?
                                <>
                                    {
                                        reviewRes?.data?.externalClientReview?.map((data, i) => <ClientReview2 key={i} i={i} data={data} getReviewDetailsById={getReviewDetailsById} />)
                                    }
                                </>
                                :
                                <>
                                    {
                                        Object.getOwnPropertyNames(reviewRes?.data?.registeredClientReview)?.map((item, i) => {
                                            let reviewArr = reviewRes?.data?.registeredClientReview[item] || [];

                                            let graphRating = [];
                                            let topServices = [];
                                            let impressed = [];
                                            let improve = [];
                                            let ftImprove = [];
                                            let ftImpressed = [];
                                            let totalImpressed = 0
                                            let totalImprove = 0
                                            let totalFTImpressed = 0
                                            let totalFTImprove = 0


                                            for (let i = 0; i < reviewArr.length; i++) {
                                                for (let j = 0; j < reviewArr[i].graphRating.length; j++) {
                                                    let temp = { typeOfReview: reviewArr[i].graphRating[j].typeOfReview }
                                                    if (i == 0) {
                                                        temp.rating = reviewArr[i].graphRating[j].rating
                                                    }
                                                    else {
                                                        temp.rating = reviewArr[i - 1].graphRating[j].rating + reviewArr[i].graphRating[j].rating
                                                    }
                                                    graphRating[j] = temp
                                                }
                                                ftImprove.push(reviewArr[i].improvementReviewComment)
                                                ftImpressed.push(reviewArr[i].positiveReviewComment)
                                            }

                                            for (let i of graphRating) {
                                                i.rating /= reviewArr.length
                                            }

                                            const data = reviewArr.reduce((acc, crr) => {
                                                const temp = acc || crr
                                                topServices.push(...crr.offeringTags)
                                                impressed.push(crr.serviceImpressed)
                                                improve.push(crr.serviceImprove)
                                                temp.avgRating = Number(acc?.avgRating || 0) + Number(crr.rating)
                                                return temp
                                            }, null)

                                            let elementCounts = {}
                                            let obj = []

                                            topServices.forEach(({ industryServices }) => {
                                                elementCounts[industryServices] = (elementCounts[industryServices] || 0) + 1;
                                            });

                                            topServices = Object.entries(elementCounts).sort((a, b) => b[1] - a[1]);
                                            for (let i of topServices) {
                                                obj.push({
                                                    industryServices: i[0],
                                                    value: i[1]
                                                });
                                            }
                                            data.topServices = obj

                                            elementCounts = {}
                                            obj = []
                                            impressed.forEach(function (i) { elementCounts[i] = (elementCounts[i] || 0) + 1; });;

                                            impressed = Object.entries(elementCounts).sort((a, b) => b[1] - a[1]);
                                            for (let i of impressed) {
                                                obj.push({
                                                    industryServices: i[0],
                                                    value: i[1]
                                                });
                                                totalImpressed += i[1]
                                            }
                                            data.impressed = obj[0]

                                            elementCounts = {}
                                            obj = []
                                            improve.forEach(function (i) { elementCounts[i] = (elementCounts[i] || 0) + 1; });;

                                            improve = Object.entries(elementCounts).sort((a, b) => b[1] - a[1]);
                                            for (let i of improve) {
                                                obj.push({
                                                    industryServices: i[0],
                                                    value: i[1]
                                                });
                                                totalImprove += i[1]
                                            }
                                            data.improve = obj[0]


                                            elementCounts = {}
                                            obj = []
                                            ftImpressed.forEach(function (i) { elementCounts[i] = (elementCounts[i] || 0) + 1; });;

                                            ftImpressed = Object.entries(elementCounts).sort((a, b) => b[1] - a[1]);
                                            for (let i of ftImpressed) {
                                                obj.push({
                                                    industryServices: i[0],
                                                    value: i[1]
                                                });
                                                totalFTImpressed += i[1]
                                            }
                                            data.ftImpressed = obj[0]

                                            elementCounts = {}
                                            obj = []
                                            ftImprove.forEach(function (i) { elementCounts[i] = (elementCounts[i] || 0) + 1; });;

                                            ftImprove = Object.entries(elementCounts).sort((a, b) => b[1] - a[1]);
                                            for (let i of ftImprove) {
                                                obj.push({
                                                    industryServices: i[0],
                                                    value: i[1]
                                                });
                                                totalFTImprove += i[1]
                                            }
                                            data.ftImprove = obj[0]

                                            data.totalImprove = totalImpressed
                                            data.totalImpressed = totalImprove
                                            data.totalFTImprove = totalFTImpressed
                                            data.totalFTImpressed = totalFTImprove
                                            data.avgRating = Number(data.avgRating / reviewArr.length).toFixed(1)

                                            return (
                                                <>
                                                    <ClientReview setSpecificCompany={setSpecificCompany} key={i} moreReviews={reviewArr} data={data} graphData={graphRating} setId={setId} getReviewDetailsById={getReviewDetailsById} />
                                                </>
                                            )
                                        })
                                    }
                                </>
                        }
                    </>
                    :
                    <>
                        {
                            typeExternal ?
                                <>
                                    {
                                        reviewRes?.data?.externalVendorReview?.map((data, i) => <ClientReview2 key={i} i={i} data={data} getReviewDetailsById={getReviewDetailsById} />)
                                    }
                                </>
                                :
                                <>
                                    {
                                        Object.getOwnPropertyNames(reviewRes?.data?.registeredVendorReview)?.map((item, i) => {
                                            let reviewArr = reviewRes?.data?.registeredVendorReview[item] || [];
                                            let graphRating = [];
                                            let topServices = [];
                                            let impressed = [];
                                            let improve = [];
                                            let ftImprove = [];
                                            let ftImpressed = [];
                                            let totalImpressed = 0
                                            let totalImprove = 0
                                            let totalFTImpressed = 0
                                            let totalFTImprove = 0


                                            for (let i = 0; i < reviewArr.length; i++) {
                                                for (let j = 0; j < reviewArr[i].graphRating.length; j++) {
                                                    let temp = { typeOfReview: reviewArr[i].graphRating[j].typeOfReview }
                                                    if (i == 0) {
                                                        temp.rating = reviewArr[i]?.graphRating[j]?.rating || 0
                                                    }
                                                    else {
                                                        temp.rating = reviewArr[i - 1]?.graphRating[j]?.rating + reviewArr[i]?.graphRating[j]?.rating || 0
                                                    }
                                                    graphRating[j] = temp
                                                }
                                                ftImprove.push(reviewArr[i].improvementReviewComment)
                                                ftImpressed.push(reviewArr[i].positiveReviewComment)
                                            }

                                            for (let i of graphRating) {
                                                i.rating /= reviewArr.length
                                            }

                                            const data = reviewArr.reduce((acc, crr) => {
                                                const temp = acc || crr
                                                topServices.push(...crr.offeringTags)
                                                impressed.push(crr.serviceImpressed)
                                                improve.push(crr.serviceImprove)
                                                temp.avgRating = Number(acc?.avgRating || 0) + Number(crr.rating)
                                                return temp
                                            }, null)

                                            let elementCounts = {}
                                            let obj = []

                                            topServices.forEach(({ industryServices }) => {
                                                elementCounts[industryServices] = (elementCounts[industryServices] || 0) + 1;
                                            });

                                            topServices = Object.entries(elementCounts).sort((a, b) => b[1] - a[1]);
                                            for (let i of topServices) {
                                                obj.push({
                                                    industryServices: i[0],
                                                    value: i[1]
                                                });
                                            }
                                            data.topServices = obj

                                            elementCounts = {}
                                            obj = []
                                            impressed.forEach(function (i) { elementCounts[i] = (elementCounts[i] || 0) + 1; });;

                                            impressed = Object.entries(elementCounts).sort((a, b) => b[1] - a[1]);
                                            for (let i of impressed) {
                                                obj.push({
                                                    industryServices: i[0],
                                                    value: i[1]
                                                });
                                                totalImpressed += i[1]
                                            }
                                            data.impressed = obj[0]

                                            elementCounts = {}
                                            obj = []
                                            improve.forEach(function (i) { elementCounts[i] = (elementCounts[i] || 0) + 1; });;

                                            improve = Object.entries(elementCounts).sort((a, b) => b[1] - a[1]);
                                            for (let i of improve) {
                                                obj.push({
                                                    industryServices: i[0],
                                                    value: i[1]
                                                });
                                                totalImprove += i[1]
                                            }
                                            data.improve = obj[0]


                                            elementCounts = {}
                                            obj = []
                                            ftImpressed.forEach(function (i) { elementCounts[i] = (elementCounts[i] || 0) + 1; });;

                                            ftImpressed = Object.entries(elementCounts).sort((a, b) => b[1] - a[1]);
                                            for (let i of ftImpressed) {
                                                obj.push({
                                                    industryServices: i[0],
                                                    value: i[1]
                                                });
                                                totalFTImpressed += i[1]
                                            }
                                            data.ftImpressed = obj[0]

                                            elementCounts = {}
                                            obj = []
                                            ftImprove.forEach(function (i) { elementCounts[i] = (elementCounts[i] || 0) + 1; });;

                                            ftImprove = Object.entries(elementCounts).sort((a, b) => b[1] - a[1]);
                                            for (let i of ftImprove) {
                                                obj.push({
                                                    industryServices: i[0],
                                                    value: i[1]
                                                });
                                                totalFTImprove += i[1]
                                            }
                                            data.ftImprove = obj[0]

                                            data.totalImprove = totalImpressed
                                            data.totalImpressed = totalImprove
                                            data.totalFTImprove = totalFTImpressed
                                            data.totalFTImpressed = totalFTImprove
                                            data.avgRating = Number(data.avgRating / reviewArr.length).toFixed(1)

                                            return (
                                                <>
                                                    <ClientReview setSpecificCompany={setSpecificCompany} key={i} moreReviews={reviewArr} data={data} graphData={graphRating} setId={setId} getReviewDetailsById={getReviewDetailsById} />
                                                </>
                                            )
                                        })
                                    }
                                </>
                        }
                    </>
                }
            </div> : <></>}
        </div>
    )
}

export default Reviews



const ClientReview = ({ data, graphData, moreReviews, setId, setSpecificCompany }) => {
    const [active, setActive] = useState(false)

    return (
        <>
            <div className='p-4 border-2 border-gray-700 rounded-xl'>
                <div className='relative'>
                    <div className='text-gray-600 text-sm absolute -top-2 right-14'>
                        <h1 className='text-sm cursor-pointer text-purple-900 hover:text-purple-700' onClick={() => setActive(true)}>{moreReviews.filter(item => !item?.hide).length} more reviews</h1>
                        <h1>
                            {moment(data.createdAt).format('DD-MMM-YYYY')}
                        </h1>
                    </div>
                    <img src={bgrating} className="z-2 absolute -top-4 right-1 w-8" />
                    <div className='absolute -top-3 right-2 text-white'>{data.avgRating}</div>
                </div>
                <div className='flex items-center gap-4'>
                    <div className='rounded-lg bg-lightpink border border-bermuda'>
                        <div className='border border-bermuda w-14 h-14 p-2 bg-white rounded-lg transform -translate-x-0.5 -translate-y-0.5'>
                            <img src={data.companyIcon} className="w-full h-full object-contain" />
                        </div>
                    </div>
                    <div>
                        <h1 className='text-lg font-medium'>{data.companyName}</h1>
                        <h1 className='text-sm'>{data.sectorOfIndustry} | {data.typeOfIndustry}</h1>
                        <p className='text-xs'>{data?.location || ''}</p>
                    </div>
                </div>

                <div className='flex items-start w-full py-4'>
                    <div className='p-1'>
                        <h1 className='text-lg font-medium text-center'>Factor analysis</h1>
                        <RadarChart width={360} height={300} draggable={false} heading={'Factor Rating'} initialData={graphData} graphData={graphData} backgroundColor={data.type == 1 ? 'rgba(239 ,185 ,255 ,0.6)' : 'rgba(184, 227, 236, 0.6)'} borderColor={data.type == 1 ? 'rgb(222, 22, 255)' : 'rgb(20 ,201 ,201)'} />
                    </div>
                    <div className='p-1 flex-grow flex flex-col items-center justify-center gap-6'>
                        <h1 className='text-lg font-medium text-center'>Services analysis</h1>
                        {
                            data?.topServices?.length ?
                                <DoughnutChart initialData={data.topServices} width={150} height={150} />
                                :
                                <h1 className='text-center text-sm text-blue-500 mt-10'>No Data Available</h1>
                        }
                    </div>
                </div>

                {
                    data.project ?
                        <div className='flex items-center gap-6'>
                            <div className='rounded w-full border-2 border-bermuda bg-signupBgColor'>
                                <div className='flex flex-col gap-2 bg-white rounded w-full border-2 border-bermuda px-2 py-1 transform -translate-x-1 -translate-y-1'>
                                    <p className='text-[10px] 2xl:text-sm'>Projects Executed</p>
                                    <p className='font-medium text-right'>{data?.project?.count || 1} Projects</p>
                                </div>
                            </div>
                            <div className='rounded w-full border-2 border-bermuda bg-lightviolet'>
                                <div className='flex flex-col gap-2 bg-white rounded w-full border-2 border-bermuda px-2 py-1 transform -translate-x-1 -translate-y-1'>
                                    <p className='text-[10px] 2xl:text-sm'>Project duration</p>
                                    <p className='font-medium text-right'>{data?.project?.duration || 0} Months</p>
                                </div>
                            </div>
                            <div className='rounded w-full border-2 border-bermuda bg-cream'>
                                <div className='flex flex-col gap-2 bg-white rounded w-full border-2 border-bermuda px-2 py-1 transform -translate-x-1 -translate-y-1'>
                                    <p className='text-[10px] 2xl:text-sm'>Project Cost</p>
                                    <p className='font-medium text-right'>${data?.project?.cost || 0}</p>
                                </div>
                            </div>
                            <div>
                                <div onClick={() => { setId(3); setSpecificCompany({ name: data?.companyName, id: data?.companyId }) }} className='bg-bermuda rounded-lg min-w-max'>
                                    <div className='py-2 px-4 rounded-lg min-w-max bg-lightgreen text-sm font-medium transform -translate-x-1 -translate-y-1 text-center cursor-pointer'>View Projects</div>
                                </div>
                                <div onClick={() => setActive(true)} className='bg-bermuda rounded-lg min-w-max mt-3'>
                                    <div className='py-2 px-4 rounded-lg min-w-max bg-lightviolet text-sm font-medium transform -translate-x-1 -translate-y-1 text-center cursor-pointer'>View Detail</div>
                                </div>
                            </div>
                        </div>
                        :
                        <div className='flex items-center justify-between gap-6'>
                            <div className='w-20'></div>
                            <div className='rounded w-full border-2 border-bermuda bg-lightviolet max-w-[200px]'>
                                <div className='flex flex-col gap-2 bg-white rounded w-full border-2 border-bermuda px-2 py-1 transform -translate-x-1 -translate-y-1'>
                                    <p className='text-[10px] 2xl:text-sm'>Engagement duration</p>
                                    <p className='font-medium text-right'>{getObjectWithMaxValue(moreReviews, "durationOfEngagement")?.durationOfEngagement || 0} Months</p>
                                </div>
                            </div>

                            <div>

                                <div onClick={() => setActive(true)} className='bg-bermuda rounded-lg min-w-max mt-3'>
                                    <div className='py-2 px-4 rounded-lg min-w-max bg-lightviolet text-sm font-medium transform -translate-x-1 -translate-y-1 text-center cursor-pointer'>View Detail</div>
                                </div>
                            </div>
                        </div>
                }

            </div>

            {active ? <FullReviewDetail reviewData={data} moreReviews={moreReviews.filter(item => !item?.hide)} setActive={setActive} /> : <></>}
        </>
    )
}

const ClientReview2 = ({ data, getReviewDetailsById, i }) => {

    let index = Math.abs((i - 4) % 4);

    return (
        <>
            <div className='p-4 mr-3 border border-gray-600 shadow rounded-xl bg-white'>
                <div className='relative'><div className='text-gray-400 absolute -top-2 right-14'>{moment(data.createdAt).format('DD-MMM-YYYY')}</div>
                    <img src={bgrating} className="z-2 absolute -top-4 right-1 w-8" />
                    <div className='absolute -top-3 right-2 text-white'>{data.avgRating}</div>
                </div>
                <div className='flex items-center gap-4 text-sm'>
                    <div className={`border border-bermuda w-14 h-14 rounded-lg ${data.type == 1 ? 'bg-lightviolet' : 'bg-lightblue'}`}>
                        <div className='bg-white border border-bermuda rounded-lg transform -translate-x-0.5 -translate-y-0.5'>
                            {index == 1 ? <SVG1 /> : index == 2 ? <SVG2 /> : index == 3 ? <SVG3 /> : <SVG4 />}
                        </div>
                    </div>
                    <div className=''>
                        <p className='text-lg font-semibold leading-none'>{data?.externalData?.companyName}</p>
                        <p className='text-sm font-medium leading-none my-1'>{data?.externalData?.sectorOfIndustry} | {data?.externalData.typeOfIndustry}</p>
                        <p className='text-xs leading-none capitalize'>{data?.externalData?.location || ''}</p>
                    </div>
                </div>
                <div className='mt-4 mb-2 flex items-center justify-center'>
                    <div className='inline-flex w-auto rounded-3xl justify-center bg-lightgreen px-4 py-2 font-medium text-black shadow-sm focus:outline-none border-0 focus:ring-bermuda focus:border-bermuda'>{data.positiveReviewComment}</div>
                    <div className='ml-2 bg-lightpeach inline-flex w-auto rounded-3xl justify-center px-4 py-2 font-medium text-black shadow-sm focus:outline-none border-0 focus:ring-bermuda focus:border-bermuda'>{data.improvementReviewComment}</div>
                </div>

                <div className='py-6'>
                    {data.reviewComment || 'no comment provided'}
                </div>
                <div className='mt-5 relative flex items-center justify-between'>
                    <div className='text-lg text-black font-medium'>Project Duration: {data.durationOfEngagement} month(s)</div>
                    <div className='relative'>
                        <div className="-mt-2 relative rounded-lg bg-bermuda w-28 h-8">
                            <div className="bg-bermuda rounded-lg w-full h-full border-2 border-bermuda">
                            </div>
                            <button onClick={() => getReviewDetailsById(data.id, data.type)} className={`border-2 rounded-lg focus:outline-none bg-blueshade text-xs absolute top-0 transform -translate-x-1 -translate-y-1 left-0 w-full h-full border-bermuda`}>Detailed review</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}


let SVG1 = () => {
    return (<>
        <svg viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.2006 27.0948C30.2309 23.905 27.8266 16.5518 21.8305 10.671C15.8344 4.79021 8.51707 2.60874 5.4868 5.79855C2.45652 8.98836 4.86081 16.3415 10.8569 22.2223C16.853 28.1031 24.1703 30.2846 27.2006 27.0948Z" fill="#C1E0B8" />
            <path d="M44.143 22.2001C50.1391 16.3193 52.5434 8.96614 49.5131 5.77633C46.4828 2.58652 39.1655 4.76799 33.1694 10.6488C27.1733 16.5296 24.769 23.8827 27.7993 27.0725C30.8296 30.2624 38.1469 28.0809 44.143 22.2001Z" fill="#C1E0B8" />
            <path d="M22.0831 44.2548C27.9185 38.3289 30.1732 31.0079 27.119 27.9029C24.0648 24.7979 16.8584 27.0847 11.023 33.0105C5.18757 38.9364 2.93292 46.2573 5.98709 49.3624C9.04125 52.4674 16.2477 50.1806 22.0831 44.2548Z" fill="#C1E0B8" />
            <path d="M49.0096 49.3783C52.0638 46.2733 49.8091 38.9524 43.9737 33.0265C38.1383 27.1006 30.9319 24.8139 27.8777 27.9189C24.8235 31.0239 27.0782 38.3449 32.9136 44.2707C38.749 50.1966 45.9555 52.4833 49.0096 49.3783Z" fill="#C1E0B8" />
        </svg>
    </>)
}

let SVG2 = () => {
    return (<>
        <svg viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.9266 39.0294C33.0894 39.0294 38.8961 33.2244 38.8961 26.0635C38.8961 18.9027 33.0894 13.0977 25.9266 13.0977C18.7637 13.0977 12.957 18.9027 12.957 26.0635C12.957 33.2244 18.7637 39.0294 25.9266 39.0294Z" fill="#F8B7C6" />
            <path d="M44.3467 52.0002C48.5736 52.0002 52.0001 48.5746 52.0001 44.3488C52.0001 40.123 48.5736 36.6973 44.3467 36.6973C40.1199 36.6973 36.6934 40.123 36.6934 44.3488C36.6934 48.5746 40.1199 52.0002 44.3467 52.0002Z" fill="#F8B7C6" />
            <path d="M44.3467 15.4299C48.5736 15.4299 52.0001 12.0042 52.0001 7.77844C52.0001 3.55264 48.5736 0.126953 44.3467 0.126953C40.1199 0.126953 36.6934 3.55264 36.6934 7.77844C36.6934 12.0042 40.1199 15.4299 44.3467 15.4299Z" fill="#F8B7C6" />
            <path d="M7.65337 15.303C11.8802 15.303 15.3067 11.8773 15.3067 7.65149C15.3067 3.42569 11.8802 0 7.65337 0C3.42654 0 0 3.42569 0 7.65149C0 11.8773 3.42654 15.303 7.65337 15.303Z" fill="#F8B7C6" />
            <path d="M7.65337 52.0002C11.8802 52.0002 15.3067 48.5746 15.3067 44.3488C15.3067 40.123 11.8802 36.6973 7.65337 36.6973C3.42654 36.6973 0 40.123 0 44.3488C0 48.5746 3.42654 52.0002 7.65337 52.0002Z" fill="#F8B7C6" />
        </svg>

    </>)
}

let SVG3 = () => {
    return (<>
        <svg viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 0C2.68629 0 0 2.68629 0 6V46C0 49.3137 2.68629 52 6 52H46C49.3137 52 52 49.3137 52 46V6C52 2.68629 49.3137 0 46 0H6ZM38.9989 32.9989C38.9989 36.3126 36.3126 38.9989 32.9989 38.9989H19.0011C15.6874 38.9989 13.0011 36.3126 13.0011 32.9989V18.9967C13.0011 15.683 15.6874 12.9967 19.0011 12.9967H32.9989C36.3126 12.9967 38.9989 15.683 38.9989 18.9967V32.9989Z" fill="#F8B7C6" />
        </svg>
    </>)
}

let SVG4 = () => {
    return (<>
        <svg viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M46 0H6C2.68629 0 0 2.68629 0 6V46C0 49.3137 2.68629 52 6 52H46C49.3137 52 52 49.3137 52 46V6C52 2.68629 49.3137 0 46 0Z" fill="#FEE8A7" />
            <path d="M46 52C49.3137 52 52 49.3137 52 46V14.4853C52 9.13985 45.5371 6.46285 41.7574 10.2426L10.2426 41.7574C6.46286 45.5371 9.13985 52 14.4853 52H46Z" fill="#B8E3EB" />
        </svg>
    </>)
}