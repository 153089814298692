import moment from 'moment';
import React, { useState } from 'react'
import { useGetReviewDetails } from '../../hooks';
import { BubbleChart } from '../Charts/BubbleChart';
import RadialChart from '../Charts/RadialChart';
import { useQuery } from 'react-query';
import { getBubbleGraphData } from '../../services/service';

function FullReviewDetail({ reviewData, moreReviews, setActive }) {

    const [page, setPage] = useState(1);

    const { mutate } = useGetReviewDetails();

    const getReviewDetailsById = (id) => {
        mutate(id)
    }
    let { isLoading, data: BubbleRes } = useQuery('getBubbleGraphData', () => getBubbleGraphData(reviewData?.companyName, reviewData?.belongsToCompanyId), { refetchOnWindowFocus: false });

    let impressed = (reviewData.impressed.value / reviewData.totalImpressed) * 100 * 3.6
    let improve = (reviewData.improve.value / reviewData.totalImprove) * 100 * 3.6
    let ftImpressed = (reviewData.ftImpressed.value / reviewData.totalFTImpressed) * 100 * 3.6
    let ftImprove = (reviewData.ftImprove.value / reviewData.totalFTImprove) * 100 * 3.6

    return (
        <>
            <div className='fixed inset-0 bg-black bg-opacity-50 grid place-items-center z-[102]'>
                <div className='bg-white rounded-lg w-full max-w-6xl py-8 px-10 relative overflow-hidden'>
                    <button type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" onClick={() => setActive(false)}>
                        <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                    </button>
                    <div className='relative z-10 '>
                        <div className='w-full relative max-w-6xl mx-auto mt-3'>
                            <div className='flex items-center justify-center'>
                                <div className='relative max-w-max mx-auto'>
                                    <div className='bg-white rounded border-2 border-bermuda py-2 px-16 max-w-max z-20 relative'>
                                        <span className='text-2xl font-bold'>Details</span>
                                    </div>
                                    <div className=' bg-cream rounded border-2 border-bermuda absolute inset-0 transform translate-x-1.5 translate-y-1.5 z-10'>
                                    </div>
                                    <div className=' bg-lightviolet rounded border-2 border-bermuda absolute inset-0 transform translate-x-3 translate-y-3 z-0'>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='flex items-center justify-between'>

                            <div className={`flex items-center gap-2 text-lg text-black font-semibold ${page == 2 ? 'cursor-pointer' : 'opacity-0'}`} onClick={() => page == 2 && setPage(1)}>
                                <span><svg width="14" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.93934 10.9393C0.353553 11.5251 0.353553 12.4749 0.93934 13.0607L10.4853 22.6066C11.0711 23.1924 12.0208 23.1924 12.6066 22.6066C13.1924 22.0208 13.1924 21.0711 12.6066 20.4853L4.12132 12L12.6066 3.51472C13.1924 2.92893 13.1924 1.97919 12.6066 1.3934C12.0208 0.807611 11.0711 0.807611 10.4853 1.3934L0.93934 10.9393ZM3 10.5H2L2 13.5H3V10.5Z" fill="black" />
                                </svg>
                                </span>
                                <span>Overview</span>
                            </div>

                            <div className={`flex items-center gap-2 text-lg text-black font-semibold ${page == 1 ? 'cursor-pointer' : 'opacity-0'}`} onClick={() => page == 1 && setPage(2)}>
                                <span>Highlighted reviews</span>
                                <span><svg width="14" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.0607 13.0607C13.6464 12.4749 13.6464 11.5251 13.0607 10.9393L3.51472 1.3934C2.92893 0.807611 1.97919 0.807611 1.3934 1.3934C0.807611 1.97919 0.807611 2.92893 1.3934 3.51472L9.87868 12L1.3934 20.4853C0.807611 21.0711 0.807611 22.0208 1.3934 22.6066C1.97919 23.1924 2.92893 23.1924 3.51472 22.6066L13.0607 13.0607ZM11 13.5H12V10.5H11V13.5Z" fill="black" />
                                </svg>
                                </span>
                            </div>
                        </div>

                        <section>

                            {
                                page == 1 ?
                                    <div className='flex items-start gap-8'>
                                        <div className='relative'>
                                            <h1 className='mb-7 text-2xl font-medium'>{reviewData?.companyName} vs Other Clients</h1>
                                            <div className='relative'>
                                                <h1 className='absolute -top-6 left-2 text-sm font-medium'>Project duration</h1>
                                                <h1 className='absolute -bottom-6 right-2 text-sm font-medium'>Total Project Cost</h1>
                                                <BubbleChart width={550} height={300} firstData={BubbleRes?.data?.myData} secondData={BubbleRes?.data?.othersData} xLabel={'Total Project Cost'} yLabel={'Project duration'} />
                                            </div>
                                        </div>

                                        <div className='flex flex-col items-center justify-center pt-4 w-full max-w-sm mx-auto'>
                                            <div >

                                                <h1 className='text-2xl font-medium'>Factors Impression</h1>
                                                <div className='relative flex items-center'>
                                                    <div className='relative'>
                                                        <div className='text-[9px] font-medium absolute top-5 right-28 text-right px-1 py-0.5'>
                                                            <h1>This Client</h1>
                                                            <h1>Other Clients</h1>
                                                        </div>
                                                        <RadialChart width={200} height={200} inner={{ value: impressed, color: '#E6BCFA', label: 'This Client' }} outer={{ value: 360, color: '#1DB440', label: 'Other Clients' }} />
                                                        <h1 className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center text-sm font-medium'>{reviewData.ftImpressed.industryServices}</h1>
                                                    </div>
                                                    <div className='relative'>
                                                        <div className='text-[9px] font-medium absolute top-5 right-28 text-right px-1 py-0.5'>
                                                            <h1>This Client</h1>
                                                            <h1>Other Clients</h1>
                                                        </div>
                                                        <RadialChart width={200} height={200} inner={{ value: improve, color: '#FF748F', label: 'This Client' }} outer={{ value: 360, color: '#F8C646', label: 'Other Clients' }} />
                                                        <h1 className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center text-sm font-medium'>{reviewData.ftImprove.industryServices}</h1>
                                                    </div>
                                                </div>

                                            </div>

                                            <div >

                                                <h1 className='text-2xl font-medium'>Services Impression</h1>
                                                <div className='relative flex items-center'>
                                                    <div className='relative'>
                                                        <div className='text-[9px] font-medium absolute top-5 right-28 text-right px-1 py-0.5'>
                                                            <h1>This Client</h1>
                                                            <h1>Other Clients</h1>
                                                        </div>
                                                        <RadialChart width={200} height={200} inner={{ value: ftImpressed, color: '#E6BCFA', label: 'This Client' }} outer={{ value: 360, color: '#1DB440', label: 'Other Clients' }} />
                                                        <h1 className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center text-sm font-medium'>{reviewData.impressed.industryServices}</h1>
                                                    </div>
                                                    <div className='relative'>
                                                        <div className='text-[9px] font-medium absolute top-5 right-28 text-right px-1 py-0.5'>
                                                            <h1>This Client</h1>
                                                            <h1>Other Clients</h1>
                                                        </div>
                                                        <RadialChart width={200} height={200} inner={{ value: ftImprove, color: '#FF748F', label: 'This Client' }} outer={{ value: 360, color: '#F8C646', label: 'Other Clients' }} />
                                                        <h1 className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center text-sm font-medium'>{reviewData.improve.industryServices}</h1>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                    :
                                    page == 2 ? <>
                                        <p className='text-purple-900 text-right max-w-3xl mx-auto'>{moreReviews?.length || 0} Reviews</p>
                                        <div className='rounded-lg max-w-3xl mx-auto p-4 flex flex-wrap items-center justify-center gap-6 border-2 border-bermuda max-h-[65vh] min-h-[60vh] mb-6 overflow-y-auto'>
                                            <div className='rounded-lg flex flex-wrap items-center justify-center gap-6'>
                                                {
                                                    moreReviews?.length ?

                                                        moreReviews?.map(item => <div key={item.id} className='flex flex-col items-center justify-center max-w-max border-2 rounded-lg p-1.5 border-bermuda'>
                                                            <h1 className='text-xl w-full'>{item.companyRole}</h1>
                                                            <div className='flex items-center gap-2 pb-3'>
                                                                <svg width="31" height="35" viewBox="0 0 31 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path opacity="0.75" d="M1 25.5346L15.483 17.3555L29.89 25.5101L15.483 33.7107L1 25.5346Z" fill="#8ACEDA" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path opacity="0.75" d="M15.483 1V17.3523L1 25.5313V9.17906L15.483 1Z" fill="#F8B6C7" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path opacity="0.75" d="M29.8628 9.26172V25.617L15.3828 33.6972V17.4378L29.8628 9.26172Z" fill="#F8B6C7" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path opacity="0.78" d="M1 9.17969L15.483 17.3558V33.7081L1 25.5319V9.17969Z" fill="#C1E1B7" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path opacity="0.75" d="M1 9.17906L15.483 17.3552L29.966 9.17906L15.483 1L1 9.17906Z" fill="#F9EBA4" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path opacity="0.75" d="M1 9.17906L15.483 17.3552L29.966 9.17906L15.483 1L1 9.17906Z" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                    <path opacity="0.75" d="M29.8628 9.26172V25.617L15.3828 33.6972V17.4378L29.8628 9.26172Z" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                                <span className='text-2xl text-purple-900'>{Number(item.rating).toFixed(1)}</span>
                                                            </div>
                                                            <div className='flex items-baseline gap-4'>
                                                                <h1 className='text-xs'>{moment(item.createdAt).format('DD-MMM-YYYY')}</h1>
                                                                <div className='rounded-lg bg-bermuda max-w-max'>
                                                                    <button onClick={() => getReviewDetailsById(item.id)} className='rounded-lg bg-lightviolet font-medium text-xs py-1.5 px-2 transform -translate-x-1 -translate-y-1'>Detailed Review</button>
                                                                </div>
                                                            </div>
                                                        </div>)
                                                        :
                                                        <h1 className='text-3xl font-medium text-gray-400 text-center mt-10'>No Highlighted Review</h1>}
                                            </div>
                                        </div>
                                    </>
                                        :
                                        <></>
                            }
                        </section>

                    </div>
                    <div className='flex items-center justify-center w-full z-10'>
                        <div className='bg-bermuda rounded-lg'>
                            <button onClick={() => setActive(false)} className='bg-lightpink text-lg font-medium py-2 px-10 rounded-lg  transform -translate-x-1 -translate-y-1'>
                                <span>Close</span>
                            </button>
                        </div>
                    </div>

                    <div className='absolute -bottom-60 -left-60'>
                        <svg width="663" className='h-full' viewBox="0 0 663 549" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle opacity="0.23" cx="312" cy="323" r="186" fill="#FBE9AF" />
                            <circle opacity="0.3" cx="477" cy="363" r="186" fill="#FBE9AF" />
                            <circle opacity="0.23" cx="186" cy="186" r="186" fill="#FBE9AF" />
                        </svg>
                    </div>
                    <div className='absolute -bottom-60 -right-60'>
                        <svg width="705" className='h-full' viewBox="0 0 805 673" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle opacity="0.23" cx="434.817" cy="377.366" r="186" transform="rotate(-60.4709 434.817 377.366)" fill="#E6BCFA" />
                            <circle opacity="0.23" cx="253.513" cy="419.476" r="186" transform="rotate(-60.4709 253.513 419.476)" fill="#E6BCFA" />
                            <circle opacity="0.3" cx="550.946" cy="253.512" r="186" transform="rotate(-60.4709 550.946 253.512)" fill="#E6BCFA" />
                        </svg>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FullReviewDetail